import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import LineChart from "../Dashboard/Charts/LineChart";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from 'react-tooltip'
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import NoDataTableModal from "../NoDataTableModal";


const EventOverview = ({state}) => {
  
  const { activityReport, getActivityReportApi,graphData ,globalLoader,checkResponse} = useContext(DataContext);
 
  
  
  const limit = 10;
  const [userFirstResultCount, setUserFirstResultCount] = useState(1);
  const [userSecondResultCount, setUserSecondResultCount] = useState(limit);
  

  const userData = {
    data: [
        
      // {
      //   id: 1,
      //   year: 2016,
      //   userGain: 80000,
      //   userLost: 823,
      // },
      // {
      //   id: 2,
      //   year: 2017,
      //   userGain: 45677,
      //   userLost: 345,
      // },
      // {
      //   id: 3,
      //   year: 2018,
      //   userGain: 78888,
      //   userLost: 555,
      // },
      // {
      //   id: 4,
      //   year: 2019,
      //   userGain: 90000,
      //   userLost: 4555,
      // },
      // {
      //   id: 5,
      //   year: 2020,
      //   userGain: 4300,
      //   userLost: 234,
      // },
    ],
    backgroundColor: [
      "rgba(255, 99, 132, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(201, 203, 207, 0.2)",
    ],
    borderColor: [
      "rgb(255, 99, 132)",
      "rgb(255, 159, 64)",
      "rgb(255, 205, 86)",
      "rgb(75, 192, 192)",
      "rgb(54, 162, 235)",
      "rgb(153, 102, 255)",
      "rgb(201, 203, 207)",
    ],
  };


// let xaxisData = ['15 sep', '18 sep', '19 sep', '26 sep', '27 sep', '16 oct'] ;
// let yaxisData = [2, 24, 5, 3, 5, 2] ;



let xaxisData=Object.keys(graphData)
let yaxisData=Object.values(graphData)
console.log(xaxisData,"xaxisData")
console.log(yaxisData,"yaxisdata")
const newYaxisArray=yaxisData.map((item)=>item)
const newXaxisArray=xaxisData.map((item)=>item)   
console.log(newXaxisArray,newYaxisArray)
// xaxisData=Object.keys(graphData).map((item)=>{
//   return item
// })
// yaxisData=Object.values(graphData).map((item)=>{
//   return item;
// })
console.log(graphData,"graphdata")

const lineChartFunction=()=>{
  setLineChartData(
    {
      labels:[...xaxisData],
      // labels:["15 sept","16 sept"],
      // labels:[...newXaxisArray],
      datasets: [
        {
          label: "Attendee Data",
          data:[...yaxisData],
          // data:[...newYaxisArray],
          // data:[2,4],
          backgroundColor:  userData.backgroundColor,
          borderColor: userData.borderColor,
          borderWidth: 1,
          tension: 0.1,
        },
      ],
    }
  )
}

  const [lineChartData, setLineChartData] = useState({
    labels:[...xaxisData],
    // labels:["15 sept","16 sept"],
    // labels:[...newXaxisArray],
    datasets: [
      {
        label: "Attendee Data",
        data:[...yaxisData],
        // data:[...newYaxisArray],
        // data:[2,4],
        backgroundColor:  userData.backgroundColor,
        borderColor: userData.borderColor,
        borderWidth: 1,
        tension: 0.1,
      },
    ],
  });
  useEffect(() => {
    getActivityReportApi(state);
  }, []);
  useEffect(()=>{
    
    if((activityReport?.registerUsers?.docs.length)>0)
    {
      console.log("inside useEffect")
      lineChartFunction()
    }
   
  })
  console.log(activityReport,"activityReport")
 console.log(checkResponse,"checkResponse")
  return (
    <>
      <div className=" w-full flex h-screen ">
        <div className="w-[100%] ">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div
                className="flex flex-col gap-4 
              "
              >
               

                <div className="flex flex-col">
                  
                 {
                 globalLoader ? <GlobalLoader/> :  <div>
                 <div className="flex justify-between items-center mt-3">
                   <p className="text-[#202223] text-base font-semibold">
                     Overall Analytics
                   </p>
                   {/* <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                     Export XLS
                   </button> */}
                 </div>
                 <div className="w-full flex justify-center items-center mt-5 ">
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center items-center rounded-tl border-x border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalLogin}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Logins
                     </span>
                   </div>
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center  items-center border-r border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalAttendee}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Attendees
                     </span>
                   </div>
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center rounded-tr items-center border-r border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalAgenda}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Sessions
                     </span>
                   </div>
                  
                 </div>
                 <div className="w-full flex justify-center items-center">
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center rounded-bl items-center  border-x border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalSpeaker}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Speakers
                     </span>
                   </div>
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center items-center border-r border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalExhibitor}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Exhibitors
                     </span>
                   </div>
                   <div className="w-1/3 h-32 bg-white flex flex-col justify-center rounded-br items-center border-r border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                       {activityReport?.data?.totalPost}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Posts
                     </span>
                   </div>
                   <div></div>
                 </div>
                 <div className="bg-white mt-4 rounded-t">
                   <div className="flex justify-between">
                     <div className="flex flex-col gap-1 p-2">
                       <p className="text-[#141824] text-base font-semibold">
                         {" "}
                         Total attendees
                       </p>
                       <p className="text-[#525B75] text-sm font-normal">
                         Total attendees attends the event
                       </p>
                     </div>
                     {/* <div className="flex gap-2 items-center mr-4">
                       <span className="capitalize text-sm font-semibold" >From</span>

                       

                       <input
                         type="date"
                         value={state?.startDate}
                         name="startDate"
                         id="startDate"
                         className="text-[#31374A] w-32 border py-1 px-2 font-semibold border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                       />

                       <span className="capitalize text-sm font-semibold">To</span>

                       <input
                         type="date"
                         value={state?.endDate}
                         className="text-[#31374A] w-32 border py-1 px-2  font-semibold border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                         name="endDate"
                         id="endDate"
                       />
                     </div> */}
                   </div>
                   <div className="w-full h-[350px] ">
                     <LineChart chartData={lineChartData} />
                   </div>
                    <div className="mb-4">
                      <table className="w-full table-auto bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[13%] px-4 py-2 rounded relative">
                              Name
                            </th>
 
                            <th className="w-[20%] px-4 py-2 relative">
                              Email
                            </th>
                            <th className="w-[20%] px-4 py-2 relative">
                              Organisation
                            </th>
                            <th className="w-[15%] px-4 py-2">Position</th>
                            <th className="w-[15%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB] bg-white">
                    
                          {activityReport?.registerUsers?.docs?.length==0 && checkResponse ?
                          <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>
         
           :
                          activityReport?.registerUsers?.docs?.map((item) => {
                            return (
                              <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                
                                

 <td className="p-2">{item?.Name}</td>
 
                                <td className=" py-2 px-4">{item?.email}</td>
 
                                <td className=" py-2 px-4">
                                  {item?.organization}
                                </td>
                                <td className=" py-2 px-4">
                                  {item?.designation}
                                </td>
 
                                <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/eventoverview-view">
                                      <AiOutlineEye
                                       data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                      className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                      <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                                    </Link>
                                  </div>
                                </td>


                                
                               
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                   
                  
                   {/* <div className="flex justify-between items-center ">
           <span className="font-[150] text-sm mt-2">{`showing ${userFirstResultCount} to ${pageCount===1 ? totalCount: userSecondResultCount} of ${totalCount} results`}</span>
           <Pagination
             firstResultCount={userFirstResultCount}
             setFirstResultCount={setUserFirstResultCount}
             secondResultCount={userSecondResultCount}
             setSecondResultCount={setUserSecondResultCount}
             limit={limit}
             api={ getActivityReportApi}
           />
         </div> */}
                  
                 </div>
                 </div>
                 }
                 
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventOverview;
