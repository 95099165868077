import React, { useContext, useEffect, useState } from "react";
import ExhibitorDetails from "../Exhibitors/ExhibitorDetails";
import { DataContext } from "../../context/DataState";
const MyProfile = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
  const { findOneExhibitorApi, editExhibitor } = useContext(DataContext);

  useEffect(() => {
    findOneExhibitorApi(userInfo?._id);
  }, []);
  return (
    <>
      <ExhibitorDetails
        role={"exhibitor"}
        id={userInfo?._id}
        data={editExhibitor}
      />
    </>
  );
};
export default MyProfile;
