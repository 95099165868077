import React, { useContext, useState, useEffect } from "react";
import EventOverview from "./EventOverview";
import SponsorsOverview from "./SponsorsOverview";
import { DataContext } from "../../context/DataState";
import ExhibitorsOverview from "./ExhibitorsOverview";
import AgendaOverview from "./AgendaOverview";
import SpeakersOverview from "./SpeakersOverview";
import AttendeeOverview from "./AttendeeOverview";

const Report = () => {
  const {
    getActivityReportApi,
    option,
    setOption,
    graphActivityReport,
    getSponsorReportApi,
    getExhibitorReportApi,
    getAttendeeReportApi,
    getAgendaReportApi,
    getSpeakerReportApi,
  } = useContext(DataContext);

  const [state, setState] = useState({
    startDate: `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9 ? "" : +"0"
    }${new Date().getMonth() + 1}-01`,
    endDate: `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9 ? "" : +"0"
    }${new Date().getMonth() + 1}-${
      new Date().getDate() > 9 ? "" : "0"
    }${new Date().getDate()}`,
  });
  const [selectedValue, setSelectedValue] = useState(option);
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
  const handleSubmit = () => {
    if (option === "sponsors") {
      getSponsorReportApi(state, 1, 10);
    } else if (option === "event-overview") {
      getActivityReportApi(state);
    } else if (option === "exhibitors") {
      getExhibitorReportApi(state, 1, 10);
    } else if (option === "attendee") {
      getAttendeeReportApi(state, 1, 10);
    } else if (option === "agenda") {
      getAgendaReportApi(state, 1, 10);
    } else if (option === "speakers") {
      getSpeakerReportApi(state, 1, 10);
    }
  };
  useEffect(() => {
    getActivityReportApi(state, graphActivityReport);
  }, []);
  console.log(option, "option");
  console.log(state.endDate, "end date");
  console.log(state.startDate, "start date");
  const permission = userInfo?.permission;
  const options = [
    { value: "sponsors", label: "Sponsors", key: "sponsors" },
    { value: "exhibitors", label: "Exhibitors", key: "exhibitor" },
    { value: "agenda", label: "Agenda", key: "agenda" },
    { value: "speakers", label: "Speakers", key: "speaker" },
    { value: "attendee", label: "Attendee", key: "attendee" },
  ];

  const hasPermission = (key) => {
    return (
      userInfo?.role === "Admin" ||
      Object.values(permission?.[key] || {}).some((value) => value)
    );
  };
  return (
    <>
      <div className=" w-full flex h-screen overflow-x-scroll">
        <div className="w-[100%] px-4 pt-5">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div
                className="flex flex-col gap-4 
              "
              >
                <div className="">
                  <h1 className="text-theme-black mt-0 text-xl font-semibold">
                    Reports
                  </h1>
                </div>

                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="flex w-full items-center gap-2">
                      <span className="capitalize text-sm text-[#202223] font-semibold">
                        Report Type:
                      </span>

                      <select
                        name="event-overview"
                        id="event-overview"
                        className="text-[#31374A] w-[32%] font-semibold border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                        onChange={(e) => {
                          setOption(e.target.value);
                        }}
                        value={option}
                      >
                        <option value="event-overview">Event Overview</option>
                        {options.map(
                          (opt) =>
                            hasPermission(opt.key) && (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            )
                        )}
                      </select>

                      <span className="capitalize text-sm font-semibold">
                        From
                      </span>

                      <input
                        type="date"
                        value={state?.startDate}
                        className="text-[#31374A] w-[25%] font-semibold  border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                        name="startDate"
                        id="startDate"
                        onChange={(e) => {
                          setState({ ...state, startDate: e.target.value });
                        }}
                      />

                      <span className="capitalize text-sm font-semibold">
                        To
                      </span>

                      <input
                        type="date"
                        value={state?.endDate}
                        className="text-[#31374A] w-[25%]  font-semibold  border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                        name="endDate"
                        id="endDate"
                        onChange={(e) => {
                          setState({ ...state, endDate: e.target.value });
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="bg-theme-color px-8 text-sm py-2 rounded disabled:opacity-50 hover:bg-orange-500 text-white ml-3"
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {option === "event-overview" ? (
                    <EventOverview state={state} />
                  ) : option === "sponsors" ? (
                    <SponsorsOverview state={state} />
                  ) : option === "exhibitors" ? (
                    <ExhibitorsOverview state={state} />
                  ) : option === "agenda" ? (
                    <AgendaOverview state={state} />
                  ) : option === "speakers" ? (
                    <SpeakersOverview state={state} />
                  ) : option === "attendee" ? (
                    <AttendeeOverview state={state} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Report;
