import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const ViewEnquiry = () => {
  const location = useLocation();
  const data = location.state.data;

  return (
    <>
      <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
              <Link to={"/enquiries"}>
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </Link>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">View Enquiry </h1>
          </div>
        </div>
        <div className="flex-col w-full  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Enquiry details</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
          </div>

          <div className=" w-full mt-5 flex gap-12">
            <div className="text-[#202223] flex-1 w-full ">
              <p className="text-sm font-medium"> Name*</p>
              <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
                {data?.Attendee_name}
              </p>
            </div>
            <div className="text-[#202223] flex-1 w-full">
              <p className="text-sm font-medium">Email*</p>
              <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
                {data?.email}
              </p>
            </div>
            <div className="text-[#202223] flex-1 w-full">
              <p className="text-sm font-medium">Query</p>
              <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
                {data?.query}
              </p>
            </div>
          </div>

          <div className=" w-full mt-5 flex gap-12">
            <div className="text-[#202223] flex-1 w-full ">
              <p className="text-sm font-medium">Description*</p>
              <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
                {data?.description}
              </p>
            </div>
            <div className="text-[#202223] flex-1 w-full">
              <p className="text-sm font-medium">Email Address*</p>
              <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
                {data?.email}
              </p>
            </div>
          </div>
          <div className="mt-7">
            <Link to={"/enquiries"}>
              <button className="border-2 px-16 py-2 text-sm  hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEnquiry;
