import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import NoContactModal from "./NoContactModal";
import ContactListModal from "./ContactListModal";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import SendEmailModal from "./SendEmailModal";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import DeleteModal from "../DeleteModal/DeleteModal";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import Pagination from "../Pagination/Pagination";
import ContactEmailTemplate from "./ContactEmailTemplate";
import History from "./History";
import { Tooltip } from 'react-tooltip'

const ContactList = () => {
  const {
    setIsContactList,
    isContactList,
    isSendMail,
    setIsSendMail,
    getContactListApi,
    contactList,
    checkResponse,
    globalLoader,
    deleteContactListApi,
    setDeleteBtnId,

    setDeleteBtn,
    deleteBtn,
    deleteBtnId,
    setContactList,
    contactListPaginationApi,
    totalCount,
    pageCount,
    currentPage,
    setCurrentPage,
    searchContactListApi,
    status,setStatus
  } = useContext(DataContext);
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
  const permission = userInfo?.permission?.contacts;
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState(null);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
 

  const checkboxHandler = (e, item) => {
    const isSelected = e.target.checked;

    let value = item._id;

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((element) => {
          return element !== value;
        });
      });
    }
  };

  const checkAllboxHandler = (e) => {
    const isSelected = e.target.checked;
    let newArray = [];
    if (isSelected) {
      newArray = contactList.map((item) => {
        return item._id;
      });
      setSelectedItems([...newArray]);
    } else {
      setSelectedItems([]);
    }
    return newArray;
  };

  const onKeyUpHandler = (e) => {
    searchContactListApi(e.target.value, currentPage);
  };
  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...contactList].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setContactList(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  // const handleDelete = () => {
  //   deleteContactListApi();
  // };

  useEffect(() => {
    // getContactListApi();
    setCurrentPage(1);
    contactListPaginationApi(1, limit);
  }, []);
console.log(isEdit,"edit")
  console.log(selectedItems, "selectedItems");
  console.log(contactList, "vontactList");
  return (
    <>
      {isEdit ? (
        <ContactListModal
          setIsContactList={setIsContactList}
          edit={"true"}
          item={item}
          setIsEdit={setIsEdit}
          limit={limit}
        />
      ) : (
        <></>
      )}
      {isContactList ? (
        <ContactListModal
          setIsContactList={setIsContactList}
          setIsEdit={setIsEdit}
        />
      ) : (
        <></>
      )}

      {deleteBtn ? (
        <DeleteModal deleteApi={deleteContactListApi} deleteId={deleteBtnId}
        currentPage={currentPage}
        limit={limit}
        secondResultCount={secondResultCount}
        setSecondResultCount={setSecondResultCount} />
      ) : (
        ""
      )}
      {isSendMail ? (
        <SendEmailModal
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      ) : (
        <></>
      )}

      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <h1 className="text-xl text-[#202223] font-semibold">Contacts</h1>
            <div className="">
              <ul className="flex gap-4 text-[#4B4B4B] text-sm font-normal cursor-pointer">
                {/* <li
                  onClick={() => {
                    setStatus("list");
                  }}
                  className={`${
                    status === "list" ? "text-theme-color underline" : ""
                  }`}
                >
                  List
                </li> */}
                {/* <li
                  onClick={() => {
                    setStatus("template");
                  }}
                  className={`${
                    status === "template" ? "text-theme-color underline" : ""
                  }`}
                >
                  Email Template
                </li>
                <li
                  onClick={() => {
                    setStatus("history");
                  }}
                  className={`${
                    status === "history" ? "text-theme-color underline" : ""
                  }`}
                >
                  History
                </li> */}
              </ul>
            </div>
          </div>
          {status === "template" ? (
            <ContactEmailTemplate />
          ) : status === "list" ? (
            <div>
              <div className=" flex justify-between pt-3 ">
                <div className="flex gap-1">
                  {(userInfo?.role==="Admin" || permission?.create || userInfo?.role==="Exhibitors" ) &&
                  <Link to="">
                    <button
                      className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm"
                      onClick={() => {
                        setIsContactList(true);
                      }}
                    >
                      Create Contact List
                    </button>
                  </Link>}
                  
                  <Link to={""}>
                    <button
                    disabled={selectedItems.length==0}
                      className="border   border-[#BABFC3] disabled:opacity-40 px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
                      onClick={() => {
                        setIsSendMail(true);
                      }}
                    >
                      Send Email
                    </button>
                  </Link>
                </div>
                <div className="float-right flex">
                  <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                  <input
                    type="text"
                    placeholder="Search"
                    className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      onKeyUpHandler(e);
                    }}
                  />
                </div>
              </div>
             
              {contactList?.length === 0 && checkResponse === true ? (
                <NoContactModal />
              ) : globalLoader ? (
                <GlobalLoader />
              ) : (
                <>
                <table className="w-full table-auto bg-white shadow-md rounded-md mt-4">
                  <thead className="cursor-pointer">
                    <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                      <th className=" w-[25%] p-2 rounded-tl-md relative">
                        <div className="flex gap-2 items-center">
                          <input
                            type="checkbox"
                            name="listName"
                            id="listname"
                            checked={
                              selectedItems.length === contactList.length && contactList.length!=0
                            }
                            onChange={(e) => {
                              checkAllboxHandler(e);
                            }}
                          />
                          <span> List Name</span>
                        </div>

                        <div className="px-[87px] ">
                          <span className="">
                            <FaSortUp
                              className={`top-[30%] absolute ${getSortArrowClassName(
                                "name",
                                "asc"
                              )}`}
                              onClick={() => sortData("name")}
                            />
                            <FaSortDown
                              className={`top-[40%] absolute ${getSortArrowClassName(
                                "name",
                                "desc"
                              )}`}
                              onClick={() => sortData("name")}
                            />
                          </span>
                        </div>
                      </th>
                      <th className=" w-[10%] py-2 px-1 whitespace-nowrap">
                        Contacts
                      </th>
                      <th className="w-[15%] p-2 ">Date Created</th>
                      <th className="w-[15%] p-2 ">Last Updated</th>
                      <th className="w-[15%] p-2 ">Mail Sent</th>
                      <th className="w-[15%] p-2 ">Action</th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {contactList?.map((item) => {
                      return (
                        <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow text-[#202223] text-sm font-normal">
                          <td className="px-2 py-3  flex gap-2 items-center ">
                            <input
                              type="checkbox"
                              name="name"
                              id="name"
                              checked={selectedItems.includes(item._id)}
                              // value={item._id}
                              onChange={(e) => {
                                checkboxHandler(e, item);
                              }}
                            />
                            <p>{item?.name}</p>
                          </td>
                          <td className="p-2  leading-5">
                            <p>{item?.numberOfContact}</p>
                          </td>
                          <td className="p-2 ">
                            {dateConversion(item?.createdAt)[0]}
                          </td>
                          <td className="p-2">
                            {dateConversion(item?.updatedAt)[0]}
                          </td>
                          <td className="p-2">{item?.mailSent}</td>
                          <td className="">
                            <div className="flex items-center   text-black ">
                              <Link
                                to="/addcontactlist"
                                state={{
                                  isContactActive: true,
                                  id: item?._id,
                                  name: item?.name,
                                }}
                              >
                                {(item?.numberOfContact === 0 ||
                                  item?.numberOfContact === undefined) && (
                                  <MdOutlineAddBox
                                  data-tooltip-id="my-tooltip" data-tooltip-content="Add contact"
                                    className={`${(item?.numberOfContact !== 0 ||
                                      item?.numberOfContact !== undefined) ? "invisibe":"" } hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px]`}
                                  />
                                 
                                )}
                                 <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                              </Link>
                              <Link
                                to="/addcontactlist"
                                state={{
                                  isContactActive: true,
                                  id: item?._id,
                                  name: item?.name,
                                }}
                              >
                                <AiOutlineEye
                                 data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                  className={`${
                                    item?.numberOfContact === 0 ||
                                    item?.numberOfContact === undefined
                                      ? "hidden"
                                      : ""
                                  } hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px]`}
                               
                                />
                                   <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                              </Link>
                              {(userInfo?.role==="Admin" || permission?.write || userInfo?.role==="Exhibitors") &&
                              <FiEdit2
                               data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                                onClick={() => {
                                  setIsEdit(true);
                                  setItem(item);
                                }}
                              />}
                               <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                              {(userInfo?.role==="Admin" || userInfo?.role==="Exhibitors") &&<>
                              <FiTrash
                               data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                                onClick={() => {
                                  setDeleteBtn(true);
                                  setDeleteBtnId(item?._id);
                                }}
                              />
                               <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" /></>}
                            </div>

                            {/* <Link to="/addcontactlist" state={{isContactActive:true, id:item?._id,name:item?.name}}>
                          <button
                            type="button"
                            className="px-3 py-2 text-white font-normal text-xs bg-theme-color rounded"
                          >
                            Add Contacts
                          </button>
                          </Link> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {
                  (contactList.length>limit && !checkResponse) &&  <div className="flex justify-between items-start ">
                  <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
                    pageCount === 1 ? totalCount : secondResultCount
                  } of ${totalCount} results`}</span>
  
                  <Pagination
                    firstResultCount={firstResultCount}
                    setFirstResultCount={setFirstResultCount}
                    secondResultCount={secondResultCount}
                    setSecondResultCount={setSecondResultCount}
                    limit={limit}
                    api={contactListPaginationApi}
                  />
                </div>
                }
                
               </>
              )}
             
            </div>
          ) : (
            <History />
          )}
          
        </div>
      </div>
    </>
  );
};
export default ContactList;
