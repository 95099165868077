export const INFORMATION_ICON = ({className:classes}) => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-[80px] h-[80px] md:w-[104px] md:h-[104px] ${classes}`}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M52 8.66663C28.08 8.66663 8.66669 28.08 8.66669 52C8.66669 75.92 28.08 95.3333 52 95.3333C75.92 95.3333 95.3334 75.92 95.3334 52C95.3334 28.08 75.92 8.66663 52 8.66663ZM47.6667 30.3333V39H56.3334V30.3333H47.6667ZM56.3334 69.3333C56.3334 71.7166 54.3834 73.6666 52 73.6666C49.6167 73.6666 47.6667 71.7166 47.6667 69.3333V52C47.6667 49.6166 49.6167 47.6666 52 47.6666C54.3834 47.6666 56.3334 49.6166 56.3334 52V69.3333ZM17.3334 52C17.3334 71.11 32.89 86.6666 52 86.6666C71.11 86.6666 86.6667 71.11 86.6667 52C86.6667 32.89 71.11 17.3333 52 17.3333C32.89 17.3333 17.3334 32.89 17.3334 52Z" fill="#FF5C67"/>
  </svg>
);

export const IMAGE_ICON = ({ className: classes }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.3327 33.3334V5.33341C47.3327 2.76675 45.2327 0.666748 42.666 0.666748H14.666C12.0993 0.666748 9.99935 2.76675 9.99935 5.33341V33.3334C9.99935 35.9001 12.0993 38.0001 14.666 38.0001H42.666C45.2327 38.0001 47.3327 35.9001 47.3327 33.3334ZM22.5993 25.2367L26.4027 30.3234L32.4227 22.8101C32.8894 22.2267 33.776 22.2267 34.2427 22.8101L41.1493 31.4434C41.756 32.2134 41.2193 33.3334 40.2393 33.3334H16.9993C16.0427 33.3334 15.4827 32.2367 16.066 31.4667L20.7327 25.2367C21.1993 24.6301 22.1327 24.6301 22.5993 25.2367ZM0.666016 42.6667V12.3334C0.666016 11.0501 1.71602 10.0001 2.99935 10.0001C4.28268 10.0001 5.33268 11.0501 5.33268 12.3334V40.3334C5.33268 41.6167 6.38268 42.6667 7.66602 42.6667H35.666C36.9493 42.6667 37.9993 43.7167 37.9993 45.0001C37.9993 46.2834 36.9493 47.3334 35.666 47.3334H5.33268C2.76602 47.3334 0.666016 45.2334 0.666016 42.6667Z"
      fill="#FF8F66"
    />
  </svg>
);

export const BACWARD_ARROW=({ className: classes })=>(
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0002 9.00025H5.41425L8.70725 5.70725C9.09825 5.31625 9.09825 4.68425 8.70725 4.29325C8.31625 3.90225 7.68425 3.90225 7.29325 4.29325L2.29325 9.29325C1.90225 9.68425 1.90225 10.3163 2.29325 10.7073L7.29325 15.7072C7.48825 15.9022 7.74425 16.0002 8.00025 16.0002C8.25625 16.0002 8.51225 15.9022 8.70725 15.7072C9.09825 15.3162 9.09825 14.6842 8.70725 14.2933L5.41425 11.0002H17.0002C17.5532 11.0002 18.0002 10.5522 18.0002 10.0002C18.0002 9.44825 17.5532 9.00025 17.0002 9.00025Z" fill="#5C5F62"/>
</svg>
);