import React, { useContext } from "react";

import { DataContext } from "../../context/DataState";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { useState } from "react";
import Pagination from "../Pagination/Pagination";

const PublishedLog = () => {
  const{publishList,globalLoader,setGlobalLoader,currentPage,setCurrentPage,publishPaginationApi,totalCount,setTotalCount,pageCount,checkResponse}= useContext(DataContext);
  const limit=15
  const [firstResultCount,setFirstResultCount]=useState(1)
  const [secondResultCount,setSecondResultCount]=useState(limit)
 
  useEffect(()=>{
    // getPublishApi()
    
    publishPaginationApi(1,limit)
  },[])
 console.log(globalLoader)
  return (
    <>
    <ToastContainer/>
      <div className="w-full bg-white px-8 pt-[26px] overflow-y-scroll h-screen space-y-4">
        <div className="flex justify-between items-center">
          <span>
            <h1 className="text-xl text-[#202223] font-semibold">Published Log</h1>
          </span>

          {/* <div className="">
            <Link>
              <button className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm">
                Add new FAQ
              </button>
            </Link>
           
          </div> */}
        </div>
        {/*--------- table list--------- */}
        {
          globalLoader ? <GlobalLoader/>:
          <>
        <table className="w-full table-auto bg-white shadow-md rounded-md ">
          {
            (publishList.length==0 && checkResponse) ? <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>:
            <>
              <thead className="cursor-pointer">
            <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
              <th className=" w-1/6 p-2">Published Date</th>
              <th className=" w-1/6 p-2">Published Time</th>
              <th className=" w-1/6 p-2"></th>
              
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
            {
              publishList?.map((item)=>{
              return (

             
          <tr className="text-sm font-sm" key={item?._id}>
            <td className="p-2">{new Date(item?.time).toDateString()}</td>
            <td className="p-2">{new Date(item?.time).toTimeString().slice(0,5)}</td>
            <td className="p-2"></td>
           
          </tr>
           )
          })
        }
          {/*<tr className="text-sm font-sm">
            <td className="p-2">12/06/2023</td>
            <td className="p-2">09:30 am</td>
            <td className="p-2"></td>
           
          </tr>*/}
         
          
            {/* {faqList.map((item) => {
              return (
                <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow">
                  <td className="p-2 text-sm text-[#202223] leading-5">
                    <p>{item?.FAQText}</p>
                  </td>
                  <td className="p-2 text-sm text-[#202223] leading-5">
                    <p>{item?.FAQAnswer}</p>
                  </td>
                  <td className="p-2">
                    <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color">
                      <Link>
                        <FiEdit2 />
                      </Link>
                      <FiTrash />
                    </div>
                  </td>
                </tr>
              );
            })} */}
          </tbody>
            </>
          }

        
        </table>
        <div className="flex justify-between items-start ">
         <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount}  of ${totalCount} results`}</span>
        <Pagination   firstResultCount={firstResultCount} setFirstResultCount={setFirstResultCount} secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} limit={limit} api={publishPaginationApi}/>
        </div>
        
        </>
        }
       
      </div>
    </>
  );
};

export default PublishedLog;
