import React from "react"
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const ViewContact=()=>{
  const location=useLocation()
  const state=location.state.item
  console.log(state,"state")
  const isContactActive=location.state.isContactActive
  const id=location.state.id 
  const name=location.state.name
  console.log(state,"state")
  // const {getEmailTemplate,emailTemplate } = useContext(DataContext)
  // useEffect(() => {
  //   getEmailTemplate();
  // }, []); // Empty dependency array ensures this runs once on mount

  return (
    <>
     <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
              <Link to={"/addcontactlist"} state={{isContactActive:isContactActive,id:id,name:name}}>
              <button className="border p-2">
                <FaArrowLeft />
              </button>
              </Link>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">View Contact </h1>
          </div>
        </div>
        <div className="flex-col w-full  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Contact details</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full ">
          <p className="text-sm font-medium">First Name*</p>
          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.firstName}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Last Name*</p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.lastName}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Organization</p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.organization ? state?.organization : "No Data"}</p>
        </div>
        
       </div>

       <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full ">
          <p className="text-sm font-medium">Phone Number*</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.phoneNumber}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Email Address*</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.email}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Position</p>
          <p className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{state?.position ? state?.position : "No Data"}</p>
        </div>
        
       </div>
       
       <div className="mt-3">
                <input
                  type="checkbox"
                  name="invitationEmail"
                  id="invitationEmail"
                  checked={state?.sendInvitationMail ? true : false}
                 
                />
                <span className="mt-4 text-sm px-3 font-medium  text-[#202223]">
                  Send Invitation Email
                </span>
              </div>
              <div>
                <select
                  id="template"
                  type="text"
                  name="template"
                  checked={(state.sentInvitationMail) ? "checked" : ""}
                  className="w-[325px] border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                >
                  <option value="" selected disabled hidden>
                  {state.sendInvitationMail ? state.sendInvitationMail : "No Template Choosen"
                  }
                  </option>
                </select>
              </div>
              <div className="mt-7"> 
              <Link to={"/addcontactlist"} state={{isContactActive:isContactActive,id:id,name:name}}>
              <button className="border-2 px-16 py-2 text-sm  hover:bg-gray-300 rounded">
                      Back
                    </button>
                    </Link>
              </div>
              
        
        </div>


      
      </div>
    </>
  )
}

export default ViewContact;