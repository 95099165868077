import React, { useEffect, useState, useContext } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { ImBin } from "react-icons/im";
import { DataContext } from "../../context/DataState";
import PreviewModal from "./PreviewModal";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../DeleteModal/DeleteModal";

const Registration = () => {
  let eventId = JSON.parse(localStorage.getItem("eventId"));
  const {
    createRegistrationApi,
    getRegistraition,
    getRegData,
    setGetRegData,
    deleteRegistrationColumnApi,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteBtn,
  } = useContext(DataContext);

  const [defaultData, setDefaultData] = useState([
    {
      columnName: "Name",
      dataType: "string",
      validation: "text",
      maximumLength: "25",
      required: true,
      event_id: eventId,
    },
    {
      columnName: "email",
      dataType: "string",
      validation: "email",
      maximumLength: "40",
      required: true,
      event_id: eventId,
    },
    {
      columnName: "Phone Number",
      dataType: "number",
      validation: "mobilenumber",
      maximumLength: "10",
      required: true,
      event_id: eventId,
    },
  ]);
  const [newColumnList, setNewColumnList] = useState([]);
  const [columnList, setColumnList] = useState([...defaultData]);
  const [previewDialog, setPreviewDialog] = useState(false);

  const [validationTypeState, setValidationTypeState] = useState();

  const handleChecking = (e, index) => {
    console.log(columnList[index].dataType, index, "dataType");
    if (columnList[index].dataType === "string") {
      setValidationTypeState(["text", "email"]);
    } else if (columnList[index].dataType === "number") {
      setValidationTypeState(["number", "mobilenumber"]);
    } else if (columnList[index].dataType === "") {
      setValidationTypeState();
    }
  };

  const handleAddColumn = () => {
    setValidationTypeState();
    setNewColumnList([
      ...newColumnList,
      {
        columnName: "",
        dataType: "",
        validation: "",
        maximumLength: "",
        required: true,
        event_id: eventId,
      },
    ]);
    setColumnList([
      ...columnList,
      {
        columnName: "",
        dataType: "",
        validation: "",
        maximumLength: "",
        required: true,
        event_id: eventId,
      },
    ]);
  };

  const handleCreatingColumn = (e, index) => {
    console.log(e, "e");
    const { name, value, checked } = e.target;
    const list = [...columnList];
    const list2 = [...newColumnList];

    list[index][name] = value;
    list2[index][name] = value;
    if (name === "dataType") {
      list[index].validation = "";
    }

    if (name === "dataType") {
      list[index].validation = "";
      list2[index].validation = "";
    }

    setColumnList(list);
    setNewColumnList(list2);
  };
  let list2 = getRegData.length > 3 ? getRegData.slice(3) : [];

  const handleRemoveColumn = (index) => {

    const updatedColumnList = [...columnList];
    updatedColumnList.splice(index, 1);

    const updatedNewColumnList = [...newColumnList];
    updatedNewColumnList.splice(index, 1);

    setColumnList(updatedColumnList);
    setNewColumnList(updatedNewColumnList);
  };

  useEffect(() => {
    console.log(columnList.length, "columnList.length");
    if (columnList.length == 0) {
      handleAddColumn();
    }
  }, [columnList.length == 0]);

  useEffect(() => {
    getRegistraition();
  }, []);

  console.log(getRegData, "getRegdata");
  console.log(columnList, "columnList");
  console.log(newColumnList, "newColumnList");
  console.log(deleteBtnId, "deleteBtnId");
  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteRegistrationColumnApi}
          deleteId={deleteBtnId}
          module="registration"
        />
      ) : (
        ""
      )}
      {previewDialog ? (
        <PreviewModal setPreviewDialog={setPreviewDialog} />
      ) : null}
      <div className="flex w-full bg-white">
        <div className="w-full pl-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4 pr-16">
          <div className="flex justify-between items-end">
            <div className="flex items-start gap-4">
              <Link to={"/agenda-list"}>
                <div className="w-8 pt-1">
                  <button className="border p-2">
                    <FaArrowLeft />
                  </button>
                </div>
              </Link>
              <div className="p-2 ">
                <h1 className="px-2 text-xl">Registration Form</h1>
              </div>
            </div>
            <button
              className="bg-theme-color px-8 text-sm py-2 rounded disabled:opacity-50 hover:bg-orange-500 text-white ml-3"
              onClick={() => setPreviewDialog(true)}
            >
              Preview Form
            </button>
          </div>

          <div className="flex space-x-10 ">
            <div className="w-full space-y-4 ">
              <h2 className="">Fields</h2>
              <hr />
              {(getRegData.length == 0 ? columnList : getRegData)?.map(
                (data, index) => (
                  <>
                    <form className="">
                      <div className="flex items-center gap-4 w-full">
                        <div className="w-[20%]">
                          <label
                            htmlFor="title"
                            className="text-sm px-1 font-medium text-[#202223] pb-1"
                          >
                            Fields {index + 1}
                          </label>
                          <input
                            type="text "
                            id="title"
                            name="columnName"
                            value={
                              (getRegData.length == 0
                                ? columnList
                                : getRegData)[index].columnName
                            }
                            className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                            onChange={(e) => handleCreatingColumn(e, index)}
                          />
                          {/* <span className="text-[#DB4446] relative bottom-[27px] left-[353px]"><ImBin size={18}/></span> */}
                        </div>
                        <div className="flex gap-2 mt-6">
                          <div className="">
                            {/* <label htmlFor="time-zone" className="font-medium text-sm text-[#202223] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder">Time zone</label> */}
                            <select
                              name="dataType"
                              id="time-zone"
                              className="w-[200px] border font-normal text-sm border-gray-300  outline-none px-3 py-2 rounded-[4px]"
                              value={
                                (getRegData.length == 0
                                  ? columnList
                                  : getRegData)[index].dataType
                              }
                              onChange={(e) => handleCreatingColumn(e, index)}
                            >
                              <option value="defaultOptionValue">
                                Choose Data Type
                              </option>
                              <option value="string">String</option>
                              <option value="number">Number</option>
                            </select>
                          </div>

                          <div className="">
                            {/* <label htmlFor="time-zone" className="font-medium text-sm text-[#202223] focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 focus-visible:border-inputBorder">Time zone</label> */}
                            <select
                              name="validation"
                              id="time-zone"
                              className="w-[200px] border font-normal text-sm border-gray-300 outline-none px-3 py-2 rounded-[4px]"
                              value={
                                (getRegData.length == 0
                                  ? columnList
                                  : getRegData)[index].validation
                              }
                              onClick={(e) => {
                                handleChecking(e, index);
                              }}
                              onChange={(e) => handleCreatingColumn(e, index)}
                            >
                              {/* <option value="defaultOptionValue" selected hidden>Choose Validation</option> */}
                              <option>
                                {(getRegData.length == 0
                                  ? columnList
                                  : getRegData)[index].validation === "" ? (
                                  <>Choose Validation</>
                                ) : (
                                  (getRegData.length == 0
                                    ? columnList
                                    : getRegData)[index].validation
                                )}
                              </option>
                              {/* {console.log(columnList[index].validation, "validationTypeState")} */}
                              {validationTypeState === undefined ? (
                                <option>Choose your data Type first</option>
                              ) : (
                                validationTypeState?.map((item) => (
                                  <option value={item}>
                                    {item === "text" ? "text" : item}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                          <div>
                            <div>
                              <input
                                type="text"
                                className="w-[200px] border font-normal text-sm border-gray-300 placeholder:text-[#241C15] outline-none px-3 py-2 rounded-[4px]"
                                placeholder="Enter Maximum Length"
                                value={
                                  (getRegData.length == 0
                                    ? columnList
                                    : getRegData)[index].maximumLength
                                }
                                name="maximumLength"
                                onChange={(e) => handleCreatingColumn(e, index)}
                              />
                            </div>
                          </div>
                          <div className="mb-1">
                            <h4 className="text-[#777777] text-xs font-normal ">
                              Required
                            </h4>
                            <div className="flex gap-3">
                              <div className="flex gap-4">
                                <input
                                  type="checkbox"
                                  name="required"
                                  id="yes"
                                  checked={
                                    (getRegData.length == 0
                                      ? columnList
                                      : getRegData)[index].required == true
                                      ? true
                                      : false
                                  }
                                  value={true}
                                  onChange={(e) =>
                                    handleCreatingColumn(e, index)
                                  }
                                />
                                <label
                                  htmlFor="yes"
                                  className="text-[#241C15] text-sm font-normal"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="flex gap-4">
                                <input
                                  type="checkbox"
                                  name="required"
                                  id="no"
                                  checked={
                                    (getRegData.length == 0
                                      ? columnList
                                      : getRegData)[index].required !== true &&
                                    (getRegData.length == 0
                                      ? columnList
                                      : getRegData)[index].required !== ""
                                      ? true
                                      : false
                                  }
                                  value={false}
                                  onChange={(e) =>
                                    handleCreatingColumn(e, index)
                                  }
                                />
                                <label
                                  htmlFor="no"
                                  className="text-[#241C15] text-sm font-normal"
                                >
                                  No
                                </label>
                              </div>
                              {index > 2 && (
                                <span
                                  className="text-[#DB4446]"
                                  onClick={() => {
                                    setDeleteBtn(true);
                                    setDeleteBtnId(data._id);
                                    handleRemoveColumn(index);
                                  }}
                                >
                                  <ImBin size={18} />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {
                  count.map((item,index)=>{
                    return(
                      <div className="flex items-center gap-4 mt-3">
                 
                <div className="w-[20%]">
                  <label
                    htmlFor="title"
                    className="text-sm px-1 font-medium text-[#202223] pb-1"
                  >
                    {`Fields ${index+2}`}
                  </label>
                  <input
                    type="text "
                    id="title"
                    className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e)=>{
                      setColumnList([...columnList,{...columnList[index+1], columnName: e.target.value}])
                    console.log(index,"index")
                    }}
                  />
                </div>
               <div className="flex gap-2 mt-6 ">
               <div className="w-[30%]">
                  <select name="time-zone" id="time-zone" className="w-full border font-normal text-sm border-gray-300 outline-none px-3 py-2 rounded-[4px]"
                    onChange={(e)=>{setColumnList([{...columnList[index+1], dataType: e.target.value}])}}
                  >
                  <option value="defaultOptionValue">Choose Data Type</option>
                  <option value="string">String</option>
                   <option value="number">Number</option>
                 
                  </select>
                 
                </div>

                <div className="w-[30%]">
                  <select name="time-zone" id="time-zone" className="w-full border font-normal text-sm border-gray-300  outline-none px-3 py-2 rounded-[4px]"
                   onChange={(e)=>{setColumnList([{...columnList[index+1], validation: e.target.value}])}}
                  >
                  <option value="defaultOptionValue">Choose Validation</option>
                  <option value="email">Email</option>
                   <option value="number">Number</option>
                   <option value="string">String</option>
                   <option value="mobile-number">Mobile Number</option>
                  

                  </select>
                 
                </div>
                <div>
                  <div>
                  <input type="text" className="w-full border font-normal text-sm border-gray-300 placeholder:text-[#241C15]   outline-none px-3 py-2 rounded-[4px]" placeholder ="Enter Maximum Length"/>
                  </div>
                
                 

                  
                </div>
                <div className="mb-1">
                  <h4 className="text-[#777777] text-xs font-normal ">Required</h4>
                  <div className="flex gap-3">
                  <div className="flex gap-4">
                    <input type="checkbox" name="yes" id="yes"
                     onChange={(e)=>{setColumnList([{...columnList[index+1], required: e.target.name}])}}
                    />
                    <label htmlFor="yes" className="text-[#241C15] text-sm font-normal">Yes</label>
                   
                  </div>
                  <div className="flex gap-4">
                    <input type="checkbox" name="no" id="no" 
                      onChange={(e)=>{setColumnList([{...columnList[index+1], required: e.target.name}])}}
                    />
                    <label htmlFor="no" className="text-[#241C15] text-sm font-normal">No</label>
                  </div>
                  <span className="text-[#DB4446]" onClick={()=>{
                     let arrNew=count.pop()
                     setCount([...count])
                    
                  }}><ImBin size={18}/></span>
                  </div>
                  
                </div>
               
                
               </div>
                </div>
                    )
                  })
                } */}
                    </form>
                  </>
                )
              )}
              {newColumnList.length > 0 &&
                newColumnList?.map((data, index) => (
                  <>
                    <form className="">
                      <div className="flex items-center gap-4 w-full">
                        <div className="w-[20%]">
                          <label
                            htmlFor="title"
                            className="text-sm px-1 font-medium text-[#202223] pb-1"
                          >
                            Fields {index + 4}
                          </label>
                          <input
                            type="text "
                            id="title"
                            name="columnName"
                            value={newColumnList[index].columnName}
                            className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                            onChange={(e) => handleCreatingColumn(e, index)}
                          />
                        </div>
                        <div className="flex gap-2 mt-6">
                          <div className="">
                            <select
                              name="dataType"
                              id="time-zone"
                              className="w-[200px] border font-normal text-sm border-gray-300  outline-none px-3 py-2 rounded-[4px]"
                              value={newColumnList[index].dataType}
                              onChange={(e) => handleCreatingColumn(e, index)}
                            >
                              <option value="defaultOptionValue">
                                Choose Data Type
                              </option>
                              <option value="string">String</option>
                              <option value="number">Number</option>
                            </select>
                          </div>

                          <div className="">
                            <select
                              name="validation"
                              id="time-zone"
                              className="w-[200px] border font-normal text-sm border-gray-300 outline-none px-3 py-2 rounded-[4px]"
                              value={newColumnList[index].validation}
                              onClick={(e) => {
                                handleChecking(e, index);
                              }}
                              onChange={(e) => handleCreatingColumn(e, index)}
                            >
                              <option>
                                {newColumnList[index].validation === "" ? (
                                  <>Choose Validation</>
                                ) : (
                                  newColumnList[index].validation
                                )}
                              </option>

                              {validationTypeState === undefined ? (
                                <option>Choose your data Type first</option>
                              ) : (
                                validationTypeState?.map((item) => (
                                  <option value={item}>
                                    {item === "text" ? "text" : item}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                          <div>
                            <div>
                              <input
                                type="text"
                                className="w-[200px] border font-normal text-sm border-gray-300 placeholder:text-[#241C15] outline-none px-3 py-2 rounded-[4px]"
                                placeholder="Enter Maximum Length"
                                value={newColumnList[index].maximumLength}
                                name="maximumLength"
                                onChange={(e) => handleCreatingColumn(e, index)}
                              />
                            </div>
                          </div>
                          <div className="mb-1">
                            <h4 className="text-[#777777] text-xs font-normal ">
                              Required
                            </h4>
                            <div className="flex gap-3">
                              <div className="flex gap-4">
                                <input
                                  type="checkbox"
                                  name="required"
                                  id="yes"
                                  checked={
                                    newColumnList[index].required == true
                                      ? true
                                      : false
                                  }
                                  value={true}
                                  onChange={(e) =>
                                    handleCreatingColumn(e, index)
                                  }
                                />
                                <label
                                  htmlFor="yes"
                                  className="text-[#241C15] text-sm font-normal"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="flex gap-4">
                                <input
                                  type="checkbox"
                                  name="required"
                                  id="no"
                                  checked={
                                    newColumnList[index].required !== true &&
                                    newColumnList[index].required !== ""
                                      ? true
                                      : false
                                  }
                                  value={false}
                                  onChange={(e) =>
                                    handleCreatingColumn(e, index)
                                  }
                                />
                                <label
                                  htmlFor="no"
                                  className="text-[#241C15] text-sm font-normal"
                                >
                                  No
                                </label>
                              </div>
                              {/* {index > 2 && ( */}
                              <span
                                className="text-[#DB4446]"
                                onClick={() => {
                                  handleRemoveColumn(index);
                                }}
                              >
                                <ImBin size={18} />
                              </span>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ))}
              <div className="w-[35%] mt-4">
                <button
                  type="button"
                  className="bg-theme-light-color border border-theme-color px-3  text-sm py-2 rounded disabled:opacity-50  text-white "
                  onClick={() => {
                    handleAddColumn();
                  }}
                >
                  <div className="flex gap-2 items-center ">
                    <span className="text-theme-color text-sm font-normal">
                      Add Field
                    </span>
                    <span className="text-theme-color">
                      <IoIosAdd size={24} />
                    </span>
                  </div>
                </button>

                <button type="button rounded border bg-theme-light-color bg-[#FF8531] "></button>
              </div>
            </div>
          </div>
          <div className="pt-1">
            <Link to={"/agenda-list"}>
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              className="bg-theme-color px-8 text-sm py-2 rounded disabled:opacity-50 hover:bg-orange-500 text-white ml-3"
              onClick={() => {
                if (getRegData.length == 0) {
                  createRegistrationApi([...defaultData, ...newColumnList]);
                } else {
                  createRegistrationApi(newColumnList);
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
