import React from 'react'
import { useState, useEffect ,useRef} from 'react';
import { FaArrowLeft} from "react-icons/fa";
import { Link ,useParams,useLocation} from 'react-router-dom';
// import { FiX, FiMessageSquare, FiUser, FiChevronDown } from "react-icons/fi";
import { useContext } from 'react';
import { DataContext } from '../../context/DataState';
import { ToastContainer } from 'react-toastify';
import { MdClose } from "react-icons/md";

const UpdateSponsor = () =>{
    const {getSponsorList,sponsorsList,sponsorsLevel,updateSponsorApi,getSponsorLevelApi,disableSave,isSpinner} = useContext(DataContext);
  
    const [count,setCount]=useState(0);
    const [bannerimage,setbannerimage]=useState(null)
    const [imageError,setImageError]=useState(false)
    const [emailError, setEmailError] = useState(false);
    const [image,setimage] = useState(null);
    const [imageErrors,setImageErrors]= useState(false)
    const {id} = useParams()
    const [phoneFormatTest, setPhoneFormatTest] = useState(false);
    const [urlError,setUrlError] =useState(false);
    const logoImageInput=useRef()
    const bannerImageInput=useRef()
    const location = useLocation()
    const sponsorLevelEdit = location.state;
    const [editSponsor,setEditSponsor] = useState(sponsorLevelEdit);

    const validUrl =new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/); 
    const validateUrl = () =>{
    if(!validUrl.test(editSponsor.url) && editSponsor.url !=="") {
      setUrlError(true);
    } else {
      setUrlError(false);
    }
  };
    const validatePhoneno = (phoneData) => {
      console.log(phoneData,"phobe")
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (phoneData.match(phoneno)) {
        return setPhoneFormatTest(false)
      }
      else if (phoneData === '') {
        return setPhoneFormatTest(false)
      }
      else {
        return setPhoneFormatTest(true)
  
      }
    }
  
    const handleUpdate = () =>{
      if(!isSpinner)
        updateSponsorApi(editSponsor , editSponsor?._id)
    }

    const validEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  
    const validateEmail = () => {
      if (!validEmail.test(editSponsor.email) && editSponsor.email !== "") {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    };

    const handleChange=(e)=>{
      setEditSponsor({ ...editSponsor, description: e.target.value })
      const textLength = e.target.value.split("").length;
     setCount(textLength);
    }

    //---------------------------bannerimage validation
  const onImageChange=(event)=>{
    const allowedExtensions = ['jpg', 'jpeg', 'png','webp'];
  
    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      return allowedExtensions.some(extension => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(event.target.files[0].name, allowedExtensions);
    
    if (event.target.files && event.target.files[0]) {
      setbannerimage(URL.createObjectURL(event.target.files[0]));
    }
    

    if (!isAllowed) {
      setImageError('Please select valid image format.');
      return false;
    }
    
    if(( event.target.files[0].size) && (event.target.files[0].size)/1024 > 1024)
    {
      setImageError("Please select image less than 1 mb")
    }
    else{
      setImageError(false)
      setEditSponsor({ ...editSponsor, bannerimage: event.target.files[0] });
    }
  } 
//-------------------------image validation---------------
  const onImageFormat=(event)=>{
    if (event.target.files && event.target.files[0]) {
      setimage(URL.createObjectURL(event.target.files[0]));
      
    }
    if ( event.target.files[0].name &&  !event.target.files[0].name.match(/\.(png)$/)) {
      setImageErrors('Please select valid image format.');
      return false;
    }
    
    if(( event.target.files[0].size) && (event.target.files[0].size)/1024 > 1024)
    {
     setImageErrors("Please select image less than 1 mb")
    }
    else{
    setImageErrors(false)
     setEditSponsor({ ...editSponsor,image: event.target.files[0]});
    }
  } 

  const handleBannerDelete = ()=>{
    setEditSponsor({...editSponsor, bannerimage: ""});
    setbannerimage(null)
    bannerImageInput.current.value = "";
    
  }
  const handleLogoDelete = ()=>{
    setEditSponsor({...editSponsor, image: ""});
    setimage(null)
    logoImageInput.current.value = "";
    
  }  


    useEffect(()=>{
      getSponsorLevelApi()
        getSponsorList()
        const newArr =sponsorsList.filter((element)=> {
            return (element?._id === id)
        })
    setEditSponsor({...newArr[0]})
    
     },[])
     
     const buttonDisabled = (editSponsor?.sponsor_name !== "" && editSponsor?.description !== "" && editSponsor?.email !== "" && editSponsor?.phone !== "" && editSponsor?.bannerimage !== ""  && editSponsor?.image !== "" && editSponsor?.url!=="" &&urlError!=true&&emailError!=true&&phoneFormatTest!=true && !imageError && !imageErrors)
console.log(editSponsor,"sponsor")
     return (
    <>
      <div className='flex w-full '>
        <div className='w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4'>
          <div className='flex items-center'>
            <Link to="/sponsors">
              <div className='shadow-xs border-2 p-2  '>
                < FaArrowLeft className='text-[20px] text-[#5C5F62]' />
              </div>
            </Link>
            <div>
              <h1 className='text-xl pl-3'>{editSponsor?.sponsor_name} </h1>
            </div>
          </div>
          
          <div className='flex flex-col p-4 rounded-lg bg-white border-[#D1D9E7]'>
          <div>
            <h2 className='font-semibold tetx-[18px]'>Base Data</h2>
            <hr className='w-[48%]' />
          </div>
          <div className='flex space-x-10 mt-2'>
            <div className='w-1/2 space-y-3 pt-1'>
              <div>
                <label htmlFor="sponsor_name" className='px-1 font-semibold pb-4 text-sm '>Sponsor Name *</label>
                  <input type='text' id="sponsor_name" name="sponsor_name" placeholder="Enter name" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                    value={editSponsor?.sponsor_name} onChange={(e)=>{setEditSponsor({...editSponsor,sponsor_name:e.target.value})}} />
              </div>
              <div className='pt-2'>
                <label htmlFor="description" className=' text-sm px-1 font-semibold pb-1 '>Description *</label>
                <textarea rows="4" name="description" placeholder="Provide some details about the Sposnors." className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm'
                 value={editSponsor?.description} onChange={(e) => { handleChange(e) }} maxLength={2000} />
                <div className='flex justify-between item-center'>
                   <span className='text-xs text-gray-500 '>Provide some details about the Sponsor.</span>
                   <span className="bottom-2 text-xs ">{count}/2000</span>
                  </div>
              </div>
              <div className='pt-2'>
                <label htmlFor="url" className=' text-sm px-1 font-semibold pb-1'>Website Url *</label>
                <input type='text' name="url" placeholder="Enter website url" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 '
                  value={editSponsor?.url} 
                  onChange={(e)=>{setEditSponsor({...editSponsor,url:e.target.value})}}
                  onKeyUp={() => validateUrl()}
                />
                {urlError && (
                  <span className="text-left text-red-600 text-sm">Please enter a valid url</span>
                )}
              </div>
              <div className='pt-2'>
                <label htmlFor="email" className=' text-sm px-1 font-semibold pb-1'>Email *</label>
                <input type='text' name="email" placeholder="Enter email " className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                  value={editSponsor?.email} onChange={(e)=>{setEditSponsor({...editSponsor,email:e.target.value})}}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                    <span className="text-left text-red-600 text-sm ">
                      Please enter a valid Email
                    </span>
                  )}
              </div>
              <div className='pt-2'>
                <label htmlFor="phone" className=' text-sm px-1 font-semibold pb-1'>Contact Number *</label>
                <input type='text' id="phone"  pattern="[0-9]{10}" name="phone" placeholder='Enter contact number' className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                 value={editSponsor?.phone} onChange={(e)=>{setEditSponsor({...editSponsor,phone:e.target.value})}}
                 onKeyUp={() => validatePhoneno(editSponsor?.phone)}  />
                 {phoneFormatTest ? <span className="text-red-600 text-sm">Please enter a valid 10 digit Number.</span> : <></>}
              </div>
            </div>

            <div className='w-1/2 '>
              {/* <div className='pt-3'>
                <h1 className='text-sm  font-semibold pb-1'>Banner image *</h1>
                <div className=' h-24 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color'>
                  <label htmlFor="choose_banner" accept="image/png, image/jpeg, image/jpg" className='text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs'>
                    Choose banner image

                  {editSponsor.image == "" ? "Add files" : editSponsor?.image}
                
                    <input id="choose_banner" type='file' className='text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md '
                      onChange={(e) => {
                        onImageChange(e);
                      }}/>
                  </label>
                  <p className="text-xs pt-1 text-gray-400">
                  Accepts,.jpeg, .jpg and .png
                </p>
                </div>
                <span className="text-xs text-red-600 font-normal">{imageError ? imageError : ""}</span>
              <div className={(image===null && !imageError ) ?  ""  : "hidden"}>
                <img src={image === null ? editSponsor.bannerimage : image} alt="banner" className="w-32 h-28 pt-2 object-contain"/>
              </div>
               
              <div className={(image!=null && !imageError ) ?  ""  : "hidden"}>
                <img src={image} alt="banner" className="w-32 h-28 pt-2 object-contain"/>
              </div>
              </div> */}
              <div className='pt-3'>
                <h1 className='text-sm  font-semibold pb-1'>Banner image *</h1>
                <div className=' h-24 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color'>
                  <label htmlFor="choose_banner" 
                  // accept="image/png, image/gif, image/jpeg, image/svg" 
                  className='text-theme-color cursor-pointer  text-sm '>
                    <span className=' bg-theme-light-color px-2 py-1'> 
                      {(bannerimage===null) ? "Choose banner image" : editSponsor?.bannerimage?.name}
                    </span>
                    <p className='text-sm pt-1 text-gray-400 leading-4'>Accepts jpeg, jpg, png and webp </p>
                    <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                    </p>
                    <input id="choose_banner" type='file' 
                    // accept='image/jpeg, image/png' 
                    ref={bannerImageInput}
                    className='text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md '
                      onChange={(e) => {
                        // setEditSponsor({ ...editSponsor, bannerimage: e.target.files[0]});
                        onImageChange(e);
                      }} />
                  </label>
                </div>
              </div>
              <span className="text-xs text-red-600 font-normal">{imageError ? imageError : ""}</span>
              <div className={(bannerimage===null && !imageError ) && editSponsor?.bannerimage!=="" ?  "relative w-48 h-28 mt-4"  : "hidden"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleBannerDelete()}/>
                <img src={bannerimage === null ? editSponsor?.bannerimage : bannerimage} alt="banner" className="absolute w-full h-full "/>
              </div>
              <div className={(bannerimage !=null && !imageError ) ?  "relative w-48 h-28 mt-4"  : "hidden"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleBannerDelete()}/>
                <img src={bannerimage} alt="banner" className="absolute w-full h-full "/>
              </div>

              <div className='pt-3'>
                <h1 className='text-sm  font-semibold pb-1'>Logo</h1>
                <div className=' h-30 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color'>
                  <label htmlFor="choose_image" accept="image/png, image/gif, image/jpeg, image/svg" className='text-theme-color cursor-pointer  text-sm '>
                    
                    <span className=' bg-theme-light-color px-2 py-1'> 
                    {(image===null) ? "Choose logo image": editSponsor?.image?.name}
                   </span>
                  
                    <p className='text-sm pt-1 text-gray-400 leading-4'>Accepts only png </p>
                    <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                    </p>
                    <input id="choose_image" type='file' 
                    // accept='image/jpeg, image/png'
                    ref={logoImageInput} 
                    className='text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md '
                      onChange={(e) => {
                        // setEditSponsor({ ...editSponsor,image: e.target.files[0]});
                        onImageFormat(e);
                      }} />
                  </label>
                </div>
              </div>
              <span className="text-xs text-red-600 font-normal">{imageErrors ? imageErrors : ""}</span>
              <div className={(image===null && !imageErrors ) && editSponsor?.image!==""? "mt-4 relative w-32 h-28" : "hidden"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleLogoDelete()}/>
                <img src={image === null ? editSponsor?.image : image} alt="banner" className="absolute w-full h-full "/>
              </div>
              <div className={(image !=null && !imageErrors ) ?  "mt-4 relative w-32 h-28"  : "hidden"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleLogoDelete()}/>
                <img src={image} alt="banner" className="absolute w-full h-full "/>
              </div>

              <div className='pt-3'>
                <label htmlFor="sponsor-level" className='text-sm px-1 font-semibold pb-1'>Level *</label>
         
              <select className='w-full  py-1 px-3 border border-gray-300 rounded-md focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm  focus-visible:shadow-focusshadow 
                focus-visible:border-1'
                onChange={(e)=>{setEditSponsor({...editSponsor,level:JSON.parse(e.target.value)})}}>
                  <option  disabled
                   
                     value = "Choose Sponsor Level">
                    Choose Sponsor Level</option>
                  {
                     sponsorsLevel.map((item,index) => {
                      return (
                        <option className='rounded-md hover:bg-theme-color' selected={editSponsor?.level_name === item?.level_name ? "selected": ""}
                        value={JSON.stringify(item)} key={index}> {item?.level_name}</option>
                          
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='w-1/2 space-y-3 pt-1'>
            <div className='flex pt-6 gap-3'>
              <Link to={"/sponsors"}>
                <button className='border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded'>Back</button>
              </Link>
              <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleUpdate();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
            </div>
          </div>
          </div>
          

        </div>
      </div>

    </>
  )
}
export default UpdateSponsor
