import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../atoms/CutomButton";
import FileDragAndDrop from "../atoms/FileDragAndDrop";
import { BACWARD_ARROW } from "../atoms/icons";
import { ADD_USER, GET_USER_BY_ID, GET_USER_ROLE, UPDATE_USER_BY_ID } from "../../constants/apiEndPoints";
import fetchUtil from "../../utils/fetch.util";
import { toast } from "react-toastify";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  roleId: ""
};

const AddUser = () => {
  const eventID = JSON.parse(localStorage.getItem("eventId")) || "";
  const imgSize = 1000000;
  const navigate = useNavigate();
  const params = useParams();
  const handleNavigation = () => navigate(-1);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState();
  const [receivedFile, setReceivedFile] = useState(); // to show image from backend

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    fetchUtil.GET(`${GET_USER_ROLE}/${eventID}`)
      .then((response) => {
        setUserRoles(response.Result.docs);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      fetchUtil.GET(`${GET_USER_BY_ID}/${params.id}`)
        .then((response) => {
          console.log(response)
          let details = response.Result;
          setFormData((prev) => ({
            ...prev,
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            phone: String(details.phone),
            roleId: details.roleId,
          }));
          setReceivedFile(details.image);
        })
        .catch((err) => { console.error(err) })
        .finally(() => setLoading(false));
    }
  }, [params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let imgFile = uploadedFile || receivedFile; // use uploadedFile if a new file is uploaded, else use receivedFile
  
    if (uploadedFile && uploadedFile[0]?.size > imgSize) {
      toast.error('Image size is too large');
      return;
    }
  
    let formDetails = new FormData();
    formDetails.append('firstName', formData.firstName);
    formDetails.append('lastName', formData.lastName);
    formDetails.append('email', formData.email);
    formDetails.append('phone', formData.phone);
    formDetails.append('roleId', formData.roleId);
  
    // Only append image if a new file was uploaded
    if (uploadedFile) {
      formDetails.append('image', uploadedFile[0]);
    } else {
      formDetails.append('image', receivedFile || ""); // Use the existing image if no new file is uploaded
    }
  
    formDetails.append('role', "SubAdmin");
  
    setLoading(true);
    if (!params.id) {
      fetchUtil.POST(`${ADD_USER}/${eventID}`, formDetails, 'formData')
        .then((response) => {
          toast.success("Details added successfully");
          setTimeout(() => {
            navigate('/users');
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        })
        .finally(() => setLoading(false));
    } else {
      fetchUtil.PUT(`${UPDATE_USER_BY_ID}/${params.id}`, formDetails, 'formData')
        .then((response) => {
          console.log(response);
          toast.success("Updated successfully");
          setTimeout(() => {
            navigate('/users');
          }, 500);
        }).catch((err) => {
          console.error(err);
          toast.error(err);
        }).finally(() => setLoading(false));
    }
  }
  

  const onUpload = useCallback((files) => {
    setUploadedFile(files);
  }, [setUploadedFile])

  const handleFormData = (e) => {
    if (e.target.name === 'phone') {
      if (e.target.value.length <= 10) {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      } 
      else if(e.target.value.length < 10){
        toast.error("Phone must be of 10 digits")
      }
    
    }
    else {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const isFormValid = () => {
    return formData.firstName?.trim() !== "" && formData.lastName?.trim() !== "" && formData.email?.trim() !== "" && formData.phone !== "" && formData.phone?.length === 10 && formData.roleId && (uploadedFile || receivedFile);
  };

  const phoneLength = formData.phone ? formData.phone.length : 0;
  const title = params.id ? 'Edit' : 'Add';

  return (
    <>
      <div className="w-full px-8 py-2 overflow-y-scroll h-screen">
        <div className="my-[20px] flex" >
          <div className="w-[36px] h-[36px] mr-8 rounded-[4px] items-center border border-[#BABFC3] flex justify-center cursor-pointer" onClick={handleNavigation}>
            <BACWARD_ARROW />
          </div>
          <div className="flex items-center justify-start">
            <h1 className="text-xl font-semibold font-sans tracking-wide">{title} User</h1>
          </div>
        </div>
        {loading ? <GlobalLoader /> :
          <div className="bg-white p-[1rem] rounded-xl w-[560px] mb-[16px]">
            <div className="w-full">
              <h3 className="text-[18px] pb-3 font-medium">User details</h3>
              <hr />

              <form onSubmit={handleSubmit}>
                <div className="pt-3 flex">
                  <div className="mr-[8px]">
                    <CustomLabel htmlFor="firstName">First name*</CustomLabel>
                    <InputBox
                      id="firstName"
                      type="text"
                      name="firstName"
                      required={"true"}
                      value={formData?.firstName}
                      onChange={handleFormData} />
                  </div>
                  <div className="ml-[8px]">
                    <CustomLabel htmlFor="lastName">Last name*</CustomLabel>
                    <InputBox
                      id="lastName"
                      type="text"
                      name="lastName"
                      required={"true"}
                      value={formData?.lastName}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
                <div className="pt-3">
                  <CustomLabel htmlFor="emailID">Email*</CustomLabel>
                  <InputBox
                    id="emailID"
                    type="email"
                    name="email"
                    required={"true"}
                    value={formData?.email}
                    onChange={handleFormData}
                  />
                </div>
                <div className="pt-3">
                  <CustomLabel htmlFor="phone">Phone*</CustomLabel>
                  <InputBox
                    id="phone"
                    type="number"
                    name="phone"
                    required={"true"}
                    value={formData?.phone}
                    onChange={handleFormData}
                  />
                </div>
                <div className="pt-3">
                  <CustomLabel htmlFor="role">Role*</CustomLabel>
                  <select
                    id="role"
                    name="roleId"
                    className="w-full border font-normal text-sm border-gray-300  outline-none px-3 py-2 rounded-[4px]"
                    value={formData?.roleId}
                    onChange={handleFormData}
                  >
                    <option value={''}>Please select  </option>
                    {userRoles.map((item) => (
                      <option value={item._id}>{item.roleName}</option>
                    ))}
                  </select>
                </div>
                <div className="pt-3">
                  <CustomLabel>User picture*</CustomLabel>
                  <FileDragAndDrop onUpload={onUpload} receivedFile={receivedFile} />

                  {/* <div className="w-full h-28 border-2 border-dashed rounded-md text-center flex flex-col  items-center justify-center">
                  <label
                    htmlFor="choose_banner"

                    className="text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs"
                  >
                    Choose File
                    <input
                      id="choose_photor"
                      type="file"
                      className=" text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md"
                    />
                  </label>
                  <p className="text-sm pt-1 text-gray-400 ">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                  </p>
                </div> */}
                </div>
                <div className="pt-6 flex">
                  <CustomButton
                    type='button'
                    category={'small'}
                    className={'mr-[8px]'}
                    transparent={"true"}
                    onClick={handleNavigation}>
                    Back
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    disabled={!isFormValid()}
                    category={'small'}>
                    {params.id ? 'Update' : 'Save'}
                  </CustomButton>
                </div>
              </form>
            </div>
          </div>}
      </div>
    </>
  );
};

export default AddUser;

const InputBox = (props) => {
  const { id, type, value, name, required, ...attributes } = props;
  return (
    <input
      id={id}
      type={type}
      value={value}
      name={name}
      className="w-full border py-1 px-3 border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
      required={!!required}
      {...attributes}
    />
  )
}

const CustomLabel = (props) => {
  const { htmlFor, children } = props
  return (
    <label htmlFor={htmlFor} className="text-sm px-1 font-semibold pb-1">{children}</label>
  )
}