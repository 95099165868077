import React, { useContext, useEffect } from "react";

import {
  FaThLarge,
  FaRegBell,
  FaRegFileExcel,
  FaBriefcase,
  FaRegCalendarAlt,
  FaMailBulk,
  FaRegRegistered,
} from "react-icons/fa";
import { MdEventSeat } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Assets/event logo.png";
import { DataContext } from "../../context/DataState";
import LogoutModal from "../LogoutModal/LogoutModal";
import {
  FiLogOut,
  FiSliders,
  FiCalendar,
  FiUser,
  FiStar,
  FiUsers,
  FiHelpCircle,
  FiBarChart2,
  FiShare,
} from "react-icons/fi";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineRead, AiOutlineLink } from "react-icons/ai";
// import {IoShareSocialOutline} from 'react-icons/io'
import { MdLeaderboard } from "react-icons/md";

function Sidebar() {
  const {
    logout,
    setLogout,
    checkLogin,
    setCheckLogin,
    addPublishApi,
    allCount,
    disableSave,
  } = useContext(DataContext);
  const location = useLocation();
  useEffect(() => {}, [location]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
  const permission = userInfo?.permission;

  return (
    <>
      {logout ? <LogoutModal type={"logout"} /> : ""}
      {checkLogin ? (
        <div className="w-1/5 shrink-0 bg-white border-r-2 ">
          <div className="px-4">
            <img className="w-[180px] py-5 " src={Logo} alt="" />
          </div>
          {/* <div className="flex items-center justify-center  gap-4 pl- pr-5 pt-5 pb-6">
            <img className="w-[25px] " src={Sidebar1} alt="sidebar"></img>
            <h2 className="text-black font-bold font-poppins lg:text-lg 2xl:text-2xl text-xl ">Event Web</h2>
            <span ><FaArrowCircleLeft/></span>
          </div> */}
          <ul className=" flex flex-col gap-[4px] h-[85vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-white  px-4">
            <li
              className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl ${
                location.pathname === "/dashboard"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              } `}
            >
              <Link className="w-full  p-3" to="/dashboard">
                <div className="flex items-center text-sm">
                  <FaThLarge />
                  <h2 className=" text-md font-normal pl-6 ">Dashboard</h2>
                </div>
              </Link>
            </li>
            {userInfo?.role === "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/profile" ||
                  location.pathname ===
                    "/update-exhibitor/" + location.pathname.split("/").pop()
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/profile">
                  <div className="flex items-center  text-sm">
                    <AiOutlineRead
                      className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                        location.pathname === "/profile" ||
                        location.pathname ===
                          "/update-exhibitor/" +
                            location.pathname.split("/").pop()
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      }   `}
                    />
                    <h2 className=" text-md font-normal pl-6">My Profile</h2>
                  </div>
                </Link>
              </li>
            )}
            {(!permission?.speaker ||
              Object.values(permission?.speaker).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/speaker_list" ||
                    location.pathname ===
                      "/update_speaker/" + location.pathname.split("/").pop() ||
                    location.pathname === "/speaker_detail" ||
                    location.pathname === "/import_speak" ||
                    location.pathname === "/add_speaker" ||
                    location.pathname ===
                      "/speaker/" + location.pathname.split("/").pop()
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/speaker_list">
                    <div className="flex items-center  text-sm">
                      <FiUser
                        className={`text-xl transition-all rounded-xl group-hover:text-theme-color ${
                          location.pathname === "/speaker_list" ||
                          location.pathname ===
                            "/update_speaker/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/speaker_detail" ||
                          location.pathname === "/import_speak" ||
                          location.pathname === "/add_speaker" ||
                          location.pathname ===
                            "/speaker/" + location.pathname.split("/").pop()
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        } `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Speaker <span>({allCount?.countSpeakers})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}
            {(!permission?.sponsors ||
              !permission?.sponsorLevel ||
              Object.values(permission?.sponsors).some(
                (value) => value === true
              ) ||
              Object.values(permission?.sponsorLevel).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/sponsors" ||
                    location.pathname === "/sponsorlevel" ||
                    location.pathname ===
                      "/update-sponsor/" + location.pathname.split("/").pop() ||
                    location.pathname ==
                      "/update-sponsorlevel/" +
                        location.pathname.split("/").pop() ||
                    location.pathname === "/addsponsorlevel" ||
                    location.pathname === "/addsponsor" ||
                    location.pathname === "/importsponsor" ||
                    location.pathname ===
                      "/sponsor/" + location.pathname.split("/").pop()
                      ? "bg-theme-color text-white active group-hover:text-white"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/sponsorlevel">
                    <div className="flex items-center  text-sm">
                      <FiStar
                        className={`text-xl transition-all rounded-xl group-hover:text-theme-color  ${
                          location.pathname === "/sponsorlevel" ||
                          location.pathname ===
                            "/update-sponsor/" +
                              location.pathname.split("/").pop() ||
                          location.pathname ===
                            "/update-sponsorlevel/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/sponsors" ||
                          location.pathname === "/addsponsorlevel" ||
                          location.pathname === "/addsponsor" ||
                          location.pathname === "/importsponsor" ||
                          location.pathname === "/sponsors" ||
                          location.pathname ===
                            "/sponsor/" + location.pathname.split("/").pop()
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        } `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Sponsors <span>({allCount?.countSponsors})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}
            {(!permission?.exhibitor ||
              Object.values(permission?.exhibitor).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/exhibitors" ||
                    location.pathname === "/add-exhibitor" ||
                    location.pathname ===
                      "/update-exhibitor/" +
                        location.pathname.split("/").pop() ||
                    location.pathname ===
                      "/exhibitor/" + location.pathname.split("/").pop()
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/exhibitors">
                    <div className="flex items-center  text-sm">
                      <MdEventSeat
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                          location.pathname === "/exhibitors" ||
                          location.pathname === "/add-exhibitor" ||
                          location.pathname ===
                            "/update-exhibitor/" +
                              location.pathname.split("/").pop() ||
                          location.pathname ===
                            "/exhibitor/" + location.pathname.split("/").pop()
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Exhibitor <span>({allCount?.countExhibitors})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}
            {(!permission?.attendee ||
              Object.values(permission?.attendee).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/attendees" ||
                    location.pathname ===
                      "/update_user/" + location.pathname.split("/").pop() ||
                    location.pathname === "/add-attendees" ||
                    location.pathname === "/import-attendees" ||
                    location.pathname ===
                      "/user/" + location.pathname.split("/").pop()
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/attendees">
                    <div className="flex items-center  text-sm">
                      <FiUsers
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                          location.pathname === "/attendees" ||
                          location.pathname ===
                            "/update_user/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/add-attendees" ||
                          location.pathname === "/import-attendees" ||
                          location.pathname ===
                            "/user/" + location.pathname.split("/").pop()
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Attendees <span>({allCount?.countAudiences})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}
            {(!permission?.agenda ||
              Object.values(permission?.agenda).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/agenda-list" ||
                    location.pathname ===
                      "/update-agenda/" + location.pathname.split("/").pop() ||
                    location.pathname === "/new_session" ||
                    location.pathname === "/import_agenda" ||
                    location.pathname ===
                      "/agenda/" + location.pathname.split("/").pop() ||
                    location.pathname === "/checkin"
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/agenda-list">
                    <div className="flex items-center  text-sm">
                      <FiCalendar
                        className={`text-xl transition-all rounded-md group-hover:text-theme-color ${
                          location.pathname === "/agenda-list" ||
                          location.pathname ===
                            "/update-agenda/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/new_session" ||
                          location.pathname === "/import_agenda" ||
                          location.pathname ===
                            "/agenda/" + location.pathname.split("/").pop() ||
                          location.pathname === "/checkin"
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        } `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Agenda <span>({allCount?.countAgendas})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}

            <li
              className={`flex items-center hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl ${
                location.pathname === "/reports" ||
                location.pathname === "/sponsors-view" ||
                location.pathname === "/exhibitorsoverview-view" ||
                location.pathname === "/agendaoverview-view" ||
                location.pathname === "/speakeroverview-view" ||
                location.pathname === "/date-time" ||
                location.pathname === "/attendeeoverview-view"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }`}
            >
              <Link
                className="w-full p-3"
                to={
                  userInfo.role === "Exhibitors"
                    ? "/exhibitorsoverview-view"
                    : "/reports"
                }
              >
                <div className="flex items-center text-sm">
                  <VscGraph
                    className={`flex items-center text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md ${
                      location.pathname === "/reports" ||
                      location.pathname === "/sponsors-view" ||
                      location.pathname === "/exhibitorsoverview-view" ||
                      location.pathname === "/agendaoverview-view" ||
                      location.pathname === "/speakeroverview-view" ||
                      location.pathname === "/attendeeoverview-view" ||
                      location.pathname === "/date-time"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    }`}
                  />
                  <h2 className="text-md font-normal pl-6">Reports</h2>
                </div>
              </Link>
            </li>

            {(!permission?.announcement ||
              Object.values(permission?.announcement).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/announcement" ||
                    location.pathname === "/add-announcement" ||
                    location.pathname ===
                      "/update-announcement/" +
                        location.pathname.split("/").pop()
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/announcement">
                    <div className="flex items-center  text-sm">
                      <FaRegBell
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                          location.pathname === "/announcement" ||
                          location.pathname === "/add-announcement" ||
                          location.pathname ===
                            "/update-announcement/" +
                              location.pathname.split("/").pop()
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Announcement
                      </h2>
                    </div>
                  </Link>
                </li>
              )}
            {userInfo?.role !== "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/leaderboard" ||
                  location.pathname === "/points-info"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/leaderboard">
                  <div className="flex items-center  text-sm">
                    <MdLeaderboard
                      className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                        location.pathname === "/leaderboard" ||
                        location.pathname === "/points-info"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      }   `}
                    />
                    <h2 className=" text-md font-normal pl-6">LeaderBoard</h2>
                  </div>
                </Link>
              </li>
            )}
            {(!permission?.socialwall ||
              Object.values(permission.socialwall).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/socialwall" ||
                    location.pathname === "/time-line" ||
                    location.pathname === "/social-wall-activity"
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to="/socialwall">
                    <div className="flex items-center  text-sm">
                      <AiOutlineLink
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                          location.pathname === "/socialwall" ||
                          location.pathname === "/time-line" ||
                          location.pathname === "/social-wall-activity"
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        SocialWall <span>({allCount?.countPost?.length})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}

            {(!permission?.contacts ||
              Object.values(permission.contacts).some(
                (value) => value === true
              )) && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/contactlist" ||
                  location.pathname === "/import-contacts" ||
                  location.pathname === "/addcontactlist" ||
                  location.pathname === "/createcontact" ||
                  location.pathname === "/view-contact" ||
                  location.pathname === "/create-template" ||
                  location.pathname ===
                    "/update-contact/" + location.pathname.split("/").pop()
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/contactlist">
                  <div className="flex items-center  text-sm">
                    <FiUsers
                      className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                        location.pathname === "/contactlist" ||
                        location.pathname === "/import-contacts" ||
                        location.pathname === "/addcontactlist" ||
                        location.pathname === "/createcontact" ||
                        location.pathname === "/view-contact" ||
                        location.pathname === "/create-template" ||
                        location.pathname ===
                          "/update-contact/" +
                            location.pathname.split("/").pop()
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      }   `}
                    />
                    <h2 className=" text-md font-normal pl-6">Contacts</h2>
                  </div>
                </Link>
              </li>
            )}

            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/meetings"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/meetings">
                <div className="flex items-center  text-sm">
                  <FaBriefcase
                    className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                      location.pathname === "/meetings"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    }   `}
                  />
                  <h2 className=" text-md font-normal pl-6">Meetings</h2>
                </div>
              </Link>
            </li> */}

            {(!permission?.polls ||
              Object.values(permission?.polls).some(
                (value) => value === true
              )) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/polls" ||
                    location.pathname ===
                      "/update-polls/" + location.pathname.split("/").pop() ||
                    location.pathname === "/add-polls" ||
                    location.pathname === "/polls-response"
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to={"/polls"}>
                    <div className="flex items-center  text-sm">
                      <FiBarChart2
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                          location.pathname === "/polls" ||
                          location.pathname ===
                            "/update-polls/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/add-polls" ||
                          location.pathname === "/polls-response"
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        Polls <span>({allCount?.countPolls})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}

            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/reports"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to="/reports">
                <div className="flex items-center  text-sm">
                  <FaRegFileExcel
                    className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                      location.pathname === "/reports"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    }   `}
                  />
                  <h2 className=" text-md font-normal pl-6">Reports</h2>
                </div>
              </Link>
            </li> */}
            {userInfo?.role !== "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/tutorial"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/tutorial">
                  <div className="flex items-center  text-sm">
                    <AiOutlineRead
                      className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                        location.pathname === "/tutorial"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      }   `}
                    />
                    <h2 className=" text-md font-normal pl-6">Tutorial</h2>
                  </div>
                </Link>
              </li>
            )}

            {(!permission?.faq ||
              Object.values(permission?.faq).some((value) => value === true)) &&
              userInfo?.role !== "Exhibitors" && (
                <li
                  className={`flex items-center  hover:text-theme-color group  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                    location.pathname === "/faq" ||
                    location.pathname ===
                      "/update-faq/" + location.pathname.split("/").pop() ||
                    location.pathname === "/add-faq"
                      ? "bg-theme-color text-white active"
                      : "text-black"
                  }   `}
                >
                  <Link className="w-full p-3" to={"/faq"}>
                    <div className="flex items-center  text-sm">
                      <FiHelpCircle
                        className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                          location.pathname === "/faq" ||
                          location.pathname ===
                            "/update-faq/" +
                              location.pathname.split("/").pop() ||
                          location.pathname === "/add-faq"
                            ? "bg-theme-color text-white active group-hover:text-white"
                            : "text-black"
                        }   `}
                      />
                      <h2 className=" text-md font-normal pl-6">
                        FAQ <span>({allCount?.countFaqs})</span>
                      </h2>
                    </div>
                  </Link>
                </li>
              )}

            {/* <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/notification" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link className="w-full p-3" to="/notification">
              <div className="flex items-center  text-sm">
                <FaRegCalendarAlt />
                <h2 className=" text-md font-normal pl-6">Notification</h2>
              </div>
            </Link>
          </li>
          <li className={`flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${location.pathname === "/chat" ? "bg-theme-color text-white active" : "text-black"}   `}>
            <Link className="w-full p-3" to="/chat">
              <div className="flex items-center  text-sm">
                <FaSkype />
                <h2 className=" text-md font-normal pl-6">Chat</h2>
              </div>
            </Link>
          </li> */}
            {userInfo?.role !== "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  
              ${
                location.pathname === "/event_information"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }`}
              >
                <Link className="w-full p-3" to={"/event_information"}>
                  <div className="flex items-center  text-sm">
                    {/* <FaRegCalendarAlt /> */}
                    <FiSliders
                      className={`text-xl  group-hover:text-theme-color  transition-all rounded-xl  ${
                        location.pathname === "/event_information"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      } `}
                    />
                    <h2 className=" text-md font-normal pl-6">
                      Event Information
                    </h2>
                  </div>
                </Link>
              </li>
            )}
            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/registration"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }`}
            >
              <Link className="w-full p-3" to={"/registration"}>
                <div className="flex items-center  text-sm">
                  {/* <FaRegCalendarAlt /> */}
            {/* <FaRegRegistered
                    className={`text-xl  group-hover:text-theme-color  transition-all rounded-xl  ${
                      location.pathname === "/registration"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md font-normal pl-6">Registration</h2>
                </div>
              </Link>
            </li>  */}
            {userInfo?.role !== "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/users" ||
                  location.pathname == "/user-roles" ||
                  location.pathname === "/new-role" ||
                  location.pathname === "/add_user"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to={"/user-roles"}>
                  <div className="flex items-center  text-sm">
                    <FaRegCalendarAlt />
                    <FiUser
                      className={`text-xl  group-hover:text-theme-color  transition-all rounded-xl  ${
                        location.pathname === "/users" ||
                        location.pathname === "/user-roles" ||
                        location.pathname === "/new-role" ||
                        location.pathname === "/add_user"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      } `}
                    />
                    <h2 className=" text-md font-normal pl-6">User Roles</h2>
                  </div>
                </Link>
              </li>
            )}
            {/* <li
              className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/email-template"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to={"/email-template"}>
                <div className="flex items-center  text-sm">
                  {/* <FaRegCalendarAlt />  */}
            {/* <FaMailBulk
                    className={`text-xl  group-hover:text-theme-color  transition-all rounded-xl  ${
                      location.pathname === "/email-template"
                        ? "bg-theme-color text-white active group-hover:text-white"
                        : "text-black"
                    } `}
                  />
                  <h2 className=" text-md font-normal pl-6">Email Template</h2>
                </div>
              </Link>
            </li>  */}
            {userInfo?.role !== "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/published-log"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to={"/published-log"}>
                  <div className="flex items-center  text-sm">
                    {/* <FaRegCalendarAlt /> */}
                    <FiShare
                      className={`text-xl  group-hover:text-theme-color  transition-all rounded-md  ${
                        location.pathname === "/published-log"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      } `}
                    />
                    <h2 className=" text-md font-normal pl-6">Published Log</h2>
                  </div>
                </Link>
              </li>
            )}
            {/* <li
              className={`flex items-center hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-3xl  ${
                location.pathname === "/event_detail"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link className="w-full p-3" to={"/event_detail"}>
                <div className=" text-center  text-sm">
                  <FaRegCalendarAlt />
                 <h2 className=" text-md font-normal">Publish</h2>
                </div>
              </Link>
            </li> */}
            {userInfo?.role === "Exhibitors" && (
              <li
                className={`flex items-center  hover:text-theme-color group hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                  location.pathname === "/enquiries" ||
                  location?.pathname === "/enquiry/view"
                    ? "bg-theme-color text-white active"
                    : "text-black"
                }   `}
              >
                <Link className="w-full p-3" to="/enquiries">
                  <div className="flex items-center  text-sm">
                    <AiOutlineRead
                      className={`flex items-center  text-xl hover:text-theme-color group-hover:text-theme-color hover:bg-theme-light-color justify-between transition-all rounded-md  ${
                        location.pathname === "/enquiries" ||
                        location?.pathname === "/enquiry/view"
                          ? "bg-theme-color text-white active group-hover:text-white"
                          : "text-black"
                      }   `}
                    />
                    <h2 className=" text-md font-normal pl-6">Enquiries</h2>
                  </div>
                </Link>
              </li>
            )}

            <li
              className={` mt-auto flex items-center  hover:text-theme-color  hover:bg-theme-light-color justify-between transition-all rounded-xl  ${
                location.pathname === "/logout"
                  ? "bg-theme-color text-white active"
                  : "text-black"
              }   `}
            >
              <Link
                className="w-full p-3"
                onClick={() => {
                  setLogout(true);
                }}
              >
                <div className="flex items-center w-full  text-sm">
                  <FiLogOut className="text-xl" />
                  <h2 className=" text-md text- px-5">Logout</h2>
                </div>
              </Link>
            </li>
            <li className="text-center py-2">
              <button
                className="text-sm py-3 text-white px-14 rounded-3xl bg-theme-color font-normal font-theme-font"
                onClick={() => {
                  if (!disableSave) addPublishApi(true);
                }}
              >
                Publish
              </button>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Sidebar;
