import React, { useContext, useEffect ,useState} from "react"
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

import { FaCrown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import dummyImage from "../Assets/dummyimage.jpg"
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const AgendaOverviewViewPage=()=>{
  const {getSingleAgendaReportApi,
    singleAgendaReport,totalCount,PageCount,checkResponse,globalLoader}=useContext(DataContext);
    const location=useLocation()
const agenda=location.state
let id=null
if(agenda?.item?._id)
{
   id=agenda?.item?._id
}
else{
  id=agenda?._id
}
const limit = 10;
console.log(agenda,"id")
const dateConversion = (date) => {
  const nDate = new Date(date).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });

  return nDate.split(",");
};
console.log(agenda,"id")
const timeconversion = (date) => {
  let nDate = String(date);

  return nDate.slice(11, 16);
};
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
    useEffect(()=>{
getSingleAgendaReportApi(id,1,limit)
    },[])
    console.log(singleAgendaReport,"singleagendaReport")
  return (
    <>
    <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={`${!agenda?.item?._id ? "/agenda-list":"/reports"}`}>
            <div className="w-8 pt-1">
             
              <button className="border p-2">
                <FaArrowLeft />
              </button>
             
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Agenda Information </h1>
          </div>
        </div>
        {globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
        <div className="flex gap-5 bg-white ">
        <div className=" w-1/2  m-0  p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Agenda Details</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] w-[30%] ">
          <p className="text-sm font-medium">Title </p>
          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{singleAgendaReport?.agendaInfo?.agenda_name}</p>
        </div>
        <div className="text-[#202223] w-[25%]">
          <p className="text-sm font-medium">Date  </p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full"> {dateConversion(singleAgendaReport?.agendaInfo?.startDate)[0]}</p>
        </div>
        <div className="text-[#202223] w-[15%]">
          <p className="text-sm font-medium">Start at </p>
          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{timeconversion(singleAgendaReport?.agendaInfo?.startTime)}</p>
        </div>
        <div className="text-[#202223] w-[15%] ">
          <p className="text-sm font-medium">End at  </p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{timeconversion(singleAgendaReport?.agendaInfo?.endTime)}</p>
        </div>
        
       </div>

       
       <div className="text-[#202223]  w-full mt-5 ">
            <label
              className="block relative mb-2 text-sm font-medium"
              typeof="description"
              htmlFor="description"
            >
              Description *
            </label>
            <textarea
             
              rows="8"

              className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-[#AEB4B9] "
              
             value={singleAgendaReport?.agendaInfo?.description}
             
            />
            
          </div>
       
      
              
              
        
        </div>

        <div className=" w-1/2  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Others</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>
          <div className="w-full flex gap-6 mt-5">
          <div className="text-[#202223] flex-1">
          <p className="text-sm font-medium">Location  </p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{singleAgendaReport?.agendaInfo?.Location}</p>
        </div>
        <div className="text-[#202223] flex-1">
          <p className="text-sm font-medium">Interests Tag</p>

          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
            {
              singleAgendaReport?.agendaInfo?.interest.length==0 ? <span className="text-sm font-normal">No Data</span>:
              <div className="flex gap-2">
              {
                singleAgendaReport?.agendaInfo?.interest.map((item)=>{
                  return (
                    <>
                      <span className="text-xs font-normal bg-[#4285F433] px-2 py-1 rounded">{item?.value}</span>
                    </>
                  )
                })
              }
            
             
            </div>
            }
            
           </p>
        </div>
          </div>
          
          
        
         
        
      <div className="flex">
      <div className=" w-full mt-5  gap-12">
        <div className="text-[#202223] flex-1 w-full  ">
          <p className="text-sm font-medium">Banner image</p>
          {
            singleAgendaReport?.agendaInfo?.image==""? <span className="text-sm">No Data</span>:
            <img src={singleAgendaReport?.agendaInfo?.image} className="w-60 h-32 mt-1" alt="banner-picture" />
          }
         
        </div>
        
        
       </div>
       
      </div>

       
       
       
      
              
              
        
        </div>


        </div>
        </>
        )}
        
        <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base p-3">Speaker</h1>
                      <table className=" w-full bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[10%] px-4 py-2 rounded relative">
                            Photo
                            </th>

                            <th className="w-[10%] px-4 py-2 ">
                           Title
                            </th>
                            <th className="w-[13%] px-4 py-2 ">
                           First name
                            </th>
                            <th className="w-[13%] px-4 py-2">Last name</th>
                            
                            <th className="w-[20%] px-4 py-2">Position</th>
                            <th className="w-[20%] px-4 py-2">Organization</th>
                           
                            {/* <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          {
                             singleAgendaReport?.agendaInfo?.speaker && singleAgendaReport?.agendaInfo?.speaker.map((item)=>{
                              return (
                                <>
                                <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="p-2">
                               <img src={(item?.image==="" ||item?.image===undefined) ? dummyImage :item?.image} className="w-12 h-12 rounded" alt="speaker-photo" />
                                </td>

                                <td className=" py-2 px-4">{item?.title}</td>

                                <td className=" py-2 px-4">
                               {item?.speaker_firstname}
                                </td>
                                
                                <td className=" py-2 px-4"> {item?.speaker_lastname}</td>

                                <td className=" py-2 px-4">
                                {item?.position}
                                </td>
                                <td className=" py-2 px-4">
                                {item?.organization}
                                </td>

                                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/sponsors-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}
                              </tr>
                                </>
                              )
                            })
                          }
                          
                            
                              
                            
                        
                        </tbody>
                      </table>
                    </div>
                    <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base p-3">Check In Attendees</h1>
                      <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[15%] px-4 py-2 rounded relative">
                           Name
                            </th>

                           
                            <th className="w-[20%] px-4 py-2 ">
                          Email
                            </th>
                            <th className="w-[20%] px-4 py-2">Organization</th>
                            
                            <th className="w-[30%] px-4 py-2">Position</th>
                            <th className="w-[20%] px-4 py-2">Check In</th>
                           
                            {/* <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          {
                             singleAgendaReport?.agendaInfo?.attendee_id  && singleAgendaReport?.agendaInfo?.attendee_id?.
                             map((item)=>{
                              return (
                                <>
                                <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="py-2 px-4">
                                 {item?.userId?.Name}
                                </td>

                                

                                <td className=" py-2 px-4">
                               {item?.userId?.email}
                                </td>
                                
                                <td className=" py-2 px-4">{item?.userId?.organization}</td>

                                <td className=" py-2 px-4">
                               {item?.userId?.designation}
                                </td>
                                <td className=" py-2 px-4">
                                {(item?.checkinTime)=== undefined ? " ": `${dateConversion(item?.checkinTime)[0]} | ${timeconversion(item?.checkinTime)}`  }
                                </td>

                                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/sponsors-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}
                              </tr>
                                </>
                              )
                             })                
                          }
                            
                              
                            
                        
                        </tbody>
                      </table>
                    </div>
                    <div className="bg-white rounded-t mt-3 px-3">
          <h1 className="text-[#141824] font-semibold text-base p-3">
          Comments
          </h1>
          <table className=" table-auto w-full bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[20%]rounded-tl px-4 py-2 rounded relative">
                Full Name
                </th>
                <th className="w-[65%%] px-4 py-2 ">Comment</th>
                <th className="w-[15%] px-4 py-2 ">Time</th>
              
                
                

              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
            {
                  singleAgendaReport?.agendaInfo?.attendeeComment.map((item)=>{
                    return (
                      <>
                        <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                
                <td className="py-2 px-4">{item?.attendee_id?.Name}</td>
               

               

               
                <td className=" py-2 px-4">{item?.Comment}</td>
               
                <td className=" py-2 px-4">
                {(item?.date)=== undefined ? " ": `${dateConversion(item?.date)[0]} | ${timeconversion(item?.date)}`  }
                  </td>
                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/sponsors-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}


               
                

                
              </tr>
                      </>
                    )
                  })
                }
            
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              id={id}
              api={getSingleAgendaReportApi}
             
            />
          </div> */}
      
      </div>
    </>
  )
}
export default AgendaOverviewViewPage;