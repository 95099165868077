import React from "react";

const History=()=>{
  return (
    <>
     <table className="w-full table-auto bg-white shadow-md rounded-md mt-4">
              <thead className="cursor-pointer">
                <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                  
                  <th className=" w-[25%] p-2 whitespace-nowrap">
                    Contacts
                  </th>
                  <th className="w-[25%] p-2 ">Title</th>
                  <th className="w-[20%] p-2 ">Description</th>
                  <th className="w-[20%] p-2 ">Date</th>
                 
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
               
                    <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow text-[#202223] text-sm font-normal">
                      <td className="px-2 py-3  flex gap-2 items-center ">
                        
                      HDFC Bank Employee
                        
                      </td>
                     
                      <td className="p-2 ">
                      Welcome Email 
                      </td>
                      <td className="p-2">
                      We would love to hear ... 
                      </td>
                      <td className="p-2">2022-07-22 14:24:34</td>
                     
                    </tr>
                
              </tbody>
            </table>
    </>
  )
}
export default History;