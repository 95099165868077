import React from 'react'
import { useState, useEffect ,useRef} from 'react';
import { FaArrowLeft, FaArrowCircleUp } from "react-icons/fa";
import { Link } from 'react-router-dom';

import { useContext } from 'react';
import { DataContext } from '../../context/DataState';
import { ToastContainer } from 'react-toastify';
import { MdClose } from "react-icons/md";

const AddSponsor = () => {
  const { addSponsorApi, getSponsorLevelApi, sponsorsLevel, disableSave,isSpinner } = useContext(DataContext);

  useEffect(() => {
    getSponsorLevelApi()
  }, [])
  const [state, setState] = useState({
    sponsor_name: "",
    description: "",
    url: "",
    image: "",
    email: "",
    phone: "",
    bannerimage: "",
    level: {},
  });
  

  // const [sponsorList, setSponsorList] = useState([]);
  const [count,setCount]=useState(0);
  const [bannerimage,setbannerimage]=useState(null)
  const [imageError,setImageError]=useState(false)
  const [emailError, setEmailError] = useState(false);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [image,setimage] = useState(null);
  const [urlError,setUrlError] =useState(false);
  const [imageErrors,setImageErrors]= useState(false);

  // const validUrl =new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

  const validUrl =new RegExp(/^(?:(?:https?|http):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/); 
  const validateUrl = () =>{
    if(!validUrl.test(state.url) && state.url !=="") {
      setUrlError(true);
    } else {
      setUrlError(false);
    }
  };

  const bannerImageInput=useRef();
  const logoImageInput=useRef();

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false)
    }
    else if (phoneData === '') {
      return setPhoneFormatTest(false)
    }
    else {
      return setPhoneFormatTest(true)

    }
  }

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email) && state.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  //---------------------------bannerimage validation-------------------
  const onImageChange=(event)=>{
    if (event.target.files && event.target.files[0]) {
      setbannerimage(URL.createObjectURL(event.target.files[0]));
     

    }
    if ( event.target.files[0].name &&  !event.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)) {
      setImageError('Please select valid image format.');
      return false;
    }
    
    if(( event.target.files[0].size) && (event.target.files[0].size)/1024 > 1024)
    {
     setImageError("Please select image less than 1 mb")
    }
    else{
    setImageError(false)
    }
  } 
//-------------------------image validation---------------
  const onImageFormat=(event)=>{
    if (event.target.files && event.target.files[0]) {
      setimage(URL.createObjectURL(event.target.files[0]));
    
    }
    if ( event.target.files[0].name &&  !event.target.files[0].name.match(/\.(png)$/)) {
      setImageErrors('Please select valid image format.');
      return false;
    }
    
    if(( event.target.files[0].size) && (event.target.files[0].size)/1024 > 1024)
    {
     setImageErrors("Please select image less than 1 mb")
    }
    else{
    setImageErrors(false)
    }
  } 

  const handleChange=(e)=>{
    setState({ ...state, description: e.target.value })
    const textLength = e.target.value.split("").length;
   setCount(textLength);
  }

  const handleSubmit = () => {
    if(!isSpinner)
    addSponsorApi(state);
  }

  const handleBannerDelete = ()=>{
    setState({...state, bannerimage: ""});
    setbannerimage(null)
    bannerImageInput.current.value = "";
    
  }
  const handleLogoDelete = ()=>{
    setState({...state, image: ""});
    setimage(null)
    logoImageInput.current.value = "";
    
  }
  const buttonDisabled = (state.sponsor_name !== "" && state.description !== "" && state.email !== "" && state.phone !== "" && state.bannerimage !== "" && Object.keys(state.level).length !== 0 && state.image !== "" && state.url!=="" &&urlError!=true&&emailError!=true&&phoneFormatTest!=true && !imageError && !imageErrors)
console.log(state,"state")
  
  return (
    
    <>
     
      <div className='flex w-full '>

        <div className='w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4'>
          <div className='flex items-center'>
            <Link to="/sponsors">
              <div className='shadow-xs border-2 p-2  '>
                < FaArrowLeft className='text-[20px] text-[#5C5F62]' />
              </div>
            </Link>
            <div>
              <h1 className='text-xl pl-3'>Sponsor information </h1>
            </div>
          </div>
         
<div className='flex flex-col p-4 rounded-lg border-[#D1D9E7] bg-white'>
<div>
            <h2 className='font-semibold tetx-[18px] '>Base Data</h2>
            <hr className='w-[48%]' />
          </div>
<div className='flex space-x-10 mt-2'>
            <div className='w-1/2 space-y-3 pt-2'>
              <div className=''>
                <label htmlFor="sponsor_name" className='px-1 font-semibold text-sm '>Sponsor Name *</label>
                  <input type='text' id="sponsor_name" name="sponsor_name" placeholder="Enter sponsor name" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                    value={state.sponsor_name}
                    onChange={(e) => { setState({ ...state, sponsor_name: e.target.value }) }} />
              </div>
              
              <div className=''>
                <label htmlFor="url" className=' text-sm px-1 font-semibold'>Website Url *</label>
                <input type='text' name="url" placeholder="Enter website url" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                  value={state.url}
                  onChange={(e) => { setState({ ...state, url:e.target.value}) }}
                  onKeyUp={() => validateUrl()}
                />
                {urlError && (
                  <span className="text-left text-red-600 text-sm">Please enter a valid url</span>
                )}
              </div>
              <div className=''>
                <label htmlFor="email" className=' text-sm px-1 font-semibold pb-1'>Email *</label>
                <input type='text' name="email" placeholder="Enter email " className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                  value={state.email}
                  onChange={(e) => { setState({ ...state, email: e.target.value }) }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                    <span className="text-left text-red-600 text-sm">
                      Please enter a valid Email
                    </span>
                  )}
              </div>
              <div className=''>
                <label htmlFor="phone" className=' text-sm px-1 font-semibold pb-1'>Contact Number *</label>
                <input type='text' maxlength={10} pattern="[0-9]{10}" id="phone" name="phone" placeholder='Enter contact number' className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                  value={state.phone}
                  onChange={(e) => { setState({ ...state, phone: e.target.value }) }}
                  onKeyUp={() => validatePhoneno(state.phone)} 
                   />
                  {phoneFormatTest ? <span className="text-red-600 text-sm">Please enter a valid 10 digit Number.</span> : <></>}
              </div>
              <div className=''>
                <label htmlFor="description" className=' text-sm px-1 font-semibold pb-1 '>Description *</label>
                <textarea rows="4" name="description" placeholder="Provide some details about the Sponsor." className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm'
                  value={state.description}
                  onChange={(e) => { handleChange(e) }} maxLength={2000} />
                  <div className='flex justify-between item-center'>
                   <span className='text-xs text-gray-500 '>Provide some details about the Sponsor.</span>
                   <span className="bottom-2 text-xs ">{count}/2000</span>
                  </div>
                
              </div>
            </div>
            <div className='w-1/2'>
              {/* <div className=''>
                <label htmlFor="session" className='  text-sm px-1 font-semibold pb-1'>Session</label>
                <select className='w-full session_sel py-1 px-3 border border-gray-300 rounded-md focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm  focus-visible:shadow-focusshadow focus-visible:border-1'>
                  <option value="" selected disabled hidden>Choose Sessions</option>
                  {
                    agendaList.map((item) => {
                      return (
                        <option className='rounded-md hover:bg-theme-color' value="session1">{item?.agenda_name}</option>
                      )
                    })
                  }

                </select>
              </div> */}
              <div className='pt-3'>
                <h1 className='text-sm  font-semibold pb-1'>Banner image *</h1>
                <div className=' h-30 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color'>
                  <label htmlFor="choose_banner" 
                  // accept="image/png, image/gif, image/jpeg, image/svg" 
                  className='text-theme-color cursor-pointer  text-sm '><span className=' bg-theme-light-color px-2 py-1'> {(state.bannerimage==="" || imageError) ? "Choose banner image" : state.bannerimage?.name}</span>
                    <p className='text-sm pt-1 text-gray-400 leading-4'>Accepts jpeg, jpg, png and webp</p>
                    <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                    </p>
                    <input id="choose_banner" type='file' 
                    // accept='image/jpeg, image/png' 
                    ref={bannerImageInput}
                    className='text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md '
                      onChange={(e) => {
                        setState({ ...state, bannerimage: e.target.files[0]});
                        onImageChange(e);
                      }} />
                      </label>
                  
                </div>
              </div>
              <span className="text-xs text-red-600 font-normal">{imageError ? imageError : ""}</span>
              <div className={(bannerimage===null || imageError ) ?  "hidden"  : "relative w-28 h-28 mt-4"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleBannerDelete()}/>
                <img src={bannerimage} alt="banner" className="absolute w-full h-full object-cover"/>
              </div>

              <div className='pt-3'>
                <h1 className='text-sm  font-semibold pb-1'>Logo*</h1>
                <div className=' h-30 border-2 border-dashed rounded-md text-center pt-5  hover:border-theme-color'>
                  <label htmlFor="choose_image" 
                  // accept="image/png, image/gif, image/jpeg, image/svg" 
                  className='text-theme-color cursor-pointer  text-sm '>
                    
                  <div className="flex justify-center">
                    <span className='flex items-center gap-2 bg-theme-light-color px-2 py-1'> {(state.image==="" || imageErrors) ? "Choose logo image": state.image?.name}
                    <FaArrowCircleUp className="text-theme-color" /> </span>
                  
                  </div>
                    <p className='text-sm pt-1 text-gray-400 leading-4'>Accepts only png</p>
                    <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                    </p>
                    
                    <input id="choose_image" type='file'
                    //  accept='image/jpeg, image/png'
                    ref={logoImageInput}
                      className='text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md '
                      onChange={(e) => {
                        setState({ ...state,image: e.target.files[0]});
                        onImageFormat(e);
                      }} />
                  </label> 
                </div>
              </div>
              <span className="text-xs text-red-600 font-normal">{imageErrors ? imageErrors : ""}</span>
              <div className={(image===null || imageErrors ) ?  "hidden"  : "relative w-28 h-28 mt-4"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleLogoDelete()}/>
                <img src={image} alt="banner" className="absolute w-full h-full object-cover"/>
              </div>
              {/* <div className='pt-3'> 
                <label htmlFor="">Logo</label>
                
                <div className="w-9 px-2 py-2 border-2 rounded-lg border-dashed border-gray-400 hover:border-theme-color">
                  <FaArrowCircleUp className="text-theme-color" 
                  onChange={(e)=> {
                    setState({...state,image:e.target.files[0]});
                    onImageFormat(e);
                  }}/>

                </div>
              </div> */}
              <div className='pt-3'>
                <label htmlFor="sponsor-level" className='text-sm px-1 font-semibold pb-1'>Level *</label>
                <select className='w-full  py-1 px-3 border border-gray-300 rounded-md focus-visible:border-[#fb923c] focus-visible:outline-none shadow-sm  focus-visible:shadow-focusshadow 
                focus-visible:border-1 placholder:text-slate-400 text-sm h-9'
                  onChange={(e) => {
                    console.log((e.target.value),"sponsorLevel")
                    setState({ ...state, level: JSON.parse(e.target.value) });  }}>
                  <option disabled selected="Choose Sponsor Level">Choose Sponsor Level</option>
                  {
                    sponsorsLevel.map((item,index) => {
                      return (
                        <option className='rounded-md hover:bg-theme-color' key={index}
                          value={JSON.stringify(item)}> {item?.level_name}</option>
                      )
                    })
                  }
                </select>
              </div>
              
            </div>
          </div>
          <div className='w-1/2'>
            <div className='flex pt-3 gap-3' >
              <Link to={"/sponsors"}>
                <button className='border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded'>Back</button>
              </Link>
              <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
            </div>
          </div>

</div>
         

        </div>
      </div>

    </>
  )
}

export default AddSponsor

