import React, { useContext, useEffect, useState } from "react";
import dummyImage from "../Assets/dummyimage.jpg";
import { DataContext } from "../../context/DataState";

const PollModal = ({pollId,optionId}) => {
  const {setPollModalBtn,pollList}=useContext(DataContext)
  const [optionarray,setOptionarray]=useState([])

  useEffect(()=>{
   
    const newArray=pollList.filter((item)=>{
     
     return item._id===pollId
    })
    const newArray2=newArray[0].options.filter((element)=>{
      return element._id===optionId
    })
   setOptionarray(newArray2)
  },[pollId,optionId,pollList])
  

  return (
    <>
      <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[1]">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white p-4 w-[100vh] max-h-[80vh]  rounded z-10">
           
            <div className="pt-3 ">
              
              <h1 className="text-xl text-[#202223] font-semibold">{optionarray[0]?.option}</h1>
            </div>
           <div className="mb-1 mt-3">
            <hr className="bg-[#0000001A] h-[2px]"/>
           
           </div>
           {
            optionarray[0]?.user_id?.length===0 ? <div className="flex justify-center items-center"><span className="text-sm font-normal py-3">No one voted</span></div> 
            :  <table className="w-full table-auto   bg-white text-start shadow-md border-separate rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-white capitalize text-left bg-[#FF8531] text-xs font-normal ">
                <td className="w-[15%] px-4 py-2 rounded-tl-lg ">Photo</td>
                <td className="w-[25%] px-4 py-2">Full name</td>
                <td className="w-[45%] px-4 py-2 rounded-tr-lg">Email</td>
              </tr>
            </thead>
            <tbody className=" " >
              {
                optionarray[0]?.user_id?.map((item,index)=>{
                  return (
                    <>
                    <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer" key={index}>
                <td className=" py-2 px-4 font-semibold text-base ">
                  <img className="h-8 w-8 rounded-full" alt="" src={`${item?.image===undefined || item?.image==="" ? dummyImage :item?.image}`} />
                </td>

                <td className="py-2 px-4">{item?.Name}</td>
                <td className=" py-2 px-4">{item?.email}</td>
              </tr>
                    </>
                  )
                })
              }
              

             
              
            </tbody>
          </table>
           }
           
            <div className="flex items-center justify-center mt-5 ">
              <button type="button" className="bg-theme-color text-white px-4 py-2 text-sm font-medium w-[200px] rounded" onClick={()=>{setPollModalBtn(false)}}>ok</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PollModal;
