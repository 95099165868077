import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeft, FaCross } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from 'react-toastify';
import { WithContext as ReactTags } from 'react-tag-input';
import { MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom";


const UpdateAgenda = () => {
  const { agendaId } = useParams();
  const { getSpeakersApi, speakers, agendaList, updateAgendaApi, specificEvent, sponsorsList, getEventByIdApi,disableSave,isSpinner } =
    useContext(DataContext);
    const location = useLocation()
    const agendaEdit = location.state;

  const [editAgenda, setEditAgenda] = useState(agendaEdit);
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false)
  const [choseSpeaker, setChoseSpeaker] = useState([])
  const [checkEnable,setCheckEnable]=useState(agendaEdit)
  const [checkNotification,setCheckNotification]=useState("false")

  const imageInput = useRef()


  const handleChange = (e) => {
    setEditAgenda({ ...editAgenda, description: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };
  const handleUpdate = () => {
    if(!isSpinner)
    updateAgendaApi(editAgenda, agendaId,checkNotification)
  };

  const buttonDisabled = (editAgenda.agenda_name !== "" && editAgenda.startTime !== "" && editAgenda.endTime !== "" )

  var newAgendaDate = new Date(editAgenda?.startDate).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });
  newAgendaDate = newAgendaDate.split(",");
  newAgendaDate = new Date(newAgendaDate).toLocaleDateString("fr-CA");

  // const dateConversion = (date) => {

  //   let date2 = new Date(date).toLocaleTimeString("en-US", {
  //     timeZone: "Asia/Calcutta",
  //   });
  //   date2 = date2.split(",");
  //   date2 = new Date(date2).toLocaleTimeString("fr-CA");
  //   var getTime = new Date(date);

  //   getTime = getTime.toLocaleString("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: false,
  //   });
  //   return date2;
  // };

  let startDate = new Date(specificEvent?.eventStartDate).toLocaleDateString('en-CA').split('T')[0];
  let endDate = new Date(specificEvent?.eventEndDate).toLocaleDateString('en-CA').split('T')[0];

  let todayDate = new Date().toISOString().split('T')[0]
  if ((startDate.slice(8, 10) < todayDate.slice(8, 10) && (startDate.slice(0,4)===todayDate.slice(0,4)&& startDate.slice(5,7)===todayDate.slice(5,7)))) {
    startDate = todayDate;
  }
  const onSpeakerSelect = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    const newSpeaker = speakers.filter((item) => {
      return item._id === option

    })
    if (!editAgenda.speaker.some(speaker => speaker._id === option)) {
      setEditAgenda({ ...editAgenda, speaker: [...editAgenda?.speaker, ...newSpeaker] })
     
    }
   

    // const resultArray = choseSpeaker.filter((obj1) =>
    //   !newSpeaker.some((obj2) => obj1._id === obj2._id)
    // );
    // setChoseSpeaker(resultArray)
  }

  const onSponsorSelect = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    const newSponsor = sponsorsList.filter((item) => {
      return item._id === option

    })
    if (!editAgenda.sponsor.some(speaker => speaker._id === option))
    setEditAgenda({ ...editAgenda, sponsor: [...editAgenda?.sponsor, ...newSponsor] })
  }

  const onRoomSelect = (e) => {
    //   const index = e.target.selectedIndex;
    //   const el = e.target.childNodes[index];
    //   const option = el.getAttribute("id");

    //  const newRoom=sponsorsList.filter((item,index)=>{
    //   return index===option

    //  })
    setEditAgenda({ ...editAgenda, Location: e.target.value })
    console.log(e.target.value)
  }

  const onInterestSelect = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    
    const newInterest = specificEvent?.interest?.filter((item, index) => {
     

      return index == option

    })
    if (!editAgenda.interest.some(speaker => speaker._id === option))

    setEditAgenda({ ...editAgenda, interest: [...editAgenda?.interest, { _id: option, value: newInterest[0] }] })
  }

  const removeSpeakerList = (item) => {
    const idToRemove = item._id;
    const updatedspeakerlist = editAgenda?.speaker?.filter((item) => item._id !== idToRemove);

    setEditAgenda({ ...editAgenda, speaker: [...updatedspeakerlist] });

    const resultArray = speakers.filter((obj1) =>
      !updatedspeakerlist.some((obj2) => obj1._id === obj2._id)
    );
    setChoseSpeaker(resultArray)
  }

  const removeSponsorList = (item) => {
    const idToRemove = item._id;
    const updatedsponsorlist = editAgenda?.sponsor?.filter((item) => item._id !== idToRemove);

    setEditAgenda({ ...editAgenda, sponsor: [...updatedsponsorlist] });
  }

  const removeInterestList = (item) => {
    const idToRemove = item;
   
  const updatedInterestlist = editAgenda?.interest?.filter((item,index) => index !== idToRemove);
  
  
  setEditAgenda({...editAgenda, interest: [...updatedInterestlist] });
   }
   
   const onImageChange = (event) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png','webp'];
  
    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName?.substring(fileName.lastIndexOf('.') + 1);
      return allowedExtensions.some(extension => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(event.target.files[0].name, allowedExtensions);

    if (event.target.files && event.target.files[0]) {
      setEditAgenda({...editAgenda, image: URL.createObjectURL(event.target.files[0])});
    }

    if (!isAllowed) {
      setImageError('Please select valid image format.');
      return false;
    }

    if ((event.target.files[0].size) && (event.target.files[0].size) / 1024 > 1024) {
      setImageError("Please select image less than 1 mb")
    }
    else {
      setImageError(false)
    }
  };

  const handleDelete = ()=>{
    setEditAgenda({...editAgenda, image: ""});
    setImage(null);
    imageInput.current.value = "";
    }

  useEffect(() => {
    

    const resultArray = speakers.filter((obj1) =>
      !editAgenda?.speaker.some((obj2) => obj1._id === obj2._id)
    );
    setChoseSpeaker(resultArray)

    setCount(editAgenda?.description?.split("").length);
    getSpeakersApi();
    getEventByIdApi()
  }, []);

console.log(checkEnable,"checkenable")
  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/agenda-list"}>
              <div className="w-8 pt-1">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2 ">
              <h1 className="px-2 text-xl">{editAgenda?.agenda_name}</h1>
            </div>
          </div>
          {/* <h1 className="text-theme-color text-2xl hover:bg-orange-400xl">
            Speaker info{" "}
          </h1> */}
          <div className="flex flex-col rounded-lg border-[#D1D9E7] p-4 bg-white">
          <div className="flex space-x-10 ">
            <div className="w-1/2 space-y-4 ">
              <h2 className="">Agenda details</h2>
              <hr />
              <form className="">
                <div>
                  <label
                    htmlFor="title"
                    className="text-sm px-1 font-semibold pb-1"
                  >
                    Title *
                  </label>
                  <input
                    type="text "
                    id="title"
                    className="w-full border py-1 px-3 capitalize placeholder:text-slate-400 text-sm h-9 border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Enter agenda name"
                    value={editAgenda?.agenda_name}
                    onChange={(e) => {
                      setEditAgenda({
                        ...editAgenda,
                        agenda_name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="py-2">
                  <label htmlFor="date" className=" text-sm font-semibold px-1  pb-1">
                    Date*
                  </label>
                  <input
                    type="date"
                    id="date"
                   
                    min={startDate}
                    max={endDate}
                    className="w-full border py-1 px-3 border-gray-300  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                    name="agenda_Date"
                    value={newAgendaDate}
                    onChange={(e) => {
                      setEditAgenda({ ...editAgenda, startDate: new Date(e.target.value) });
                    }}
                  />
                </div>
                <div className="flex space-x-5 py-3">
                  <div className="w-1/2">
                    <label
                      htmlFor="start_date"
                      className="pt-2 text-sm px-1 font-semibold  pb-1"
                    >
                      Start Time *
                    </label>
                    <input
                      type="time"
                      id="start_date"
                      className="w-full border py-1 px-3 border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      value={
                        editAgenda?.startTime === undefined
                          ? ""
                          : editAgenda?.startTime.slice(11, 16)
                      }
                      onChange={(e) => {
                        setEditAgenda({
                          ...editAgenda,
                          startTime: `${(editAgenda?.startDate).slice(0, 10)}T${e.target.value}`,
                        });

                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="end_date"
                      className="pt-2 text-sm px-1 font-semibold  pb-1"
                    >
                      End Time *
                    </label>
                    <input
                      type="time"
                      id="end_date"
                      className="w-full border py-1 px-3  border-gray-300  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      value={
                        editAgenda?.endTime === undefined
                          ? ""
                          : editAgenda?.endTime.slice(11, 16)
                      }
                      onChange={(e) => {
                        setEditAgenda({
                          ...editAgenda,
                          endTime: `${(editAgenda?.startDate).slice(0, 10)}T${e.target.value}`,
                        });

                      }}
                    />
                  </div>
                </div>
                <div className="py-1">
                  <label
                    id="speaker"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Speakers
                    {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                  </label>

                  <select
                    type="text"
                    id="speaker"
                    className="w-full border py-1 px-2  border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e) => { onSpeakerSelect(e) }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Speaker
                    </option>
                    {speakers?.map((item) => {
                      return (
                        <>
                          <option
                            id={item?._id}
                            key={item?._id}
                          >{`${item?.title} ${item?.speaker_firstname} ${item?.speaker_lastname}`}</option>
                        </>
                      );
                    })}
                  </select>
                  <ul className="flex flex-wrap gap-2">
                    {editAgenda?.speaker?.map((item, index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14} />
                            </span>
                            <span className="text-[#202223] text-xs">{`${item?.title} ${item?.speaker_firstname} ${item?.speaker_lastname}`}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX  size={14} onClick={() => { removeSpeakerList(item) }} />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="py-2">
                  <label
                    id="speaker"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Sponsors
                    {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                  </label>

                  <select
                    type="text"
                    id="speaker"
                    className="w-full border py-1 px-2  border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e) => { onSponsorSelect(e) }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Sponsor
                    </option>
                    {sponsorsList.map((item) => {
                      return (
                        <>
                          <option
                            id={item?._id}
                            key={item?._id}
                          >{`${item?.sponsor_name} `}</option>
                        </>
                      );
                    })}
                  </select>
                  <ul className="flex flex-wrap gap-2">
                    {editAgenda?.sponsor?.map((item, index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14} />
                            </span>
                            <span className="text-[#202223] text-xs">{`${item?.sponsor_name}`}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX size={14} onClick={() => { removeSponsorList(item) }} />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-2">
                  <label className="pt-3 text-sm  pb-2 font-semibold">
                    Description
                  </label>
                  <textarea
                    rows={"4"}
                    className="w-full border  px-3 py-1 border-gray-300 placeholder:text-slate-400 text-sm  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Enter description about session"
                    maxLength={2000}
                    value={editAgenda?.description}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />



                </div>
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">
                    Provide some details about the session.
                  </span>
                  <span className="bottom-2 text-sm ">
                    {count}/2000
                  </span>
                </div>
                <div className="flex gap-2 mt-4">
                      <p className="text-sm text-[#525252]">Status (Enable/Disable)</p>
                      <div><label class="relative inline-flex items-center me-5 cursor-pointer">
  <input type="checkbox" value="" class="sr-only peer" checked={editAgenda?.status=="Active"?true:false}  
  onClick={(e)=>{
    setCheckEnable(!checkEnable)
    setEditAgenda({...editAgenda,status:e.target.checked?"Active":"Disable"})}}/>
  <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-400 peer-focus:ring-4 peer-focus:ring-white  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
 
</label></div>

                    </div>
                    <div className="flex  gap-14 mt-2 items-center">
<p className="text-sm text-[#525252]" id="notification"> Send Notification</p>
<input type="checkbox" onChange={(e)=>{setCheckNotification(e.target.checked?"true" : "false")}} name="notification" id="notification" />
</div>
              </form>
            </div>
            <div className="w-1/2 space-y-2">
              <h2 className="pb-1 text-lg">Others</h2>
              <hr />
              <div className="py-2">
                <label
                  id="speaker"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Location 
                  {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                </label>

                <select
                  type="text"
                  id="speaker"
                  className="w-full border py-1 px-2  border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                  onChange={(e) => { onRoomSelect(e) }}
                >
                  {/* <option value="" selected disabled hidden>
                      Choose Location
                    </option> */}
                  <option value="" selected hidden>{editAgenda?.Location}</option>
                  {specificEvent?.room?.map((item, index) => {
                    return (
                      <>
                        <option
                          id={index}
                          key={index}
                        >{`${item} `}</option>
                      </>
                    );
                  })}
                </select>
                {/* <ul className="inline-block">
                    {editAgenda?.sponsor?.map((item) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-1">
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser />
                            </span>
                            <span className="text-[#202223]">{`${item?.sponsor_name}`}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX onClick={()=>{removeSponsorList(item)}} />
                          </span>
                        </li>
                      );
                    })}
                  </ul> */}
              </div>
              <div className="py-2">
                <label
                  id="speaker"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Interests
                  {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                </label>

                <select
                  type="text"
                  id="speaker"
                  className="w-full border py-1 px-2  border-gray-300   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                  onChange={(e) => { onInterestSelect(e) }}
                >
                  <option value="" selected disabled hidden>
                    Choose Interest
                  </option>
                  {specificEvent?.interest?.map((item, index) => {
                    return (
                      <>
                        <option
                          id={index}
                          key={index}
                        >{`${item}`}</option>
                      </>
                    );
                  })}
                </select>
                <ul className="flex flex-wrap gap-2 mt-1 items-center">
                  {


                    editAgenda?.interest?.map((item, index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14} />
                            </span>
                            <span className="text-[#202223] text-xs">{`${item?.value}`}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX size={14} onClick={() => { removeInterestList(index) }} />
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="pt-3">
                <label className="text-sm  font-semibold pb-1">
                  Banner Image
                </label>
                <div className=" h-32 border-2 border-dashed rounded-md text-center pt-5">
                  <label
                    htmlFor="choose_banner"
                   
                    className="text-theme-color cursor-pointer  text-sm flex flex-col justify-center items-center h-full "
                  >
                    <span className=" bg-theme-light-color px-2 py-1">

                      {image === null ? "Choose banner image" : editAgenda?.image?.name}
                    </span>
                    <p className="text-sm pt-1 text-gray-400">
                      Accepts jpg, jpeg, png and webp
                    </p>
                    <p className="text-[11px] font-normal text-gray-400">
                      Maximum Upload size: 1Mb
                    </p>
                    <input
                      id="choose_banner"
                      type="file"
                      ref={imageInput}
                      className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                      onChange={(e) => {
                        setEditAgenda({
                          ...editAgenda,
                          image: e.target.files[0],
                        });
                        onImageChange(e);
                      }}


                    />
                  </label>
                </div>
              </div>
              <span className="text-xs text-red-600 font-normal">{imageError ? imageError : ""}</span>
              <div className={editAgenda?.image === "" || imageError ? "hidden" : "relative w-28 h-28"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleDelete()}/>

                <img src={editAgenda.image} alt="banner" className="absolute w-full h-full object-cover"
 />
              </div>

              
            </div>
          </div>
          <div className="pt-5 flex gap-3">
            <Link to={"/agenda-list"}>
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
handleUpdate();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default UpdateAgenda;
