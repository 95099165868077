import React,{useContext, useEffect, useState} from "react"
import BarChart from "../Dashboard/Charts/BarChart"
import LineChart from "../Dashboard/Charts/LineChart"
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import dummyImage from "../Assets/Image.png"
import {
 
  FiUsers,
  } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import tableImage from "../Assets/Frame.png"
import TableNoData from "./TableNoData";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from 'react-tooltip'
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const AttendeeOverview=({state})=>{
  const {  getAttendeeReportApi,
    attendeeReport,totalCount,pageCount,currentPage,setcurrentPage,checkResponse,globalLoader}=useContext(DataContext)

    const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const timeconversion = (date) => {
    let nDate = String(date);

    return nDate.slice(11, 16);
  };
  const userData = {
    data: [
      {
        id: 1,
        year: 2016,
        userGain: 80000,
        userLost: 823,
      },
      {
        id: 2,
        year: 2017,
        userGain: 45677,
        userLost: 345,
      },
      {
        id: 3,
        year: 2018,
        userGain: 78888,
        userLost: 555,
      },
      {
        id: 4,
        year: 2019,
        userGain: 90000,
        userLost: 4555,
      },
      {
        id: 5,
        year: 2020,
        userGain: 4300,
        userLost: 234,
      },
    ],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ]
  }

  

  const [lineChartData, setLineChartData] = useState({
    labels: userData?.data?.map((data) => data.year),
    datasets: [{
      label: "Speaker Data",
      data: userData?.data?.map((element) => element.userGain),
    
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 1,
      tension: 0.1
    }]
  })
  useEffect(()=>{
    console.log("hello")
   getAttendeeReportApi(state,1,limit)
  },[])
  console.log(totalCount,"totalCount");
  console.log(pageCount,"pageCount")
  console.log(attendeeReport?.getAttendee,"hello")
  return (
    <>
    <div className=" w-full flex h-screen ">
        <div className="w-[100%] ">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div
                className="flex flex-col gap-4 
              "
              >
               

                <div className="flex flex-col">
                  
                 {
                  globalLoader ? <GlobalLoader/>: <div>
                  <div className="flex justify-between items-center mt-3">
                    <p className="text-[#202223] text-base font-semibold">
                      Overall Analytics
                    </p>
                    {/* <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                      Export XLS
                    </button> */}
                  </div>
                  <div className="w-full flex justify-center items-center mt-5 ">
                    <div className="w-1/2 h-32 bg-white flex flex-col justify-center items-center rounded-tl border-x border-b border-[#E3E6ED]">
                      <span className="text-[#202223] text-3xl font-bold">
                      {attendeeReport?.getAttendee && attendeeReport?.getAttendee[0]?.totalNumberOfAttendee}
                      </span>
                      <span className="text-[#31374A] text-xs font-normal">
                        Total Attendee
                      </span>
                    </div>
                    
                    <div className="w-1/2 h-32 bg-white flex flex-col justify-center rounded-tr items-center border-r border-b border-[#E3E6ED]">
                      <span className="text-[#202223] text-3xl font-bold">
                      {attendeeReport?.getAttendee && attendeeReport?.getAttendee[0]?.totalLoggedInOnce}
                      </span>
                      <span className="text-[#31374A] text-xs font-normal">
                       Logged In
                      </span>
                    </div>
                    <div></div>
                  </div>
                  

                  <div className="bg-white mt-4 rounded-t">
                    <div className="flex justify-between">
                      <div className="flex flex-col gap-1 p-2">
                        <p className="text-[#141824] text-base font-semibold">
                          {" "}
                          Total attendees
                        </p>
                        
                      </div>
                      {/* <div className="flex gap-2 items-center mr-4 mt-2">
                        <span className="capitalize text-sm font-semibold" >From</span>

                        

                        <input
                          type="date"
                          value={state?.startDate}
                          name="startDate"
                          id="startDate"
                          className="text-[#31374A] w-32 border py-1 px-2 font-semibold border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                        />

                        <span className="capitalize text-sm font-semibold">To</span>

                        <input
                          type="date"
                          value={state?.endDate}
                          className="text-[#31374A] w-32 border py-1 px-2  font-semibold border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                          name="endDate"
                          id="endDate"
                        />
                      </div> */}
                    </div>
                    <div className="w-full h-[350px] ">
                      <LineChart chartData={lineChartData} />
                    </div>
                    <div>
                     
                      <table className="w-full table-auto bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[12%] px-4 py-2 rounded relative">
                            Name
                            </th>

                           
                            <th className="w-[20%] px-4 py-2 relative">
                             Email
                            </th>
                            <th className="w-[20%] px-4 py-2 relative">
                              Organisation
                            </th>
                            <th className="w-[20%] px-4 py-2">Position</th>
                            <th className="w-[18%] px-4 py-2">Last Logged In</th>
                            <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB] ">
                          { attendeeReport?.AttendeeFind?.docs.length==0 && checkResponse ?
                          <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>:
                            attendeeReport?.AttendeeFind?.docs.map((item)=>{
                              return (
                                <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="py-2 px-4">{item?.Name}</td>

                               

                                <td className=" py-2 px-4">
                               {item?.email}
                                </td>
                                <td className=" py-2 px-4">
                               {item?.organization}
                                </td>
                              
                                <td className=" py-2 px-4">
                               {item?.designation}
                                </td>
                                
                                <td className=" py-2 px-4">
                                {(item?.lastLogin)=== undefined ? "Not logged in": `${dateConversion(item?.lastLogin)[0]} | ${timeconversion(item?.lastLogin)}`  }
                                </td>
                                
                                

                             

                                <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/attendeeoverview-view" state={item}>
                                      <AiOutlineEye 
                                        data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                      className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                       <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                                    </Link>
                                  </div>
                                </td>
                               
                                
                              </tr>
                              )
                            })
                          }
                          
                           
                              
                             
                              
                           
                        </tbody>
                      </table>
                     
                     
                    </div>
                   
                  </div>
                 
                  </div>
                 }
                 
                  
                </div>
                {
                        attendeeReport?.AttendeeFind?.docs.length!==0 && !checkResponse &&
 <div className="flex justify-between items-center ">
 <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
 <Pagination
   firstResultCount={firstResultCount}
   setFirstResultCount={setFirstResultCount}
   secondResultCount={secondResultCount}
   setSecondResultCount={setSecondResultCount}
   limit={limit}
   api={getAttendeeReportApi}
   state={state}
 />
</div>
                      }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AttendeeOverview;