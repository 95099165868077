import React, { useContext, useEffect, useState } from "react";
import dummyImage from "../Assets/dummyimage.jpg";
import { DataContext } from "../../context/DataState";

const SocialWallModal = ({ item }) => {

  const { setPollModalBtn, pollModalBtn } = useContext(DataContext)

  //  const formatDate = (time) => {
  //   if (!time) return "N/A";
  //   const date = new Date(time);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "short",
  //     day: "2-digit",
  //   });
  // };

  // const formatTime = (time) => {
  //   if (!time) return "N/A";
  //   const date = new Date(time);
  //   return date.toLocaleTimeString("en-US", {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //   });
  // };


  return (
    <>
      <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[1]">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white p-4 w-[100vh] max-h-[80%]     rounded z-10">

            <div className="pt-3 ">

              <h1 className="text-xl text-[#202223] font-semibold">{pollModalBtn == "like" ? "Likes" : "Comments"}</h1>
            </div>
            <div className="mb-1 mt-3">
              <hr className="bg-[#0000001A] h-[2px]" />

            </div>

            <table className="w-full overflow-y-scroll  h-[30%]  bg-white text-start shadow-md border-separate rounded-lg ">
              <thead className="cursor-pointer">
                <tr className="text-white capitalize text-left bg-[#FF8531] text-xs font-normal ">
                  <td className="w-[25%] px-4 py-2 rounded-tl-lg ">Full Name</td>

                  <td className="w-[50%] px-4 py-2 rounded-tl-lg ">{pollModalBtn == "like" ? "Email" : "Comments"}</td>
                  <td className="w-[75%] px-4 py-2 rounded-tl-lg ">{pollModalBtn == "like" ? "LogTime" : "LogTime"}</td>


                </tr>
              </thead>
              <tbody className=" " >
                {
                  item.map((element) => {
                    console.log("0202020202020202",element)
                    return (
                      <tr className="border-t text-left text-sm text-[#202223] font-normal hover:shadow-tableRowsShadow cursor-pointer">
                        {/* Name */}
                        <td className="py-2 px-4">
                          {pollModalBtn == "like" ? element?.user.Name : element?.attendee_id?.Name}
                        </td>

                        {/* Email or Comment */}
                        <td className="py-2 px-4">
                          {pollModalBtn == "like" ? element?.user.email : element?.comment}
                        </td>

                        <td className="py-2 px-4">
  {element?.time ? (
    <div className="flex flex-col">
      {/* Date */}
      <span className="text-sm font-semibold text-gray-700">
        {new Date(element?.time).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        })}
      </span>
      {/* Time */}
      <span className="text-xs text-gray-500">
        {new Date(element?.time).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        })}
      </span>
    </div>
  ) : (
    "N/A"
  )}
</td>


                      </tr>
                    );
                  })
                }






              </tbody>
            </table>




            <div className="flex items-center justify-center mt-5 ">
              <button type="button" onClick={() => { setPollModalBtn(false) }} className="bg-theme-color text-white px-4 py-2 text-sm font-medium w-[200px] rounded" >ok</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SocialWallModal;
