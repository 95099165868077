import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaSearch ,FaSortUp, FaSortDown} from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { useLocation } from "react-router-dom";

const CheckIn = () => {
  const { globalLoader, searchCheckInApi ,checkInList,setCheckInList} = useContext(DataContext);
  const [searchInput,setSearchInput]=useState("")
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const location = useLocation();
  
  const agendaEdit =location.state.item|| location.state;
  const onKeyUpHandler = (e) => {
    searchCheckInApi(agendaEdit._id,e.target.value);
  };

  const getSortArrowClassName = (property, direction) => {
    console.log("hello")
    console.log(property,"property")
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };
  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const timeconversion = (date) => {
    date=new Date(date)
    console.log(date,"date")
    const newUtcTime = new Date(date.getTime() + (5 * 60 + 30) * 60000);
     return newUtcTime.toISOString().slice(11,16);
    // let nDate = String(date);

    // return nDate.slice(11, 16);
  };
  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...checkInList].sort((a, b) => {
      console.log("sortdata")
      const valueA = (a[property] || '').toString();
      const valueB = (b[property] || '').toString();
      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

   setCheckInList(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  useEffect(()=>{
  searchCheckInApi(agendaEdit._id,"")
  },[])
  console.log(checkInList,"checkInlist")
  return (
    <>
      <div className="flex w-full bg-white">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={`${location.state.item?"/reports":"/agenda-list"}`}>
              <div className="w-8 pt-1">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2 ">
              <h1 className="px-2 text-xl">{`Check In - ${agendaEdit?.agenda_name}`}</h1>
            </div>
          </div>
          <div className="">
            <FaSearch className="search-icon relative top-[1.7rem]  left-2 text-gray-500 " />
            <input
              type="text"
              placeholder="Search"
              className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
              value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
            />
          </div>

          <table className="w-full table-auto bg-white shadow-md rounded-md ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className=" w-[8%] p-2 rounded-tl-md  relative">Name
                <div className="px-[74px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "Name",
                          "asc"
                        )}`}
                        onClick={() => sortData("Name")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "Name",
                          "desc"
                        )}`}
                        onClick={() => sortData("Name")}
                      />
                    </span>
                  </div>
                </th>
                {/* <th className=" w-[10%] p-2 relative">Last Name
                <div className="px-[74px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "lastName",
                          "asc"
                        )}`}
                        onClick={() => sortData("lastName")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "lastName",
                          "desc"
                        )}`}
                        onClick={() => sortData("lastName")}
                      />
                    </span>
                  </div>
                </th> */}
                <th className="w-[20%] p-2 ">Email</th>
                <th className="w-[10%] p-2 ">Organization</th>
                <th className="w-[15%] p-2 ">Position</th>
                <th className="w-[15%] p-2 ">Check In Time</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {(checkInList)?.map((item) => {
                return (
                  <>
                    <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow">
                      <td className="p-2 text-sm text-[#202223] leading-5">
                        <p>{item?.userId?.Name}</p>
                      </td>
                      {/* <td className="p-2 text-sm text-[#202223] leading-5">
                        <p>{item?.userId?.lastname}</p>
                      </td> */}
                      <td className="p-2 text-sm">{item?.userId?.email}</td>
                      <td className="p-2 text-sm whitespace-nowrap">
                        {item?.userId?.organization}
                      </td>
                      <td className="p-2 text-sm">{item?.userId?.designation}</td>
                      <td className="p-2 text-sm">{(item?.checkinTime
                        )=== undefined ? " ": `${dateConversion(item?.checkinTime
                          )[0]} | ${timeconversion(item?.checkinTime)}`  }</td>

                      {/* <td className="p-2 text-sm">{`${dateConversion(item?.createdAt)[0]} | ${timeconversion(item?.createdAt)}`}</td> */}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default CheckIn;
