import React, { useContext, useEffect } from "react"
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { useLocation } from "react-router-dom";


const SpeakerDetails=()=>{
  const {speakers,findOneSpeakerApi}=useContext(DataContext)
  const location=useLocation()
  const state=location.state
   useEffect(()=>{
    console.log("hello")
    findOneSpeakerApi(state._id)
   },[])
   console.log(speakers,"speakers")
  return (
    <>
    <div className="flex w-full ">
        <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/speaker_list"}>
              <div className="w-8 pt-1">
                <button className="border p-2" >
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">{`${state?.title} ${state?.speaker_firstname} ${state?.speaker_lastname}`} </h1>
            </div>
          </div>
          <div className="flex space-x-10 m-0 ">
            
            
          </div>
          <div className="flex flex-col rounded-lg bg-white border-[#D1D9E7] p-3">
          <div className=" flex space-x-10  ">
            <div className="w-1/2 space-y-3">
              
              <div className="">
                <div>
                  <label
                    htmlFor="first-name"
                    className="text-sm px-1 font-semibold "
                  >
                    Name 
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstname"
                    
                    className="w-full pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 shadow-sm rounded-md text-[#8C9196]  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={`${state?.title} ${state?.speaker_firstname} ${state?.speaker_lastname}`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                   Organization 
                  </label>
                  <input
                    id="organization"
                    type="text"
                    name="organization"
                   
                    className="w-full border pointer-events-none  py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.organization===undefined ||state.organization==="" ? "No Data" :state.organization}
                  />
                </div>
                <div>
                  <label
                    htmlFor="position"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                   Position 
                  </label>
                  <input
                    id="position"
                    type="text"
                    name="position"
                    
                    className="w-full  pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.position===undefined ||state.position==="" ? "No Data" :state.position}
                  />
                  
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Country 
                  </label>
                  <input
                    type="text"
                    name="country"
                    placeholder="country"
                    className="w-full  pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.country===undefined ||state.country==="" ? "No Data" :state.country}
                    
                  />
                </div>
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Instagram Url
                  </label>
                  <input
                    type="text"
                    name="organization"
                    placeholder="Organization"
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.instagram_profile===undefined ||state.instagram_profile==="" ? "No Data" :state.instagram_profile}
                    
                  />
                </div>
                
                
                <div>
                  <label
                    htmlFor="limkedin-profile"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Linkedin Url
                  </label>
                  <input
                    id="linkedin-profile"
                    type="text"
                    name="linkedin-profile"
                    
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.linkedin_profile===undefined ||state.linkedin_profile==="" ? "No Data" :state.linkedin_profile}
                  />
                 
                </div>
               
              

              </div>
            </div>
            <div className="w-1/2 ">
              
              
              <div>
                <label
                  htmlFor="email"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Email Id
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"ˀ
                 
                  className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.email===undefined ||state.email==="" ? "No Data" :state.email}
                  
                />
                
              </div>
              
              <div>
                  <label
                    htmlFor="facebook-profile"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Facebook Url
                  </label>
                  <input
                    id="facebook-profile"
                    type="text"
                    name="facebook-profile"
                   
                    className="w-full pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.facebook_profile===undefined ||state.facebook_profile==="" ? "No Data" :state.facebook_profile}
                  />
                 
                </div>

              <div>
                  <label
                    htmlFor="twitter-profile"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Twitter Url
                  </label>
                  <input
                    id="twitter-profile"
                   type="text"
                    name="twitter-profile"
                    placeholder="Enter your email"
                    className="w-full pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.twitter_profile===undefined ||state.twitter_profile==="" ? "No Data" :state.twitter_profile}
                  />
                </div>
              <div className="">
                <p className="text-sm pt-2  font-semibold pb-1">
                  Speaker Photo 
                </p>
                <div>
               {state.image===undefined ||state.image==="" ? <span className="text-[#8C9196] text-sm">No Data</span>: <img src={state.image} alt=""  className="w-32 h-28 rounded"/>}
                 
                </div>
                
                
              </div>

              <div className="mt-4 relative">
                <label
                  htmlFor="biography"
                  className="text-sm pt-4 font-semibold pb-1"
                >
                  Biography 
                </label>
                <textarea
                  rows="7"
                  id="biography"
                  name="biography"
                  className="w-full border pointer-events-none py-1 px-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                  value={state.Biography===undefined ||state.Biography==="" ? "No Data" :state.Biography}
                  
                />
                <div className="flex justify-between">
                  {/* <span className="text-xs text-gray-500 items-center">
                    Provide some details about the speaker's background,
                    achievements and presentation goals.
                  </span> */}
                  {/* <span className=" bottom-1 text-xs">{count}/2000</span> */}
                </div>
              </div>
             
             
            </div>
            
            
          </div>
          </div>
         
            
        </div>
      </div>
    </>
  )
}
export default SpeakerDetails;