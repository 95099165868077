import React from "react";
import ReactPlayer from 'react-player'


const Tutorial = () => {
  return (
    <>
      <div className="w-full px-7 py-2 overflow-y-scroll scrollbar-thin h-screen space-y-4">
        <div className="pt-3 ">
          <h1 className="text-xl text-[#202223] font-semibold">TUTORIALS</h1>
        </div>
        <div className="flex gap-6 flex-wrap">
        <div className="bg-white w-[316px] rounded-[8px] flex flex-row gap-3 flex-wrap">
          <div className="flex flex-col gap-1 shadow-videoPlayerShadow">
            <div className="rounded-t-lg">  <ReactPlayer url='https://www.youtube.com/watch?v=iu-LBY7NXD4' width="316px" height="218px"  controls={true} progressInterval={true}  /></div>
        
          <span className="text-[20px] font-normal text-[#202223] px-3 mb-2">Global conference 2024 </span>
          </div>
         
         
       
        </div>
        <div className="bg-white w-[316px] rounded-[8px] flex flex-row gap-3 flex-wrap">
          <div className="flex flex-col gap-1  shadow-videoPlayerShadow">
            <div>  <ReactPlayer url='https://www.youtube.com/watch?v=iu-LBY7NXD4' width="316px" height="218px"  controls={true} progressInterval={true}  className="rounded-t-lg"/></div>
        
          <span className="text-[20px] font-normal text-[#202223] px-3 mb-2">Global conference 2024 </span>
          </div>
         
         
       
        </div>
        </div>
        
      </div>
    </>
  );
};

export default Tutorial;
