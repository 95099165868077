import React from 'react'
import { Link } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'
import { useEffect ,useState} from 'react'
import { useContext } from 'react'
import { DataContext } from '../../context/DataState'
import GlobalLoader from '../GlobalLoader/GlobalLoader'
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom'
import DeleteModal from '../DeleteModal/DeleteModal'
import { ToastContainer } from 'react-toastify'
import Pagination from "../Pagination/Pagination"
import NoDataModal from '../NoDatatModal/NoDataModal'
import { Tooltip } from 'react-tooltip'


const Faq = () => {
  const {faqList,globalLoader,deleteBtn,setDeleteBtn,setDeleteBtnId, deleteFaqApi, deleteBtnId,faqPaginationApi,totalCount,pageCount,currentPage,checkResponse}=useContext(DataContext);
  const navigate=useNavigate()

  const limit=15;
  const [firstResultCount,setFirstResultCount]=useState(1)
  const [secondResultCount,setSecondResultCount]=useState(limit)
 
  useEffect(()=>{
    
    faqPaginationApi(1,limit)
  },[])

  return (
    <>
    {deleteBtn ? <DeleteModal deleteApi={deleteFaqApi} deleteId={deleteBtnId} currentPage={currentPage} limit={limit}
        secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount}   /> : ""}
    <ToastContainer/>
      <div className="w-full  px-8 pt-[26px] overflow-y-scroll h-screen space-y-6">
        <div className="flex justify-between items-center">
          
          
          <span><h1 className="text-xl text-[#202223] font-semibold">FAQ</h1></span>
          
        
          <div className="">
            <Link to="/add-faq">
              <button className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm">
                Add New FAQ
              </button>
            </Link>
           
          </div>
        </div>
     
       
      
         {
         faqList?.length===0 && checkResponse===true ? <NoDataModal/> :
          globalLoader ? 
            <GlobalLoader />:
          <>
          <table className="w-full table-auto bg-white shadow-md rounded-md ">
          <thead className='cursor-pointer'>
            <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
              <th className=" w-[45%] p-2">Heading</th>
              <th className=" w-[45%] p-2">Description</th>
              <th className="w-[10%] text-center ">Action</th>

            </tr>
          </thead>
          <tbody className='[&>*:nth-child(even)]:bg-[#FAFBFB]'>
            {
              faqList?.map((item)=>{
                return (
                  <tr className='border-t cursor-pointer group hover:shadow-tableRowsShadow' key={item?._id}>
              <td className='p-2 text-sm text-[#202223] leading-5'><p>{item?.FAQText}</p></td>
              <td className='p-2 text-sm text-[#202223] leading-5'><p>{item?.FAQAnswer}</p></td>
              <td className='p-2'>
              <div className="flex items-center  justify-center  gap-2 text-black ">
                  <Link to={`/update-faq/${item?._id}`} state={item}>
                  <FiEdit2 
                    data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                  className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' /> 
                  
                  <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                  </Link>
                    <FiTrash 
                     data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                    className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' 
                    onClick={()=>{setDeleteBtn(true)
                    setDeleteBtnId(item?._id)
                    }} />
                      <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                    </div></td>
            </tr>
                )
              })
            }
            {/* <tr className='border-t cursor-pointer hover:shadow-tableRowsShadow'>
              <td className='px-4 text-sm py-12 text-[#202223] leading-5'><p>How would you begin a not-for-profit organization?</p></td>
              <td className='px-4 text-sm py-[18px] text-[#202223] leading-5'><p>Nonprofits and education organizations are always in everyone’s interest because they direct their goals to community and social activities, so common FAQ questions for websites will be beneficial for internet users to learn about your organization.</p></td>
            </tr>
            <tr className='border-y  hover:shadow-tableRowsShadow cursor-pointer'>
              <td className='px-4 text-sm py-12 text-[#202223] leading-5'><p>How would you begin a not-for-profit organization?</p></td>
              <td className='px-4 text-sm py-[18px] text-[#202223] leading-5'><p>Nonprofits and education organizations are always in everyone’s interest because they direct their goals to community and social activities, so common FAQ questions for websites will be beneficial for internet users to learn about your organization.</p></td>
            </tr>
            <tr className='hover:shadow-tableRowsShadow cursor-pointer'>
              <td className='px-4 text-sm py-12 text-[#202223] leading-5'><p>How would you begin a not-for-profit organization?</p></td>
              <td className='px-4 text-sm py-[18px] text-[#202223] leading-5'><p>Nonprofits and education organizations are always in everyone’s interest because they direct their goals to community and social activities, so common FAQ questions for websites will be beneficial for internet users to learn about your organization.</p></td>
            </tr> */}


          </tbody>
        </table>
        {
         !checkResponse &&  <div className="flex justify-between items-start ">
          <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
         <Pagination   firstResultCount={firstResultCount} setFirstResultCount={setFirstResultCount} secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} limit={limit} api={faqPaginationApi}/>
         </div>
        }
       
        </>
        
         }
              
         
        

      </div>
    </>
  )
}

export default Faq
