import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";

const ChangePassword = () => {
  const {  setIsPassModal, changePasswordApi } = useContext(DataContext);
  const [oldPassword, setOldPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [matchPasswordError, setMatchPasswordError] = useState(false);

  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });

  const validateOldPassword = () => {
    if (state.oldPassword === "") {
      setOldPasswordError(true);
    } else {
      setOldPasswordError(false);
    }
  }

  const validateNewPassword = () => {
    if (state.newPassword === "") {
      setNewPasswordError(true);
    } else {
      setNewPasswordError(false);
    }
  }

  const validateConfirmPassword = () => {
    if (state.confirmNewPassword === "") {
      setConfirmNewPasswordError(true);
    } else {
      setConfirmNewPasswordError(false);
    }

  }

  const matchNewPassword = () => {
    if(state.confirmNewPassword === ""){
      setMatchPasswordError(false);
    } else if(state.newPassword !== state.confirmNewPassword){
      setMatchPasswordError(true);
    } else {
      setMatchPasswordError(false);
    }
  }

  const buttotDisabled = (state.oldPassword === "" || state.newPassword === "" || state.confirmNewPassword === "" || state.newPassword !== state.confirmNewPassword )

  const handleChangePassword = () => {
    changePasswordApi(state);
  }

  return (
    <>
      <div className="absolute top-[50%] left-[50%] z-[1] -translate-x-2/4 -translate-y-2/4 w-full h-full bg-[#00000090] ">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white w-[451px] p-9 rounded-lg z-10 ">
            <h2 className=" font-semibold text-xl pb-4">Change Password</h2>
            <form>
              <div className="mb-3">
                <label className="block text-sm font-normal text-[#202223] mb-1">
                  Old password
                </label>
                <div className="relative">  
                  <input
                    type={oldPassword === true ? "text" : "password"}
                    className="block w-full px-3 py-2 bg-white border border-[#AEB4B9] shadow-inputshadow rounded-md text-sm focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Old Password"
                    onChange={(e) => { setState({ ...state, oldPassword: e.target.value }) }}
                    onKeyUp={() => validateOldPassword()}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setOldPassword(!oldPassword)}
                  >
                    {oldPassword === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </div>
                {
                  oldPasswordError && (<small className="text-xs text-left text-red-600">Enter Old Password</small>)
                }
              </div>
              <div className="mb-3">
                <label className="block text-sm font-normal text-[#202223] mb-1">
                  New password
                </label>
                <div className="relative">
                  <input
                    type={newPassword === true ? "text" : "password"}
                    className="block w-full px-3 py-2 bg-white border border-[#AEB4B9] shadow-inputshadow rounded-md text-sm focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="New Password"
                    onChange={(e) => { setState({ ...state, newPassword: e.target.value }) }}
                    onKeyUp={() => {validateNewPassword(); matchNewPassword();}}
                  />
                   <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setNewPassword(!newPassword)}
                  >
                    {newPassword === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </div>
                {
                  newPasswordError && (<small className="text-xs text-left text-red-600">Enter New Password</small>)
                }
              </div>
              <div className="mb-3">
                <label className="block text-sm font-normal text-[#202223] mb-1">
                    Confirm password
                </label>
                <div className="relative">
                  <input
                    type={confirmNewPassword === true ? "text" : "password"}
                    className="block w-full px-3 py-2 bg-white border border-[#AEB4B9] shadow-inputshadow rounded-md text-sm focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Confirm Password"
                    onChange={(e) => { setState({ ...state, confirmNewPassword: e.target.value }) }}
                    onKeyUp={() => {validateConfirmPassword(); matchNewPassword();}}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                    onClick={() => setConfirmNewPassword(!confirmNewPassword)}
                  >
                    {confirmNewPassword === true ? (
                      <FaRegEye className="h-5 w-5 fill-theme-color" />
                    ) : (
                      <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                    )}
                  </span>
                </div>
                {
                  confirmNewPasswordError && (<small className="text-xs text-left text-red-600">Enter Confirm Password</small>)
                }
                {
                  matchPasswordError && 
                    (<small className="text-xs text-left text-red-600">New Password and Confirm Password not match</small>)
                }
              </div>
              <div className="flex justify-between gap-2 pt-2">
                <Link to={"/event_information"}>
                  <button
                    type="button"
                    className="hover:bg-gray-300 border font-medium border-[#BABFC3] text-[#202223]  hover:text-white  p-2 w-48 rounded text-sm"
                    onClick={() => { setIsPassModal(false) }}>
                    Back
                  </button>
                </Link>

                <button
                  type="button"
                  className="bg-theme-color text-white p-2 w-48  font-medium rounded text-sm disabled:opacity-40"
                  disabled={buttotDisabled}
                  onClick={() => handleChangePassword()}
                >
                  Save
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
