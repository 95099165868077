import React, { useContext, useEffect, useState, useRef } from "react";
import { FaArrowLeft, FaCross } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";
import TagsModal from "./TagsModal";


const NewSession = () => {
  const { getSpeakersApi,updatEventApi,getAgendaById,agendaList ,speakers, createAgendaApi,specificEvent,getEventByIdApi,getSponsorList,sponsorsList ,isSpinner} = useContext(DataContext);
  const [isLocationModalOpen,setIsLocationModalOpen]=useState(false)
  const [isInterestTagModalOpen,setIsInterestTagModalOpen]=useState(false)
  
  
  
  const obj={
    location:{
      h1:"Add New Location",
      h2:"Location",
      placeholder:"Enter event Location",
      p:"Enter the full address or name of the venue where your event will be held. Be as specific as possible to help attendees find it"
    },
    interest:{
      h1:"Add New Interest Tag",
      h2:"Interests Tag",
      placeholder:"Enter interest tag ",
      p:"Enter relevant tags to categorize your event and link it to related agenda topics like technology, AI, machine learning etc"
    },
  }
  const [state, setState] = useState({
    agenda_name: "",
    startTime: "",
    description: "",
    image: "",
   agenda_Date: "",
    speaker: [],
    endTime: "",
    Location: "",
    sponsor:[],
    interest:[],
    document:"",
    status:""
  });
  const isEventCollision = (agendaList) => {
    const collisionArray=agendaList.filter((item)=>{
      console.log(new Date(state.startTime).getTime() >= new Date(item.startTime).getTime(),"inside filere")
      return (
        (new Date(state.startTime).getTime() >= new Date(item.startTime).getTime() &&
          new Date(state.startTime).getTime() <= new Date(item.endTime).getTime()) ||
          (new Date(state.endTime).getTime() >= new Date(item.startTime).getTime() &&
            new Date(state.endTime).getTime() <= new Date(item.endTime).getTime())
      )
    })
   console.log(collisionArray,"collisionArray")
  };
  


  const buttonDisabled=state.agenda_name !== "" && state.startTime !== ""  && state.endTime !== ""  

  const [speakerList, setSpeakerList] = useState([]);
  const [agendaSponsorList, setAgendaSponsorList] = useState([]);
  const [roomList,setRoomList]=useState([])
  const [interestList,setInterestList]=useState([])
  const [image, setImage] = useState(null);
  const [count, setCount] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [fileError,setFileError]=useState(false)
  // const [speakerOption,setSpeakerOption]=useState(false)
const imageInput = useRef()
const onSpeakerSelect = (e) => {
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index];
  const option = el.getAttribute("id");

  const selectedSpeaker = {"_id": option, "value": e.target.value};

  // Check if the selected speaker is not already in the list
  if (!speakerList.some(speaker => speaker._id === option)) {
    setSpeakerList([...speakerList, selectedSpeaker]);
    setState({ ...state, speaker: [...state.speaker, selectedSpeaker] });
  }
};
const onsponsorSelect = (e) => {
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index];
  const option = el.getAttribute("id");

  const selectedSponsor = {"_id": option, "value": e.target.value};

  // Check if the selected sponsor is not already in the list
  if (!agendaSponsorList.some(sponsor => sponsor._id === option)) {
    setAgendaSponsorList([...agendaSponsorList, selectedSponsor]);
    setState({ ...state, sponsor: [...state.sponsor, selectedSponsor] });
  }
};
console.log(state, "thisisstate")

  const onRoomSelect = (e) => {
    
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index];
    // const option = el.getAttribute("id");
    
    // setRoomList([...roomList, {"_id":option,"value":e.target.value}]);
    setState({ ...state, Location: e.target.value });
    
     
  };

  const onInterestSelect = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
  
    const selectedInterest = {"_id": option, "value": e.target.value};
  
    // Check if the selected interest is not already in the list
    if (!interestList.some(interest => interest._id === option)) {
      setInterestList([...interestList, selectedInterest]);
      setState({ ...state, interest: [...state.interest, selectedInterest] });
    }
  };
 
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }

    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)
    ) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false);
    }
  };
  const handleChange = (e) => {
    setState({ ...state, description: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };

  const handleSubmit = () => {
    if(!isSpinner)
    createAgendaApi(state);
  };
 const removeSpeakerList=(item)=>{
  const idToRemove = item._id;
const updatedspeakerlist = speakerList.filter((item) => item._id !== idToRemove);
// if(updatedspeakerlist.length===0)
// {
//   setSpeakerOption(false)
// }
setSpeakerList([...updatedspeakerlist])
setState({ ...state, speaker: [...updatedspeakerlist] });
 }

 const removeSponsorList=(item)=>{
  const idToRemove = item._id;
const updatedsponsorlist = agendaSponsorList.filter((item) => item._id !== idToRemove);
setAgendaSponsorList([...updatedsponsorlist])
setState({ ...state, sponsor: [...updatedsponsorlist] });
 }

 

 const removeInterestList=(item)=>{
  const idToRemove = item;
const updatedInterestList = interestList.filter((item,index) => index !== idToRemove);

setInterestList([...updatedInterestList])
setState({ ...state, interest: [...updatedInterestList] });
 }
 useEffect(()=>{
   isEventCollision(agendaList)
 },[state.endTime])

  useEffect(() => {
    getSpeakersApi();
    getEventByIdApi();
    getSponsorList()
    getAgendaById()
  }, []);
  console.log(specificEvent,"specific event")
  let startDate = new Date(specificEvent?.eventStartDate).toLocaleDateString('en-CA').split('T')[0];
  let endDate = new Date(specificEvent?.eventEndDate).toLocaleDateString('en-CA').split('T')[0];
 
  let todayDate=new Date().toISOString().split('T')[0]
  if (startDate.slice(8,10)<todayDate.slice(8,10) && (startDate.slice(0,4)===todayDate.slice(0,4)&& startDate.slice(5,7)===todayDate.slice(5,7)) )
  {
     startDate=todayDate;
  }
  const onFileChange = (event) => {
    console.log(event,"event")
    setState({ ...state, document: event.target.files[0] });
    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(pdf|pptx)$/)
    ) {
      setFileError("only .pdf and .pptx file format are supported");
      return false;
    } else {
      setFileError(false);
    }
  };
 
  const handleDelete = ()=>{
    setState({...state, image: ""});
    setImage(null)
    imageInput.current.value = "";
    
  }
  
 console.log(state,"start")

  return (
    <>
    {
      isLocationModalOpen ? <TagsModal obj={obj.location} setFunction={setIsLocationModalOpen}/>:<></>
    }
    {
      isInterestTagModalOpen ? <TagsModal obj={obj.interest} setFunction={setIsInterestTagModalOpen}/>:<></>
    }
      <div className="flex w-full ">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/agenda-list"}>
              <div className="w-8 pt-1">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2 ">
              <h1 className="px-2 text-xl">Agenda Information</h1>
            </div>
          </div>

          <div className="flex flex-col border-[#D1D9E7] rounded-lg p-4  bg-white">
          <div className="flex space-x-10 ">
            <div className="w-1/2 space-y-4 ">
              <h2 className="">Agenda details</h2>
              <hr />
              <form className="">
                <div>
                <label htmlFor="title" className="text-sm px-1 font-semibold pb-1">
                  Title *
                </label>
                <input
                  type="text "
                  id="title"
                  className="w-full border capitalize py-1 px-3 border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                  value={state.agenda_name}
                  placeholder="Enter agenda name"
                  onChange={(e) => {
                    setState({ ...state, agenda_name: e.target.value });
                  }}
                />
                </div>
                
                <div className="py-2">
                  <label htmlFor="date" className=" text-sm font-semibold px-1 pb-1">
                    Date *
                  </label>
                  <input 
                    min={startDate}
                    value={state.agenda_Date}
                    max={endDate}                   
                    type="date"
                    id="date"
                    className="w-full border py-1 px-3 border-gray-300 text-sm placeholder:text-slate-400 h-9 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                    name="agenda_Date"
                    onChange={(e) => {
                      setState({ ...state, agenda_Date: e.target.value })
                    }}
                  />
                </div>
                <div className="flex space-x-5 py-3">
                  <div className="w-1/2">
                    <label
                      htmlFor="start_date"
                      className="pt-2 text-sm px-1 font-semibold  pb-1"
                    >
                      Start Time *
                    </label>
                    <input
                      type="time"
                      id="start_date"
                      
                      className="w-full border py-1 px-3 border-gray-300  text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      onChange={(e) => {
                    
                        setState({ ...state, startTime:`${state.agenda_Date}T${e.target.value}` });
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="end_date"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      End Time *
                    </label>
                    <input
                      type="time"
                      id="end_date"
                      className="w-full border py-1 px-3  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      onChange={(e) => {
                     
                        setState({ ...state, endTime: `${state.agenda_Date}T${e.target.value}` });
                      }}
                    />
                    
                     
                  </div>
                </div>
                <div className="py-1">
                  <label id="speaker" className=" text-sm px-1 font-semibold  pb-1">
                    Speakers 
                    {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                  </label>

                  <select
                    type="text"
                    id="speaker"
                    className="w-full border py-1 px-2 border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    
                    onChange={(e) => {
                      onSpeakerSelect(e);
                    }}
                  >
                    <option value="" selected={ speakerList.length===0 ? "true" : "false"} disabled hidden>
                      Choose Speaker
                    </option>
                    {speakers?.map((item) => {
                      return (
                        <>
                          <option
                            id={item?._id}
                            key={item?._id}
                          >{`${item?.title} ${item?.speaker_firstname} ${item?.speaker_lastname}`}</option>
                        </>
                      );
                    })}
                  </select>
                  
                  <ul className="flex flex-wrap gap-2">
                    {speakerList?.map((item,index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded w-auto" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14} />
                            </span>
                            <span className="text-[#202223] text-xs">{item?.value}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX size={14} onClick={()=>{removeSpeakerList(item)}}/>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="py-2">
                  <label id="sponsors" className=" text-sm px-1 font-semibold  pb-1">
                    Sponsors 
                    {/* <span className="float-right text-theme-color">
                      Create speaker
                    </span> */}
                  </label>

                  <select
                    type="text"
                    id="sponsors"
                    className="w-full border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e) => {
                      onsponsorSelect(e);
                    }}
                  >
                    <option value="" selected={ sponsorsList.length===0  ? "true" : "false"} disabled hidden >
                      Choose Sponsor
                    </option>
                    {sponsorsList?.map((item,index) => {
                      return (
                        <>
                          <option
                            id={item?._id}
                            key={index}
                          >{`${item?.sponsor_name} `}</option>
                        </>
                      );
                    })}
                  </select>
                  
                  <ul className="flex flex-wrap gap-2">
                    {agendaSponsorList?.map((item) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded" key={item?._id}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14}/>
                            </span>
                            <span className="text-[#202223] text-xs">{item?.value}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX size={14} onClick={()=>{removeSponsorList(item)}}/>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-2">
                  <label className="pt-6 text-sm font-semibold pb-2">Description</label>
                  <textarea
                   rows={"4"}
                    className="w-full border  px-3 py-1 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c] focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    value={state.description}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    maxLength={2000}
                    placeholder="Enter description about session"
                  />
                  <div className="flex justify-between">
                  <span className="text-sm text-gray-500">
                    Provide some details about the session.
                  </span>
                    <span className="bottom-2 text-sm ">
                      {count}/2000
                    </span>

                    
                  </div>
                  
                </div>
                <div className="flex gap-2 mt-4">
                      <p className="text-sm text-[#525252]">Status (Enable/Disable)</p>
                      <div><label class="relative inline-flex items-center me-5 cursor-pointer">
  <input type="checkbox" value="" class="sr-only peer"  onClick={(e)=>{setState({...state,status:e.target.checked?"Active":"Disable"})}}/>
  <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-400 peer-focus:ring-4 peer-focus:ring-white  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
  {/* <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Orange</span> */}
</label></div>
                    </div>
              </form>
            </div>
            <div className="w-1/2  space-y-2">
              <h2 className="pb-2 text-lg">Others</h2>
              <hr />
              <div className="py-1">
                <div className=" flex justify-between">
                <label id="speaker" className=" text-sm px-1 font-semibold  pb-1">
                    Location 
                 
                  </label>
                <span onClick={()=>{setIsLocationModalOpen(true)}} className="text-theme-color font-normal text-sm cursor-pointer">Create Location</span>
                </div>
                
                  

                  <select
                    type="text"
                    id="speaker"
                    className="w-full border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    
                    onChange={(e) => {
                      onRoomSelect(e);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Location
                    </option>
                    {specificEvent?.room?.map((item,index) => {
                      return (
                        <>
                          <option
                            id={index}
                            key={index}
                          >{`${item}`}</option>
                        </>
                      );
                    })}
                  </select>
                  
                  <ul className="inline-block">
                    {roomList?.map((item,index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-1" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser />
                            </span>
                            <span className="text-[#202223]">{item?.value}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX  onClick={()=>{removeInterestList(item)}}/>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="py-1">
                  <div className="flex justify-between">
                  <label id="speaker" className=" text-sm px-1 font-semibold  pb-1">
                    Interests
                    
                  </label>
                  <span onClick={()=>{setIsInterestTagModalOpen(true)}} className="text-theme-color font-normal text-sm cursor-pointer">Create Interest Tag</span>
                  </div>
                  

                  <select
                    type="text"
                    id="speaker"
                    className="w-full border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e)=>{onInterestSelect(e)}}
                    
                  >
                    <option value="" selected disabled hidden>
                      Choose Interest
                    </option>
                    {specificEvent?.interest?.map((item,index) => {
                      return (
                        <>
                          <option
                            id={index}
                            key={index}
                          >{`${item}`}</option>
                        </>
                      );
                    })}
                  </select>
                  <ul className="flex flex-wrap gap-2 mt-1">
                    {interestList?.map((item,index) => {
                      return (
                        <li className="flex items-center bg-[#FF85311A] gap-2 px-2 mt-2 rounded" key={index}>
                          <div className="flex items-center gap-1 py-1 ">
                            <span className="text-theme-color">
                              <FiUser size={14}/>
                            </span>
                            <span className="text-[#202223] text-xs">{item?.value}</span>
                          </div>

                          <span className="text-[#5C5F62] py-1">
                            <FiX  size={14} onClick={()=>{removeInterestList(index)}}/>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  
                </div>
              <div className="flex justify-between">
                <div>
                <label className="text-sm  font-semibold pb-3">
                  Banner Image
                </label>
                <div className=" h-32 mt-1 full border-2 border-dashed rounded-md text-center pt-5">
                  <label
                    htmlFor="choose_banner"
                    
                    className="text-theme-color cursor-pointer  text-sm flex flex-col justify-center items-center h-full"
                  >
                    <span className=" bg-theme-light-color px-2 py-1">
                      {state.image === "" || imageError
                        ? "Choose banner image"
                        : state.image.name}
                    </span>
                    <p className="text-sm p-2 text-gray-400">
                      Accepts, jpg, jpeg , png and webp
                    </p>
                    <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                    </p>
                    <input
                      id="choose_banner"
                      ref={imageInput}
                      type="file"
                      className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                      onChange={(e) => {
                        setState({ ...state, image: e.target.files[0] });
                        onImageChange(e);
                      }}
                    />
                  </label>
                  <span className="text-xs text-red-600 font-normal">
                {imageError ? imageError : ""}
              </span>
              <div className={image === null || imageError ? "hidden" : "relative w-28 h-28"}>
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" 
              onClick={()=> handleDelete()}/>

                <img src={image} alt="banner" className="absolute w-full h-full object-cover"
 />
              </div>
                </div>
                </div>
               
               <div>
               <label className="text-sm  font-semibold mb-3">
                Document
                </label>
                <div className=" h-32 w-[230px] mt-1 border-2 border-dashed rounded-md text-center pt-5">
                  <label
                    htmlFor="choose_document"
                    
                    className="text-theme-color cursor-pointer  text-sm flex flex-col justify-center items-center h-full"
                  >
                    
                    <span className=" bg-theme-light-color px-2 py-1">
                    {state.document === ""|| fileError ? "Choose document" : state.document.name}
                    </span>
                    <p className="text-sm p-2 text-gray-400">
                      Accepts PDFs and PPTs
                    </p>
                    {/* <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                    </p> */}
                    <input
                      id="choose_document"
                      // ref={imageInput}
                      type="file"
                      className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                      onChange={(e) => {
                        onFileChange(e);
                      }}
                    />
                 
                 </label>
              
                </div>
                <span className="text-xs text-red-600 font-normal">
                {fileError ? fileError : ""}
              </span>
               </div>
              </div>
            
            </div>
          </div>
          <div className="pt-5 flex gap-3">
            <Link to={"/agenda-list"}>
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
          </div>

          </div>
          
         
        </div>
      </div>
    </>
  );
};

export default NewSession;
