  import React, { useContext, useEffect } from "react"
  import { Link } from "react-router-dom";
  import { FaArrowLeft } from "react-icons/fa";
  import { DataContext } from "../../context/DataState";
  import { useLocation } from "react-router-dom";


  const AgendaDetails=()=>{
    const {findOneAgendaApi}=useContext(DataContext)
    const location=useLocation()
    const state=location.state

    const dateConversion = (date) => {
      const nDate = new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Calcutta",
      });
      
      return nDate.split(",");
    };
    const timeconversion = (date) => {
      let nDate = String(date);
  
      return nDate.slice(11, 16);
    };

  useEffect(()=>{
    findOneAgendaApi(state._id)
  },[])
  console.log(state,"state")
    return (
      <>
      {/* <div className="flex w-full bg-white">
          <div className="w-full px-7  bg-white overflow-y-scroll h-screen space-y-3 ">
            <div className="flex pt-3">
              <Link to={"/agenda-list"}>
                <div className="w-8 pt-1">
                  <button className="border p-2" >
                    <FaArrowLeft />
                  </button>
                </div>
              </Link>
              <div className="p-2">
                <h1 className=" text-xl px-2 ">Agenda Information </h1>
              </div>
            </div>
            <div className="flex space-x-10 m-0 ">
              <div className="w-1/2 space-y-3 ">
                <h2 className="font-semibold text-lg ">Agenda Details</h2>
                <hr />
                
              </div>
              
            </div>
            <div className="flex flex-col">
            <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-4 w-[85%]">
                    <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Title</h2>
                        <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.agenda_name}`}</p>
                      </div>

                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Date</h2>
                        <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.startDate?.slice(0,10)}`}</p>
                      </div>
                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Timings</h2>
                          <div className="flex align-items-center gap-4">
                        <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.startTime?.slice(0,10)}  -  `}
                        {`${state.endTime?.slice(0,10)}`}</p>
                        </div>
                      </div>
                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Speakers</h2>
                          {
                          state.speaker.length===0 ?   <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p>
                          :  state.speaker?.map((v) => {
                            return(
                              <div className="flex gap-3 mt-3 ">
                                <img src={v.image} alt="speakerPhoto" className="w-10 h-10 rounded-full" />
                                 <p className="text-[#4B4B4B] text-sm font-normal p-2">{v.speaker_firstname + " " + v.speaker_lastname}</p>
                                </div>
                          
                            )
                            })
                        }
                        
                      </div>
                      <div className="mt-2 w-full">
                          <h2 className="text-[#6D7175] text-sm font-medium">Sponsors</h2>
                          {state.sponsor.length === 0 ?  <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p> :  state.sponsor.map((v) => {
                            return(
                              <div className="flex gap-3 mt-3 ">
                                <img src={v.image} alt="speakerPhoto" className="w-10 h-10 rounded-full" />
                                <p className="text-[#4B4B4B] text-sm font-normal p-2">{v.sponsor_name}</p>
                                </div>
                            )
                          }
                         )}
                        </div>
                       
                      
                    
                      </div>
                      
                      
                      

                      <div className="flex flex-col gap-4  w-full">
                      
                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Location</h2>
                          {
                          state.Location===""?   <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p>
                          :  <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.Location}`}</p>
                        }
                      
                      </div>
                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Interests</h2>
                          {state.interest.length === 0 ? 
                          <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p> :  
                          state?.interest?.map((v) => 
                        <p className="text-[#4B4B4B] text-sm font-normal p-2">{v.value}</p>)
                        }</div>
                      <div className="mt-2">
                          <h2 className="text-[#6D7175] text-sm font-medium">Banner Image</h2>
                          {
                            state.image==="" ?     <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p> :
                            <img src={state?.image} alt={state?.image} className="w-40 rounded-md h-40 m-2" />
                          }
                      
                      </div>
                    
                      </div>
                

              </div>
              <div className="mt-2 w-full">
                          <h2 className="text-[#6D7175] text-sm font-medium">Description</h2>
                          {
                          state.description===""?   <p className="text-[#4B4B4B] text-sm font-normal p-2">No data</p>
                          :  <p className="text-[#4B4B4B] text-sm font-normal p-2 text-justify">{`${state.description}`}</p>
                        }
                      
                      </div>
            </div>
          
              
          </div>
        </div> */}
        <div className="flex w-full ">
        <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/agenda-list"}>
              <div className="w-8 pt-1">
                <button className="border p-2" >
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">{`${state.agenda_name}`} </h1>
            </div>
          </div>
          <div className="flex space-x-10 m-0 ">
            
            
          </div>
          <div className="flex flex-col rounded-lg bg-white border-[#D1D9E7] p-3">
          <div className=" flex space-x-10  ">
            <div className="w-1/2 space-y-3">
              
              <div className="">
                <div>
                  <label
                    htmlFor="agenda-name"
                    className="text-sm px-1 font-semibold "
                  >
                  Title
                  </label>
                  <input
                    type="text"
                    id="agenda-name"
                    name="agenda-name"
                    
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 shadow-sm rounded-md text-[#8C9196]  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.agenda_name===undefined ||state.agenda_name==="" ? "" :state.agenda_name}
                  />
                </div>
                <div>
                  <label
                    htmlFor="start-date"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >Date
                  </label>
                  <input
                    id="start-date"
                    type="text"
                    name="start-date"
                   
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.startDate===undefined ||state.startDate==="" ? "No Data" :dateConversion(state.startDate)[0]}
                  />
                </div>
                <div className="flex justify-between">
                <div>
                  <label
                    htmlFor="start-time"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Start at
                  </label>
                  <input
                    id="start-time"
                    type="text"
                    name="start-time"
                    
                    className="w-full border pointer-events-none  py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.startTime===undefined ||state.startTime==="" ? "No Data" :timeconversion(state.startTime)}
                  />
                  
                </div>
                <div>
                  <label
                    htmlFor="end-time"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                   End at
                  </label>
                  <input
                    type="text"
                    name="end-time"
                   
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.endTime===undefined ||state.endTime==="" ? "No Data" :timeconversion(state.endTime)}
                    
                  />
                </div>
                </div>
                
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                   Speakers
                  </label>
                 <div className="flex gap-4 flex-wrap ">
                  {
                    state.speaker.length===0 ? <input  className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={"No Data"} />
                    
                    :state.speaker.map((item)=>{
                      return (
                        <>
                        <div className="flex gap-2 items-center flex-wrap  w-52 rounded bg-[#FAFBFB] px-3 py-2">
                    <img src={item.image} alt="" className="w-8 h-8 rounded "   />
                    <span className="text-sm font-normal text-[#8C9196]">{` ${item.title} ${item.speaker_firstname} ${item.speaker_lastname}`}</span>

                  </div>
                        </>
                      )
                    })
                  }
                  

                 </div>
                </div>
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Sponsors
                  </label>
                 <div className="flex gap-4 flex-wrap ">
                  {
                    state.sponsor.length===0 ? <input  className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={"No Data"} />
                    
                    :state.speaker.map((item)=>{
                      return (
                        <>
                        <div className="flex gap-2 items-center flex-wrap  w-52 rounded bg-[#FAFBFB] px-3 py-2">
                    <img src={item.image} alt="" className="w-8 h-8 rounded "   />
                    <span className="text-sm font-normal text-[#8C9196]">{` ${item.sponsor_name}`}</span>

                  </div>
                        </>
                      )
                    })
                  }
                  

                 </div>
                </div>
                <div className="mt-2 relative">
                <label
                  htmlFor="description"
                  className="text-sm pt-4 font-semibold pb-1"
                >
                 Description
                </label>
                <textarea
                  rows="7"
                  id="description"
                  name="description"
                  className="w-full border pointer-events-none py-1 px-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                  value={state.description===undefined ||state.description==="" ? "No Data" :state.description}
                  
                />
               
              </div>
                
                
               
               
              

              </div>
            </div>
            <div className="w-1/2 ">
              
              
              <div>
                <label
                  htmlFor="location"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                 Location
                </label>
                <input
                  id="location"
                  type="email"
                  name="location"ˀ
                 
                  className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.Location===undefined ||state.Location==="" ? "No Data" :state.Location}
                  
                />
                
              </div>
              
              <div>
                  <label
                    htmlFor="facebook-profile"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                   Interest Tag
                  </label>
                  <div className="flex gap-4 flex-wrap mt-2">
                  {
                   state.interest.length==0 ? <span className="text-[#8C9196] text-sm">"No Data"</span>:state.interest.map((element)=>{
                    return(
                     
                 
                   <span className="text-sm font-normal bg-[#FAFBFB] p-2 text-[#8C9196]">{` ${element.value}`}</span>

                

                    )
                   }) 
                  }
                  </div>
                 
                  
                 
                </div>

             
              <div className="">
                <p className="text-sm pt-2  font-semibold pb-1">
                  Banner Image
                </p>
                <div>
               {state.image===undefined ||state.image==="" ? <span className="text-[#8C9196] text-sm">No Data</span>: <img src={state.image} alt=""  className="w-full h-44 rounded"/>}
                 
                </div>
                
                
              </div>

              
             
             
            </div>
            
            
          </div>
          </div>
         
            
        </div>
      </div>
      </>
    )
  }
  export default AgendaDetails;