import React, { useEffect, useState } from "react";
import fetchUtil from "../../utils/fetch.util";
import { ADD_USER_ROLE, UPDATE_USER_ROLE } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";

var role_permissions = [
  { label: "Speaker", value: "speaker" },
  { label: "Sponsor", value: "sponsors" },
  { label: "Attendee", value: "attendee" },
  { label: "Agenda", value: "agenda" },
  { label: "Announcement", value: "announcement" },
  { label: "Exhibitors", value: "exhibitor" },
  { label: "Polls", value: "polls" },
  { label: "Contacts", value: "contacts" },
  { label: "Faq", value: "faq" },
  { label: "Registration", value: "registration" },
  { label: "Sponsor Level", value: "sponsorLevel" },
  // { label: "Contact List", value: "contactList" },
];

const NewRole = ({ isOpen, onClose, userRoleInfo }) => {
  const eventID = JSON.parse(localStorage.getItem("eventId")) || "";

  const initialState = {
    roleName: "",
    permission: {},
  };
  const [roleInfo, setRoleInfo] = useState(initialState);

  useEffect(() => {
    if (!!userRoleInfo) {
      setRoleInfo((prev) => ({
        ...prev,
        roleName: userRoleInfo?.roleName,
        permission: userRoleInfo?.permission,
      }));
    }
  }, [userRoleInfo]);

  const handleRoleName = (e) => {
    setRoleInfo((prev) => ({ ...prev, roleName: e.target.value }));
  };

  const handleChecbox = (e, item) => {
    const { name, checked } = e.target; // Extract name and checked values
    setRoleInfo((prev) => {
      const updatedPermission = { ...prev.permission };

      // Initialize the object for the item if not already set
      if (!updatedPermission[item.value]) {
        updatedPermission[item.value] = {
          read: false,
          write: false,
          create: false,
        };
      }

      // Update the current checkbox value
      updatedPermission[item.value][name] = checked;

      // Cascade logic when a checkbox is **checked**
      if (name === "create" && checked) {
        updatedPermission[item.value].write = true;
        updatedPermission[item.value].read = true;
      } else if (name === "write" && checked) {
        updatedPermission[item.value].read = true;
      }

      // Return the updated state
      return {
        ...prev,
        permission: updatedPermission,
      };
    });
  };

  const handleRoleInformation = () => {
    if (!!userRoleInfo) {
      fetchUtil
        .PUT(`${UPDATE_USER_ROLE}/${userRoleInfo._id}`, roleInfo)
        .then((resp) => {
          console.log(resp);
          toast.success("Role has been updated");
          setTimeout(() => {
            onClose();
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        });
    } else {
      fetchUtil
        .POST(`${ADD_USER_ROLE}/${eventID}`, roleInfo)
        .then((resp) => {
          console.log(resp);
          toast.success("Role has been created");
          setTimeout(() => {
            onClose();
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        });
    }
  };

  const handleClose = () => {
    setRoleInfo(initialState);
    onClose();
  };
  const isFormValid = () => {
    return (
      roleInfo.roleName?.trim() !== "" &&
      Object.values(roleInfo.permission).some((permission) =>
        Object.values(permission).some((checked) => checked)
      )
    );
  };

  return (
    <>
      {!!isOpen && (
        <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-full z-[1] bg-[#00000090] ">
          <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
            <div className="bg-white w-[750px] p-7 rounded-lg z-10 ">
              <h2 className=" font-semibold text-xl pb-4">User Roles</h2>
              <hr />
              <div className="p-4">
                <div className="mb-3 pt-4 ">
                  <label
                    className="block text-sm text-[#202223] mb-1 font-semibold"
                    htmlFor="role_name"
                  >
                    Role Name
                  </label>
                  <input
                    id="role_name"
                    name="roleName"
                    value={roleInfo?.roleName}
                    className="block w-full px-3 py-2 bg-white border border-[#AEB4B9]  rounded-md text-sm focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Administration"
                    onChange={handleRoleName}
                  />
                </div>
                <div className="mb-3">
                  <div className="overflow-scroll scrollbar-none text-[15px] h-60 ">
                    <h2 className="font-semibold py-5 ">Role Permission</h2>
                    {/* <div className="flex gap-10 content-stretch justify-stretch space-x-10 py-3 border-b border-dashed">
                      <p>Adminsitration access</p>
                      <div className=" flex space-x-2 gap-2">
                        <input type="checkbox" className="checked:bg-theme-color " />
                        Select all
                      </div>
                    </div> */}
                    {role_permissions?.map((item) => (
                      <div className="flex content-stretch justify-stretch space-x-10 py-3 border-b w-full border-dashed">
                        <p className="w-48">{item?.label}</p>
                        <div className=" flex space-x-8 content-stretch">
                          <div>
                            <input
                              name="read"
                              type="checkbox"
                              checked={roleInfo?.permission[item.value]?.read}
                              onChange={(e) => handleChecbox(e, item)}
                            />
                            <label className="px-2">Read</label>
                          </div>
                          <div>
                            <input
                              name="write"
                              type="checkbox"
                              checked={roleInfo?.permission[item.value]?.write}
                              onChange={(e) => handleChecbox(e, item)}
                            />
                            <label className="px-2">Write</label>
                          </div>
                          <div>
                            <input
                              name="create"
                              type="checkbox"
                              checked={roleInfo?.permission[item.value]?.create}
                              onChange={(e) => handleChecbox(e, item)}
                            />
                            <label className="px-2">Create</label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-between gap-2 pt-2">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="hover:bg-gray-300 border font-medium border-[#BABFC3] text-[#202223] hover:text-white p-2 w-48 rounded text-sm"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    disabled={!isFormValid()}
                    className="bg-theme-color text-white p-2 w-48  font-medium rounded text-sm disabled:opacity-40"
                    onClick={handleRoleInformation}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(NewRole);
