import React, { useRef, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'

const LineChart = ({ chartData }) => {

    


    return (
  <div className='chart-container'>
 <Line data={chartData} />
  </div>
    )
    
   
}
export default LineChart;