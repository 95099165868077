import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";
import ImageCropper from "../ImageCropper";

const AddSpeaker = () => {
  const {
    AddSpeakerApi,
    getSpeakersApi,
    speakers,
    getCountryApi,
    countryList,
    disableSave,
    isSpinner
  } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [isValid, setIsValid] = useState(true);

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    title: "",
    organization: "",
    position: "",
    status: "",
    country: "",
    biography: "",
    image: "",
    instagram_profile: "",
    facebook_profile: "",
    linkedln_profile: "",
    twitter_profile: "",
    email:""
  });

  const [errors, setErrors] = useState({
    firstName: true,
    lastName: true,
    position:true,
    organization:true,
    
  });
  const [instagramError,setInstagramError]= useState(false);
  const [linkedlnError,setLinkedlnError]= useState(false);
  const [twitterError,setTwitterError]= useState(false);
  const [facebookError,setFacebookError]= useState(false);

  const validUrl= new RegExp(/^https?:\/\/(www\.)?([^\s/?\.#]+\.?)+(\/[^\s]*)?(\.[a-zA-Z]{2,})?$/);
  const imageInput=useRef()

    // const validUrl = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/); 
    const validateUrl = (url,name) =>{
      if(name=="instagram"){
        if(!url.test(state.instagram_profile) && state.instagram_profile !=="") {
          setInstagramError(true);
        } 
        else {
          setInstagramError(false);
        }
      }
   if(name=="linkedin"){
    if(!url.test(state.linkedln_profile) && state.linkedln_profile !=="") {
      setLinkedlnError(true);
    } 
    else {
      setLinkedlnError(false);
    }
   }
  if(name=="twitter"){
    if(!url.test(state.twitter_profile) && state.twitter_profile !=="") {
      setTwitterError(true);
    } 
    else {
      setTwitterError(false);
    }
  }
  if(name=="facebook"){
    if(!url.test(state.facebook_profile) && state.facebook_profile !=="") {
      setFacebookError(true);
    } 
    else {
      setFacebookError(false);
    }
  }

  };

  const handleChange = (e) => {
    setState({ ...state, biography: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();

        reader.addEventListener('load', () =>
            setImageToCrop(reader.result)
        );

        reader.readAsDataURL(event.target.files[0]);
    }
};

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      // const reader = new FileReader();

      //       reader.addEventListener('load', () =>
      //           setImageToCrop(reader.result)
      //       );

      //       reader.readAsDataURL(event.target.files[0]);
    }

    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(jpeg|jpg|png|webp)$/)
    ) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false);
    }
  };

  const handleDelete = ()=>{
    setState({...state, image: ""});
    setImage(null)
    imageInput.current.value = "";
    
  }
 
  const buttonDisabled =
    state.firstname !== "" &&
    state.lastname !== "" &&
    state.title !== "" &&
    state.organization !== "" &&
    state.position !== "" &&
    state.country !== "" &&
    state.image !== "" &&
    state.biography !== ""&&
    state.email!=="" 
    &&
    !instagramError &&
    !linkedlnError
     && !twitterError && !facebookError 
     && errors.firstName
      && errors.lastName && errors.position


  const handleSubmit = () => {
   if(!isSpinner) AddSpeakerApi(state);
  };

  useEffect(() => {
    getSpeakersApi();
    getCountryApi();
    speakers?.filter((element) => {
      return;
    });
  }, []);
console.log(buttonDisabled,"disable")
console.log(disableSave,"disable Save")
  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/speaker_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                Speaker info{" "}
              </h1>
            </div>
          </div>
          <div>
          
          </div>
          <div className="flex flex-col rounded-lg bg-white border-[#D1D9E7] p-3">
          <div className=" flex space-x-10  ">
            <div className="w-1/2 space-y-3">
              <h2 className="font-semibold text-lg">Speaker Details</h2>
              <hr />
              <div className="">
                <div>
                  <label
                    htmlFor="first-name"
                    className="text-sm px-1 font-semibold "
                  >
                    First Name *
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstname"
                    placeholder="Enter your first name"
                    className="w-full border py-1 capitalize px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.firstname}
                    onChange={(e) => {
                      const isValidInput =  /^[a-zA-Z\s]+$/.test(e.target.value);
                      setState({ ...state, firstname: e.target.value });
                     setErrors({...errors,firstName:isValidInput})
                    }}
                  />
                   {!errors.firstName && state.firstname!="" && (
        <p className=" text-xs text-red-600" id="email-error">
          Please enter only alphabetical characters.
        </p>
      )}
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Last Name *
                  </label>
                  <input
                    id="last-name"
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name"
                    className="w-full border py-1 capitalize px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.lastname}
                    onChange={(e) => {
                      const isValidInput =  /^[a-zA-Z\s]+$/.test(e.target.value);
                      setState({ ...state, lastname: e.target.value });
                      setErrors({...errors,lastName:isValidInput});
                    }}
                  />
                   {!errors.lastName && state.lastname!="" &&(
        <p className=" text-xs text-red-600" id="email-error">
          Please enter only alphabetical characters.
        </p>
      )}
                 
                </div>
                <div>
                  <label
                    htmlFor="title"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Title *
                  </label>
                  <select
                    id="title"
                    type="text"
                    name="title"
                    placeholder="Title"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.title}
                    onChange={(e) => {
                      setState({ ...state, title: e.target.value });
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Title
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Organization *
                  </label>
                  <input
                    type="text"
                    name="organization"
                    placeholder="Organization"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.organization}
                    onChange={(e) => {
                     
                      const isValidInput =  /^[a-zA-Z\s]+$/.test(e.target.value);
                      setState({ ...state, organization: e.target.value });
                     setErrors({...errors,organization:isValidInput})
                    }}
                  />
                   {!errors.organization && state.organization!="" && (
        <p className=" text-xs text-red-600" id="email-error">
          Please enter only alphabetical characters.
        </p>
      )}
                </div>
                <div>
                  <label
                    htmlFor="position"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Position *
                  </label>
                  <input
                    type="text"
                    id="position"
                    name="position"
                    placeholder="Position"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.position}
                    onChange={(e) => {
                      const isValidInput = /^[a-zA-Z\s,&]+$/.test(e.target.value);
                      // const isValidInput =  /^[a-zA-Z\s]+$/.test(e.target.value);
                      setState({ ...state, position: e.target.value });
                      setErrors({...errors,position:isValidInput});
                    }}
                  />
                   {!errors.position && state.position!="" &&(
        <p className=" text-xs text-red-600" id="email-error">
          Please enter only alphabetical characters.
        </p>
      )}
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Country *
                  </label>
                  <select
                    id="country"
                    type="text"
                    name="country"

                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    // value={state.title}
                    onChange={(e) => {
                      setState({ ...state, country: e.target.value });
                    }}
                  >
                    <option value="" selected disabled>
                      Choose Country
                    </option>
                    {countryList.map((item,index) => {
                      return (
                        <>
                          <option value={item?.name} key={index}>{item?.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="instagram"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Instagram Url
                  </label>
                  <input
                    id="instagram"
                    type="text"
                    name="instagram"
                    placeholder="www.instagram.com"
                    className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    // value={state.instagram_profile}
                    onChange={(e) => {
                      setState({ ...state, instagram_profile: e.target.value });
                    }}
                   

                    onKeyUp={(e) =>{ 
                      const instagramRegex = /^(https?:\/\/)?(www\.)?instagram.com(\/[a-zA-Z0-9_.-]+\/?)?$/;
                      validateUrl(instagramRegex,"instagram")
                    }}
                  />
                  {instagramError && (
                  <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                )}
                </div>
                <div className="mt-3">
                <label
                  htmlFor="linkedln"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Linkedln Url
                </label>
                <input
                  id="linkedln"
                  type="text"
                  name="linkedln"
                  placeholder="www.linkedln.com"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  // value={state.linkedln_profile}
                  onChange={(e) => {
                    setState({ ...state, linkedln_profile: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    
                    const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin.com(\/[a-zA-Z]+\/?)(\/[a-zA-Z0-9_.-]+\/?)?$/;
                    validateUrl(linkedInRegex,"linkedin")}}
                />
                {linkedlnError && (
                  <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                )}
              </div>
              <div className="mt-3">
                <label
                  htmlFor="facebook"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Facebook Url
                </label>
                <input
                  id="facebook"
                  type="text"
                  name="facebook"
                  placeholder="www.facebook.com"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  // value={state.facebook_profile}
                  onChange={(e) => {
                    setState({ ...state, facebook_profile: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    const facebookRegex =/^(https?:\/\/)?(www\.)?facebook.com(\/[a-zA-Z0-9_.-]+\/?)?$/;
                   
                    validateUrl(facebookRegex,"facebook")}}
                />
                {facebookError && (
                  <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                )}
              </div>

              </div>
            </div>
            <div className="w-1/2 pt-14">
              
              
              <div>
                <label
                  htmlFor="twitter"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Twitter Url
                </label>
                <input
                  id="twitter"
                  type="text"
                  name="twitter"ˀ
                  placeholder="www.twitter.com"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  // value={state.twitter_profile}
                  onChange={(e) => {
                    setState({ ...state, twitter_profile: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    const twitterRegex = /^(https?:\/\/)?(www\.)?twitter.com(\/[a-zA-Z0-9_.-]+\/?)?$/;
                    validateUrl(twitterRegex,"twitter")
                  }}
                />
                {twitterError && (
                  <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                )}
              </div>

              <div className="mt-3">
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Email *
                  </label>
                  <input
                    id="email"
                   type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.email}
                    onChange={(e) => {
                      setState({ ...state, email: e.target.value });
                    }}
                  />
                </div>
              <div className="">
                <p className="text-sm pt-2 font-semibold pb-1">
                  Speaker Photo *{" "}
                </p>
                <div className="w-full h-28 border-2 border-dashed rounded-md text-center flex flex-col  items-center justify-center">
                  <label
                    htmlFor="choose_banner"
                    // accept="image/png, image/gif, image/jpeg, image/svg"
                    className="text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs"
                  >
                    {state.image == "" || imageError
                      ? "Choose File"
                      : state.image.name}
                    <input
                      id="choose_banner"
                      ref={imageInput}
                      type="file"
                      className=" text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md"
                      onChange={(e) => {
                        setState({ ...state, image: e.target.files[0] });
                       onUploadFile(e)
                      }}
                    />
                  </label>

                  <p className="text-sm pt-1 text-gray-400 ">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                    </p>
                </div>
                {/* <div>
                <ImageCropper
                    imageToCrop={imageToCrop}
                    onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                />
            </div> */}
            {/* {
                imageToCrop &&
                <div>
                    <h2>Cropped Image</h2>
                    <img
                        alt="Cropped Image"
                        src={imageToCrop}
                    />
                </div>
            } */}
                <span className="text-xs text-red-600 font-normal">
                  {imageError ? imageError : ""}
                </span>
                <div className={image === null || imageError ? "hidden" : "relative w-28 h-28 mt-4"}>
                <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" 
              onClick={()=> handleDelete()}/>

                  <img
                    src={image}
                    alt="banner"
                    className="absolute w-full h-full object-cover"
                  />
                </div>
              </div>

              <div className="mt-4 relative">
                <label
                  htmlFor="biography"
                  className="text-sm pt-4 font-semibold pb-1"
                >
                  Biography *
                </label>
                <textarea
                  rows="4"
                  id="biography"
                  name="biography"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                  value={state.biography}
                  maxLength={2000}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500 items-center">
                    Provide some details about the speaker's background,
                    achievements and presentation goals.
                  </span>
                  <span className=" bottom-1 text-xs">{count}/2000</span>
                </div>
              </div>
             
             
            </div>
            
            
          </div>

          <div className="mt-3 flex gap-2">
            <Link to="/speaker_list">
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>

           
  {/* <button type="button"
  
   disabled={disableSave ? buttonDisabled : !buttonDisabled}
   onClick={() => {
    handleSubmit();
  }}
  className="flex items-center  bg-theme-color  gap-1 disabled:opacity-50 text-sm  rounded hover:bg-orange-400  ml-3 px-4 py-2 text-white">
  <span><svg class="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg></span>
    
    <span class="font-medium"> Save </span>
  </button> */}

           
<button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
          </div>
          </div>
          
          
        </div>
      </div>
    </>
  );
};

export default AddSpeaker;
