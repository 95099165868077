import React, { useContext, useEffect } from "react"
import { Link, useLocation} from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

import { FaCrown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";




const DateAndTime=()=>{
  const { getSponsorDateAndTime, sponsorTime,getExhibitorDateAndTime}=useContext(DataContext);
  const location=useLocation();
  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const timeconversion = (date) => {
    let nDate = String(date);

    return nDate.slice(11, 16);
  };
  const {sponsor_id,clicks,user_id}=location?.state;
console.log(location.state.user_id,"id")
   useEffect(()=>{
    if(location.state.module=="exhibitors")
    {
      getExhibitorDateAndTime(sponsor_id,clicks,user_id) 
    }
    else
    {
      getSponsorDateAndTime(sponsor_id,clicks,user_id)
    }
   
   },[])
  return (
    <>
    <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={location.state.module=="exhibitors"? "/exhibitorsoverview-view" :"/sponsors-view"} state={{item:location.state.item}}>
            <div className="w-8 pt-1">
             
              <button className="border p-2">
                <FaArrowLeft />
              </button>
             
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">{location.state.name} </h1>
          </div>
        </div>
       
        
        <div className="bg-white w-1/2 rounded-t mt-3  pb-4 px-3">
                <h1 className="text-[#141824] font-semibold text-base p-3">{clicks=="websiteClicks" ? "Website Clicks " : clicks=="profileVisits" ?"Profile Visits" :"Banner Clicks"}</h1>
                      <table className=" table-auto bg-white text-start  shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[15%] px-4 py-2 rounded-tl relative">
                           Date
                            </th>

                            <th className="w-[15%] rounded-tr px-4 py-2 ">
                        Time
                            </th>
                           
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                         
                               {
                                sponsorTime?.map((item)=>{
                                  return (
                                    <>
                                     <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="py-2 px-4 rounded-bl">
                                {dateConversion(item?.createdAt)[0]}
                                </td>

                                <td className=" py-2 px-4 rounded-br"> {item?.createdAt !== undefined
                              ? timeconversion(item?.createdAt)
                              : ""}</td>
                                </tr>
                                    </>
                                  )
                                })
                               }
                               
                                
                            
                          
                            
                              
                            
                        
                        </tbody>
                      </table>
                    </div>
                    
                  
        
      
      </div>
    </>
  )
}
export default DateAndTime;