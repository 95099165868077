import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";

const NoEnquiriesModal = () => {
  return (
    <>
      <div className="flex w-full bg-white mt-4">
        <div className="w-full  p-8  bg-white overflow-y-scroll h-[35vh] rounded  ">
          <div className="flex flex-col gap-2 items-center justify-center mt-7">
            <h1 className="text-[#202223] text-xl font-semibold ">
              No Enquiries Available
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
export default NoEnquiriesModal;
