import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import dummyImage from "../Assets/img2.jpg";
import { FaCrown } from "react-icons/fa";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { AiOutlineEye } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import { AiOutlineLike } from "react-icons/ai";
import { BiComment } from "react-icons/bi";
import { IoShareSocialOutline } from "react-icons/io5"
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const AttendeeOverviewViewPage = () => {
  const text="Our opening keynote will provide an overview of the rapid advances being made in artificial intelligence and machine learning. Our speaker will discuss how AI is transforming major industries, the ethical implications, and what the future may look like in a world of advanced AI systems. Expect a thoughtful and engaging discussion about the promise and perils of this technology."
  const { getSingleAttendeeReportApi, singleAttendeeReport, globalLoader,}=useContext(DataContext)
  const[sortedArray,setSortedArray]=useState([])
  const location=useLocation()
const attendee=location.state.item||location.state
const id=attendee._id
const dateConversion = (date) => {
  const nDate = new Date(date).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });

  return nDate.split(",");
};
TimeAgo.addDefaultLocale(en)
  const timeAgo = new TimeAgo('en-US')
const timeconversion = (date) => {
  let nDate = String(date);

  return nDate.slice(11, 16);
};
// const newArray=singleAttendeeReport?.leaderBoards?.map((item)=>{
//   return item.score;
// })
// console.log(newArray,"newArray")
function arrangeScores(leaderBoards)
{
  
}


  useEffect(()=>{
getSingleAttendeeReportApi(id)
  },[])
  useEffect(()=>{
    let newArray=singleAttendeeReport?.leaderBoards?.map((item)=>{
      return item.score
    })
    setSortedArray(newArray?.sort((a,b)=>{
      return b-a
    }))
    
  },[singleAttendeeReport])

  return (
    <>
      <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={`${location.state.item ? "/attendees":"/reports"}`}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">User Information </h1>
          </div>
        </div>
        {globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
        <div className="flex gap-5 bg-white">
          <div className=" w-1/2  m-0 bg-white p-3 rounded">
            <div className=" space-y-3 ">
              <h2 className="font-semibold text-lg ">User details</h2>
              <hr className=" border-1 border-[#BDBDBD]" />
            </div>

            <div className=" w-full mt-5 flex gap-3">
              <div className="text-[#202223]  w-[20%] ">
                <p className="text-sm  font-medium">User Photo</p>
                <img src={singleAttendeeReport?.findAttendee && singleAttendeeReport?.findAttendee?.image} alt="user-photo" className="w-14 h-14" />
              </div>
              <div className="text-[#202223] w-[35%]  ">
                <p className="text-sm  font-medium">First name </p>
                <p className="border outline-none px-2 py-2  rounded  text-sm font-normal mt-2 border-[#AEB4B9] w-full">
                  {singleAttendeeReport?.findAttendee && singleAttendeeReport.findAttendee?.Name?.split(" ").length>1 ?  singleAttendeeReport?.findAttendee?.Name?.split(" ").slice(0,-1).join(" ") : singleAttendeeReport?.findAttendee?.Name}
                </p>
              </div>
              <div className="text-[#202223] w-[35%] ">
                <p className="text-sm font-medium">Last name </p>
                <p className="border px-2 py-2 outline-none rounded text-sm font-normal mt-2 border-[#AEB4B9] w-full">
                {singleAttendeeReport?.findAttendee && singleAttendeeReport.findAttendee?.Name?.split(" ").length>1 ? singleAttendeeReport?.findAttendee?.Name?.split(" ").slice(-1)[0] :"No Data"}
                </p>
              </div>
            </div>

            <div className=" w-full mt-5 flex gap-3">
              <div className="text-[#202223] flex-1 w-full ">
                <p className="text-sm font-medium">Email address </p>
                <p className="border outline-none text-sm font-normal mt-2 px-2 py-2 rounded border-[#AEB4B9] w-full">
                {singleAttendeeReport?.findAttendee  && singleAttendeeReport?.findAttendee?.email}
                </p>
              </div>
              <div className="text-[#202223] flex-1 w-full">
                <p className="text-sm font-medium">Organization</p>
                <p className="border outline-none text-sm font-normal mt-2 rounded px-2 py-2 border-[#AEB4B9] w-full">
                {singleAttendeeReport?.findAttendee  &&  singleAttendeeReport?.findAttendee?.organization}
                </p>
              </div>
            </div>
          </div>

          <div className=" w-1/2  m-0 bg-white p-3 rounded">
            <div className="text-[#202223] flex-1 w-full mt-[52px]">
              <p className="text-sm font-medium pt-2">Position</p>
              <p className="border outline-none text-sm font-normal mt-2 rounded px-2 py-2 border-[#AEB4B9] w-full">
              
          
              {singleAttendeeReport?.findAttendee  &&  singleAttendeeReport?.findAttendee?.designation}
               
              </p>
            </div>

            <div className="text-[#202223]  w-full mt-4 ">
            <label
              className="block relative mb-2 text-sm"
              typeof="description"
              htmlFor="description"
            >
             Biography 
            </label>
            <textarea
            value= {singleAttendeeReport?.findAttendee  && singleAttendeeReport?.findAttendee?.biography!="" ?singleAttendeeReport?.findAttendee?.biography :"No Data"}
             
              rows="4"

              className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-[#AEB4B9] "
              
              
             
            />
            
          </div>
          </div>
        </div>
        </>
        )}

        <div className="bg-white rounded-t mt-3 px-3">
          <h1 className="text-[#141824] font-semibold text-base p-3">
          Agenda Attending
          </h1>
          <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[20%] px-4 py-2 rounded relative">
                 Agenda Name
                </th>
                <th className="w-[15%] px-4 py-2 ">Speakers</th>
                <th className="w-[15%] px-4 py-2 ">Date</th>
                <th className="w-[20%] px-4 py-2 ">Start Time</th>
                <th className="w-[15%] px-4 py-2">End Time</th>
                <th className="w-[12%] px-4 py-2 relative">Check In</th>
                <th className="w-[6%] px-4 py-2 relative">My schedule</th>
                {/* <th className="w-[7%] px-4 py-2 relative">Action</th> */}
                

              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {
                singleAttendeeReport?.findAgendas && singleAttendeeReport?.findAgendas.length==0?  <tr className="border w-full  flex justify-center">
               No data available
            </tr>:
                singleAttendeeReport?.findAgendas && singleAttendeeReport?.findAgendas?.map((item)=>{
                  return(
                    <>
                    
                     <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                <td className="py-2 px-4">{item?.agenda_name}</td>
                <td className="py-2 px-4">
                <div className="flex items-center">
                              {item?.speaker
                                .slice(0, 3)
                                .map((element, index) => {
                                  return (
                                    <div
                                      className="relative right-2 "
                                      key={index}
                                    >
                                      <img
                                        src={element?.image}
                                        className="w-10 h-10  rounded-full object-fill speaker profile"
                                        alt=""
                                      />
                                    </div>
                                  );
                                })}

                              {item.speaker.length >= 4 && (
                                <span className="text-xs whitespace-nowrap text-[#202223]  font-medium">{`+ ${
                                  item?.speaker.length - 3
                                } more`}</span>
                              )}
                            </div>
                </td>

                <td className=" py-2 px-4"> {dateConversion(item?.startDate)[0]}</td>

                <td className=" py-2 px-4"> {item?.startTime !== undefined
                              ? timeconversion(item?.startTime)
                              : ""}</td>
                <td className=" py-2 px-4">{item?.endTime !== undefined
                              ? timeconversion(item?.endTime)
                              : ""}</td>
               <td className= {`${item.attendee_id.length>0 ? "underline" :""} text-theme-color  `}>
                              <Link to={`${item.attendee_id.length>0 ? "/checkin" :""}`}state={{item:item}} className="flex items-center justify-center pr-6">
                              <span>{item.attendee_id.length}</span>
                              </Link>
                             
                            </td>
                        

                <td className=" py-2 px-4 text-center">33</td>
                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link>
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}

                
              </tr>
                    </>
                  )
                })
                 
              }
             
                 
                
            </tbody>
          </table>
        </div>
        <div className="bg-white rounded-t mt-3 px-3">
          <h1 className="text-[#141824] font-semibold text-base p-3">
          Comments
          </h1>
          <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[20%] px-4 py-2 rounded relative">
                 Agenda Name
                </th>
                <th className="w-[65%%] px-4 py-2 ">Comment</th>
                <th className="w-[20%] px-4 py-2 ">Time</th>
                
                

              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {
                singleAttendeeReport?.findAgendas?.map((item)=>{
                  return (
                    <>
                    <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                <td className="py-2 px-4">{item?.agenda_name}</td>
               

               {
                  item?.attendeeComment?.length==0? <span className="text sm flex items-center">No comments</span>:
                item?.attendeeComment?.map((element)=>{
                  return (
                    <>
                    <tr className="w-full col-span-2 flex justify-between gap-6">
                      
                    <td className=" py-2 px-4">{element?.Comment} </td>
               
               <td className=" py-2 px-4">{(element?.date)=== undefined ? " ": `${dateConversion(element?.date)[0]} | ${timeconversion(element?.date)}`  }</td>

                    </tr>
                    
                   
                    
                    </>
                  )
                })
               }

               
               

               
                

                
              </tr>
                    </>
                  )
                })
              }
              
            </tbody>
          </table>
        </div>
        <div className="bg-white rounded-t mt-3 px-3">
          <h1 className="text-[#141824] font-semibold text-base p-3">
          Polls Attended
          </h1>
          <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[60%] px-4 py-2 rounded relative">
                Question
                </th>
              
                <th className="w-[15%] px-4 py-2 ">Time</th>
                <th className="w-[10%] px-4 py-2 ">Correct Answer</th>
                {/* <th className="w-[7%] px-4 py-2 ">Action</th> */}
                
                

              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {
                singleAttendeeReport?.pollsAttendeds?.map((item)=>{
                  return (
                    <>
                    <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                <td className="py-2 px-4">{item?.pollText}</td>
               

               

               
                <td className=" py-2 px-4">{(item?.created)=== undefined ? " ": `${dateConversion(item?.created)[0]} | ${timeconversion(item?.created)}`  }</td>
                <td className=" py-2 px-4">yes</td>

                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link>
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}
                

                
              </tr>
                    </>
                  )
                })
              }
              
            </tbody>
          </table>
        </div>
        <div className="flex gap-4 ">
        <div className="bg-white h-auto mb-4 w-[35vw] rounded-t mt-3 px-3">
          <h1 className="text-[#141824] text-base font-semibold p-3">Social Wall</h1>
          {
            singleAttendeeReport?.socialWalls?.map((item)=>{
              return (
                <>
                <div>
          <div className="flex  justify-between items-center gap-4 mt-2 px-6">
            <div className="flex gap-4 items-center">
            <img src={dummyImage} alt="sociwall" className="w-14 h-14 rounded-full" />
            <div className="flex flex-col">
              <h1 className="text-sm fontsemibold">{item?.attendee_id?.Name}</h1>
              <span className="text-[#747474] text-[13px] font-normal">{timeAgo.format(Date.now() - (Date.now() - Date.parse(item?.createdAt)), 'round')
                          }</span>
            </div>
            </div>
            <FiMoreHorizontal className="text-[#868EAE]" size={20}/>
           
          </div>
          <hr className="text-[#747474] mx-6 mt-3"/>
          
          {
            item?.image.length>0 &&  <div className="flex flex-wrap gap-4 mt-3 mx-6">
            {
              item.image.map(element=>{
                return (<img src={element} alt=" " className="rounded-md w-44 h-28"></img>)
              })
            }

          </div>
          }
          {
            item?.caption && <p className="text-[#404040] text-sm font-normal mt-3 px-6">{item?.caption}</p>
          }
<hr className="text-[#747474] mx-6 mt-3"/>
<div>
          <div className="flex gap-7 mx-6 mt-4">
            <div className="flex gap-2 items-center ">
            <AiOutlineLike className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">{item?.likes.length}</span>
            </div>

            <div className="flex gap-2 items-center ">
            <BiComment className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">{item?.comments.length}</span>
            </div>
            <div className="flex gap-2 items-center ">
            <IoShareSocialOutline className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">Share</span>
            </div>
          
            
          </div>
          <hr className="text-[#747474] mx-6 mt-6"/>
        </div>
          </div>
                </>
              )
            })
          }
          

          {/* <div className="mt-7">
          <div className="flex  justify-between items-center gap-4 mt-2 px-6">
            <div className="flex gap-4 items-center">
            <img src={dummyImage} alt="sociwall-picture" className="w-14 h-14 rounded-full" />
            <div className="flex flex-col">
              <h1 className="text-sm fontsemibold">Meryl carles</h1>
              <span className="text-[#747474] text-[13px] font-normal">18 min ago</span>
            </div>
            </div>
            <FiMoreHorizontal className="text-[#868EAE]" size={20}/>
           
          </div>
          <hr className="text-[#747474] mx-6 mt-3"/>
          <div className="flex flex-wrap gap-4 mt-3 mx-6">
            {
              Array.from("abcdef").map(item=>{
                return (<img src={dummyImage} className="rounded-md w-44 h-28"></img>)
              })
            }

          </div>
<hr className="text-[#747474] mx-6 mt-3"/>
<div>
          <div className="flex gap-7 mx-6 mt-4">
            <div className="flex gap-2 items-center ">
            <AiOutlineLike className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">14</span>
            </div>

            <div className="flex gap-2 items-center ">
            <BiComment className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">6</span>
            </div>
            <div className="flex gap-2 items-center ">
            <IoShareSocialOutline className="text-[#4B4B4B]"/>
            <span className="text-[#4B4B4B] font-normal text-sm">Share</span>
            </div>
          
            
          </div>
          <hr className="text-[#747474] mx-6 mt-6"/>
        </div>
          </div> */}
          

        
         
        </div>

        <div className=" w-[30vw] h-32  bg-white rounded-t mt-3 px-3">
        <h1 className="text-[#141824] font-semibold text-base p-3">
          Leaderboard
          </h1>
          <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[20%] px-4 py-2 rounded relative">
                Rank
                </th>
                <th className="w-[15%] px-4 py-2 ">Points</th>
                
                

              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
             
            {
              singleAttendeeReport?.leaderBoards?.map((item)=>{
                return (
                  <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                  <td className="py-2 px-4">{`${sortedArray?.indexOf(item?.score)+1}`}</td>
                  <td className="py-2 px-4">{item?.score}</td>
  
                 
  
                 
  
                  
  
                  
                </tr>

                )
              })
            }
                 
                
              
            </tbody>
          </table>
              
            </div>
        </div>
       
        
      
      </div>
    </>
  );
};
export default AttendeeOverviewViewPage;
