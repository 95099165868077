import React, { useContext, useEffect } from "react"
import dummyImage from "../Assets/Image.png"
import { Link } from "react-router-dom"
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";

const EventOverviewViewPage=()=>{
  
  return (
    <>
    <div className=" w-full flex h-screen overflow-x-scroll">
        <div className="w-[100%] px-4 pt-5">
        <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base pt-3 pb-1">Total Speaker</h1>
                <span className="text-[#525B75] text-sm font-normal pt-2">Total Speakers in the event</span>

                      <table className=" table-auto bg-white text-start shadow-md  rounded-lg mt-3 ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[13%] px-4 py-2 rounded-tl relative">
                            Photo
                            </th>

                            <th className="w-[20%] px-4 py-2 ">
                            Title
                            </th>
                            <th className="w-[20%] px-4 py-2 ">
                            First name
                            </th>
                            <th className="w-[15%] px-4 py-2">Last name</th>
                            <th className="w-[20%] px-4 py-2 relative">
                           Position
                            </th>
                            <th className="w-[15%] px-4 py-2">Email Id</th>
                            <th className="w-[20%] px-4 py-2 rounded-tr">Organization</th>
                            <th className="w-[20%] px-4 py-2 rounded-tr">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          
                            
                              <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="p-2">
                                <img
                            src={dummyImage}
                            className="w-12 h-12 rounded-md border-2 border-gray"
                            alt="Sponsor"
                          />
                                </td>

                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>
                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>
                                <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/eventoverview-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td>
                                
                              </tr>
                            
                        
                        </tbody>
                      </table>
                    </div>

                    <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base pt-3 pb-1">Total Agenda</h1>
                <span className="text-[#525B75] text-sm font-normal pt-2">Total Agenda in the event</span>

                      <table className=" table-auto bg-white text-start shadow-md  rounded-lg mt-3 ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                           

                            <th className="w-[20%] px-4 py-2 ">
                           Session name
                            </th>
                            <th className="w-[25%] px-4 py-2 ">
                            Speakers
                            </th>
                            <th className="w-[15%] px-4 py-2">Date</th>
                            <th className="w-[15%] px-4 py-2 relative">
                          Start Time
                            </th>
                            <th className="w-[15%] px-4 py-2">End Time</th>
                            <th className="w-[15%] px-4 py-2 rounded-tr">Check In</th>
                            <th className="w-[15%] px-4 py-2 rounded-tr">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          
                            
                              <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                

                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>
                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>
                                <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/eventoverview-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td>

                                
                              </tr>
                            
                        
                        </tbody>
                      </table>
                    </div>

                    <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base pt-3 pb-1">Sponsors</h1>
                <span className="text-[#525B75] text-sm font-normal pt-2">Total Speakers in the event</span>
                      <table className=" table-auto bg-white text-start shadow-md  rounded-lg mt-3 ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[13%] px-4 py-2 rounded relative">
                             Logo
                            </th>

                            <th className="w-[20%] px-4 py-2 ">
                              Sponsor Name
                            </th>
                            <th className="w-[20%] px-4 py-2 ">
                             Level
                            </th>
                            <th className="w-[15%] px-4 py-2">Profile Visit</th>
                            <th className="w-[20%] px-4 py-2 relative">
                            Banner Click
                            </th>
                            <th className="w-[15%] px-4 py-2">Website Click</th>
                            <th className="w-[15%] px-4 py-2">Website</th>
                            <th className="w-[15%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          
                            
                              <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="p-2">
                                <img
                            src={dummyImage}
                            className="w-12 h-12 rounded-md border-2 border-gray"
                            alt="Sponsor"
                          />
                                </td>

                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>
                                <td className=" py-2 px-4">hjk</td>

                                <td className=" py-2 px-4">
                                 njhkj
                                </td>
                                <td className=" py-2 px-4">
                                  hjm
                                </td>

                                <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link>
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            
                        
                        </tbody>
                      </table>
                    </div>
        </div>
      </div>
    
    </>
  )
}
export default EventOverviewViewPage;