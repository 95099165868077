import React, { useState, useContext, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import ExcelLogo from "../Assets/excel logo.png";

const ImportAttendees = () => {
  const { importUserApi, disableSave , getRegistraition} = useContext(DataContext);
  const [state, setState] = useState({
    file: "",
  });
  const [fileError, setFileError] = useState(null);

  const onButtonClick = () => {
    fetch("SampleFile.xlsx").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SampleFile.xlsx";
        alink.click();
      });
    });
  };

  const onFileChange = (event) => {
    setState({ ...state, file: event.target.files[0] });
    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(xls|xlsx)$/)
    ) {
      setFileError("only .xls and .xlsx file format are supported");
      return false;
    } else {
      setFileError(false);
    }
  };
  const buttonDisabled = !fileError && state.file !== "";

  const handleSubmit = () => {
    if (!disableSave) importUserApi(state);
  };
  
  console.log(buttonDisabled);
  return (
    <>
      <div className="flex w-full">
        <div className="w-full px-7 py-2 ">
          <div className="flex py-2 items-center justify-between">
            <div className="flex">
              <Link to={"/attendees"}>
                <div className="w-8 pt-1">
                  <button className="border p-2">
                    <FaArrowLeft />
                  </button>
                </div>
              </Link>
              <div className="p-2">
                <h1 className="text-xl px-2 ">Attendee Import</h1>
              </div>
            </div>
            <button
              type="button"
              className="flex items-center bg-[#34a85314] gap-2 h-9 rounded cursor-pointer px-2"
              onClick={onButtonClick}
            >
              <img src={ExcelLogo} alt="excel-logo" />
              <span className="text-[#34A853] font-medium  text-sm ">
                Download Sample
              </span>
            </button>
          </div>
          <div className="bg-white p-5 rounded-md">
            <div className="w-full h-72 border border-dashed hover:border-theme-color border-[#BABFC3] rounded-md text-center">
              <div className="flex justify-center pt-32">
                {/* <button className="text-theme-color bg-orange-200 p-2 px-4 py-1  text-sm rounded">
                Choose Excel File
              </button> */}
                <label
                  htmlFor="choose_banner"
                  accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer px-2 py-1  text-sm "
                >
                  <span className="bg-theme-light-color px-2 py-1">
                    {state.file === "" ? "No File choosen" : state.file.name}
                  </span>
                  <p className="text-sm pt-1 text-gray-400">
                    Accepts .xls and .xlsx
                  </p>
                  <input
                    id="choose_banner"
                    type="file"
                    className="text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                </label>
              </div>
              <span className="text-xs text-red-600 font-normal">
                {fileError ? fileError : ""}
              </span>
            </div>
          </div>

          <div className="pt-2">
            <Link to={"/attendees"}>
              <button className="border-2 hover:bg-gray-300  border-[#BABFC3] px-8 text-sm py-2 rounded">
                Back
              </button>
            </Link>
            <button
              className="bg-theme-color px-8 text-sm py-2 disabled:opacity-50 rounded hover:bg-orange-500 text-white ml-3"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!buttonDisabled}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportAttendees;
