import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { FiTrash } from "react-icons/fi";
import DeleteModal from "../DeleteModal/DeleteModal";
import { AiOutlineEye } from "react-icons/ai";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from "react-tooltip";
import NoEnquiriesModal from "./NoEnquiriesModal";
import AddEnquiry from "./addEnquiry";

const Enquiries = () => {
  const {
    checkResponse,
    globalLoader,
    setDeleteBtnId,
    setDeleteBtn,
    deleteBtn,
    deleteBtnId,
    totalCount,
    pageCount,
    currentPage,
    setCurrentPage,
    isEnquiryList,
    setIsEnquiryList,
    enquiryList,
    getEnquiry,
    enquiryPaginationApi,
    deleteEnquiry,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);

  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };
  useEffect(() => {
    getEnquiry();
    setCurrentPage(1);
    enquiryPaginationApi(1, limit);
  }, []);
  const handleView = (data) => {
    navigate("/enquiry/view", { state: { data: data } });
  };
  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteEnquiry}
          deleteId={deleteBtnId}
          currentPage={currentPage}
          limit={limit}
          secondResultCount={secondResultCount}
          setSecondResultCount={setSecondResultCount}
        />
      ) : (
        ""
      )}
      {isEnquiryList ? (
        <AddEnquiry setIsEnquiryList={setIsEnquiryList} />
      ) : (
        <div className="flex w-full">
          <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
            <div className="pt-3 ">
              <h1 className="text-xl text-[#202223] font-semibold">
                Enquiries
              </h1>
            </div>

            <div>
              <div className=" flex justify-between pt-3 ">
                <div className="flex gap-1">
                  <Link to="">
                    <button
                      className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm"
                      onClick={() => {
                        setIsEnquiryList(true);
                      }}
                    >
                      Create Enquiry
                    </button>
                  </Link>
                </div>
              </div>

              {enquiryList?.length === 0 && checkResponse === true ? (
                <NoEnquiriesModal />
              ) : globalLoader ? (
                <GlobalLoader />
              ) : (
                <>
                  <table className="w-full table-auto bg-white shadow-md rounded-md mt-4">
                    <thead className="cursor-pointer">
                      <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                        <th className=" w-[25%] p-2 rounded-tl-md relative">
                          <span>Attendee Name</span>
                        </th>
                        <th className=" w-[10%] py-2 px-1 whitespace-nowrap">
                          Attendee Email
                        </th>
                        <th className="w-[15%] p-2 ">Enquiry</th>
                        <th className="w-[15%] p-2 ">Date Created</th>
                        <th className="w-[15%] p-2 ">Action</th>
                      </tr>
                    </thead>
                    <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                      {enquiryList?.map((item) => {
                        return (
                          <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow text-[#202223] text-sm font-normal">
                            <td className="px-2 py-3  flex gap-2 items-center ">
                              <p>{item?.Attendee_name}</p>
                            </td>
                            <td className="p-2  leading-5">
                              <p>{item?.email}</p>
                            </td>
                            <td className="p-2  leading-5">
                              <p>{item?.query}</p>
                            </td>
                            <td className="p-2 ">
                              {dateConversion(item?.createdAt)[0]}
                            </td>
                            <td className="">
                              <div className="flex items-center   text-black ">
                                <AiOutlineEye
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="View"
                                  className={` hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px]`}
                                  onClick={() => handleView(item)}
                                />
                                <Tooltip
                                  place="top"
                                  id="my-tooltip"
                                  className="custom-tooltip"
                                />

                                <FiTrash
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Delete"
                                  className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                                  onClick={() => {
                                    setDeleteBtn(true);
                                    setDeleteBtnId(item?._id);
                                  }}
                                />
                                <Tooltip
                                  place="top"
                                  id="my-tooltip"
                                  className="custom-tooltip"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {enquiryList.length > limit && !checkResponse && (
                    <div className="flex justify-between items-start ">
                      <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
                        pageCount === 1 ? totalCount : secondResultCount
                      } of ${totalCount} results`}</span>

                      <Pagination
                        firstResultCount={firstResultCount}
                        setFirstResultCount={setFirstResultCount}
                        secondResultCount={secondResultCount}
                        setSecondResultCount={setSecondResultCount}
                        limit={limit}
                        api={enquiryPaginationApi}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Enquiries;
