import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataState";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dummyImage from "../Assets/dummyimage.jpg";
import NoDataModal from "../NoDatatModal/NoDataModal";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { BsExclamationTriangle } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineLike } from "react-icons/ai";
import { BiComment } from "react-icons/bi";
import DisableModal from "./DisableModal";
import SocialWallModal from "./SocialWallModal";

const Activity = () => {
  const { notificationList, getNotificationApi, checkResponse, globalLoader,pollModalBtn, setPollModalBtn } = useContext(DataContext);
  const [currentSlide, setCurrentSlide] = useState(0);
const [disableId, setDisableId] = useState(null)
const [disable, setDisable] = useState(false)


  const [item, setItem] = useState(null)
  TimeAgo.addDefaultLocale(en);
  const timeAgo = new TimeAgo('en-US');

  const validateDate = (dateString) => {
    const parsedDate = Date.parse(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  useEffect(() => {
    getNotificationApi();
  }, []);

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 250,
    arrows: false,
    adaptiveHeight: true,
    appendDots: (dots) => <ul className="custom-dots">{dots}</ul>,
  };

  return (
   
    <div className="w-full">
      {
        pollModalBtn ? <SocialWallModal item={item} /> : <></>
      }
      {notificationList.length === 0 && checkResponse ? (
        <NoDataModal />
      ) : globalLoader ? (
        <GlobalLoader />
      ) : (
        notificationList.map((item, idx) => (
          <div key={idx} className="bg-white rounded-md p-5">
            {item?.reports_id.map((element, subIdx) => (
              <div key={subIdx} className="flex gap-3 items-center mb-2">
                <BsExclamationTriangle className="text-theme-color" size={36} />
                <div className="flex flex-col text-sm">
                  <div className="flex gap-1">
                    <span className="text-theme-color">{element?.Name}</span>
                    <span>reported</span>
                    <span className="italic">{item?.post_id?.attendee_id?.Name}</span>
                    <span>post</span>
                  </div>
                  <p className="text-[13px] font-normal text-[#747474]">
                    {validateDate(item?.createdAt)
                      ? timeAgo.format(validateDate(item?.createdAt), "round")
                      : "Invalid date"}
                  </p>
                </div>
              </div>
            ))}

            <div className="bg-[#FAFBFB] w-full rounded-md mt-4">
              <div className="px-6 pb-1 flex justify-between">
                <div className="flex space-x-3 my-2 pt-3">
                  <img
                    src={item?.post_id?.attendee_id?.image || dummyImage}
                    className="w-12 h-12 rounded-full"
                  />
                  <div>
                    <p className="text-[14px] font-semibold">{item?.post_id?.attendee_id?.Name}</p>
                    <p className="text-[13px] font-normal text-[#747474]">
                      {validateDate(item?.post_id?.createdAt)
                        ? timeAgo.format(validateDate(item?.post_id?.createdAt), "round")
                        : "Invalid date"}
                    </p>
                  </div>
                </div>
              </div>

              <hr className="text-gray-50" />
              <div className="px-6 py-2">
                {item?.post_id?.image?.length > 0 && (
                  <Slider {...settings}>
                    {item?.post_id?.image?.map((element, index) => (
                      <div key={index}>
                        <img
                          src={element}
                          alt={`Image ${index}`}
                          className="w-full object-scale-down h-[250px] rounded-lg"
                        />
                      </div>
                    ))}
                  </Slider>
                )}
                <div className="py-3">
                  <p className="text-sm font-normal text-[#404040] w-[60%]">{item?.post_id?.caption}</p>
                </div>
              </div>
              
              <hr className="text-gray-50" />
              <div className="flex gap-4 mx-6 my-3 pb-3 ">
                <div className="flex gap-1 items-center text-theme-color">
                  <AiOutlineLike className="" />
                  <span
                    onClick={() => {
                      setPollModalBtn("like");
                      setItem(item?.post_id?.likes||[])
                      console.log("11111111111111",item);
                    }}
                    className={`${item?.post_id?.likes?.length > 0 ? "underline" : ""
                      } font-normal text-sm cursor-pointer`}
                  >
                    {item?.post_id?.likes?.length||0}
                  </span>
                </div>

                <div className="flex gap-1 items-center text-theme-color">
                  <BiComment className="" />
                  <span
                    onClick={() => {
                      setItem(item?.post_id?.comments);
                      setPollModalBtn("comment");
                    }}
                    className={`${item?.post_id?.comments?.length > 0 ? "underline" : ""
                      } font-normal text-sm cursor-pointer`}
                  >
                    {item?.post_id?.comments?.length||0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Activity;
