import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";

const SponsorLevel = () => {
  const {
    getSponsorLevelApi,
    sponsorsLevel,
    setSponsorsLevel,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteSponsorlevelApi,
    searchSponsorLevelApi,
    checkResponse,
  } = useContext(DataContext);
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
  const permission = userInfo?.permission?.sponsorLevel;
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  const onKeyUpHandler = (e) => {
    searchSponsorLevelApi(e.target.value);
  };

  useEffect(() => {
    getSponsorLevelApi();
  }, []);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...sponsorsLevel].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setSponsorsLevel(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  return (
    <>
      <ToastContainer />
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteSponsorlevelApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )}
      <div className="w-full flex">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-1">
          <div className="pt-3 ">
            <div>
              <h1 className="text-xl  font-semibold  ">Sponsor</h1>
            </div>
            <div className="flex pt-5 text-sm">
            {(userInfo?.role === "Admin" ||
                Object.values(userInfo?.permission?.sponsorLevel).some(
                  (value) => value === true
                )) &&
              <Link to={"/sponsorlevel"}>
                <button className="hover:underline text-theme-color underline hover:text-theme-color pr-4">
                  Sponsor Level
                </button>
              </Link>}
              {(userInfo?.role === "Admin" ||
                Object.values(userInfo?.permission?.sponsors).some(
                  (value) => value === true
                )) &&
              <Link to={"/sponsors"}>
                <button className=" hover:underline hover:text-theme-color">
                  Sponsor
                </button>
              </Link>}
            </div>
            <div className=" flex items-start justify-between space-x-2 py-5">
              <div className="space-x-2 text-sm">
                {(userInfo?.role === "Admin" || permission?.create) && (
                  <Link to={"/addsponsorlevel"}>
                    <button className="border bg-theme-color text-white  px-4 py-2 rounded ">
                      {"Add New Sponsor Level"}
                    </button>
                  </Link>
                )}
                {/* <button className="border text-black bg-white px-4 rounded py-2">
                                    {"Export XLS"}
                                </button>
                                <Link to={"/importsponsor"}>
                                    <button className="border text-black bg-white px-4 rounded py-2">
                                        {"Import XLS"}
                                    </button>
                                </Link> */}
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] text-sm left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2  px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/* table list */}
          {sponsorsLevel?.length === 0 && checkResponse === true ? (
            <NoDataModal />
          ) : globalLoader ? (
            <GlobalLoader />
          ) : (
            <table className="table-auto bg-white shadow-md text-start w-full   rounded-lg">
              <thead className=" cursor-pointer">
                <tr className=" text-left  bg-theme-light-color text-theme-color text-xs font-normal  ">
                  <th className="w-3/12 py-2  px-4 rounded-tl-md relative">
                    Sponsor level
                    <div className="px-[90px] ">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] absolute ${getSortArrowClassName(
                            "level_name",
                            "asc"
                          )}`}
                          onClick={() => sortData("level_name")}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute ${getSortArrowClassName(
                            "level_name",
                            "desc"
                          )}`}
                          onClick={() => sortData("level_name")}
                        />
                      </span>
                    </div>
                  </th>
                  {/* <th className="w-1/6 py-2 px-4">ID</th> */}
                  <th className="w-1/6 py-2 px-4">Level order</th>
                  <th className="w-1/6 py-2 px-4">Level color</th>

                  <th className="w-1/6 py-2 px-4 text-center  rounded-tr-md">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm [&>*:nth-child(even)]:bg-[#FAFBFB]">
                {sponsorsLevel.map((item, index) => (
                  <tr
                    className="capitalize  text-sm border-y text-start hover:shadow-tableRowsShadow  cursor-pointer "
                    key={index}
                  >
                    <td className="text-theme-color py-2 w-3/12 px-4">
                      {item?.level_name}{" "}
                    </td>
                    {/* <td className='py-2 px-4 w-3/12'>{item?.id}</td> */}
                    <td className="py-2 px-4">{item?.order}</td>
                    <td className="py-2 px-4">{item?.level_color}</td>
                    <td className="">
                      {" "}
                      <div className="flex items-center  justify-center   text-black  ">
                        {(userInfo?.role === "Admin" || permission?.write) && (
                          <Link
                            to={`/update-sponsorlevel/${item?._id}`}
                            state={item}
                          >
                            <FiEdit2
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Edit"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            />
                            <Tooltip
                              place="top"
                              id="my-tooltip"
                              className="custom-tooltip"
                            />
                          </Link>
                        )}
                        {userInfo?.role === "Admin" && (
                          <>
                            <FiTrash
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Delete"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                              onClick={() => {
                                setDeleteBtn(true);
                                setDeleteBtnId(item?._id);
                              }}
                            />
                            <Tooltip
                              place="top"
                              id="my-tooltip"
                              className="custom-tooltip"
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}

                {/*<tr className="capitalize border-y text-start group cursor-pointer hover:shadow-tableRowsShadow">
                               

                                <td className="text-theme-color py-2 w-3/12 px-4">Gold</td>
                                <td className='py-2 w-3/12'>Gold</td>
                                <td className='py-2'>2</td>
                                <td className=''><div className="flex items-center  justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiEdit2/> <FiTrash/> </div></td>



                            </tr>
                            <tr className="capitalize border-y text-start group cursor-pointer hover:shadow-tableRowsShadow">
                                

                                <td className="text-theme-color py-2 w-3/12 px-3">silver </td>
                                <td className='py-2 w-3/12'>Gold</td>
                                <td className='py-2'>3</td>
                                <td className=''><div className="flex items-center  justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color">  <FiEdit2/><FiTrash/> </div></td>

                            </tr>
                            <tr className="capitalize border-y text-start group cursor-pointer hover:shadow-tableRowsShadow">
                                
                                <td className="text-theme-color py-2 w-3/12 px-3 rounded-bl-lg">gold </td>
                                <td className='py-2 w-3/12'>silver</td>
                                <td className='py-2'>4</td>
                                <td className='rounded-br-lg'><div className="flex items-center  justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color "><FiEdit2/>  <FiTrash/> </div></td>

                    </tr>*/}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default SponsorLevel;
