import React, { useContext, useEffect, useRef } from "react";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { FiMoreHorizontal } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import dummyImage from "../../components/Assets/dummyimage.jpg"
import { useState } from "react";
import { Link } from "react-router-dom";
import NoDataModal from "../NoDatatModal/NoDataModal";
import Slider, { slickGoTo } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DisableModal from "./DisableModal";
import { AiOutlineLike } from "react-icons/ai";
import { BiComment } from "react-icons/bi";
import SocialWallModal from "./SocialWallModal";


const TimeLine = () => {
  const { getAllPostsApi, allPostsList, disablePostApi, postDisabled, checkResponse, globalLoader, pollModalBtn, setPollModalBtn } = useContext(DataContext)
  const [toggle, setToggle] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0);
  const [disable, setDisable] = useState(false)
  const [disableId, setDisableId] = useState(null)
  const [item, setItem] = useState(null)

  const sliderRef = useRef(null);
  TimeAgo.addDefaultLocale(en)
  const timeAgo = new TimeAgo('en-US')

  let handleResponse = (id) => {
    if (toggle === id) {
      setToggle(!null);
      return false
    }
    setToggle(id);
  }
  useEffect(() => {

    getAllPostsApi()
  }, [])
  const CustomDot = ({ active }) => (
    <div
      className={`custom-dot ${active ? ' active' : ''}`}
    ></div>
  );

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 250,
    arrows: false,
    adaptiveHeight: true,

    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    autoplay: true,
    appendDots: (dots) => (
      <div>
        <ul className="custom-dots">{dots}</ul>

      </div>

    ),
    customPaging: (i) => (
      <CustomDot onClick={() => { setCurrentSlide(i) }} key={i} active={i === currentSlide} />
    )
  };
  const handleSlideChange = (next) => {
    console.log(next, "next")
    setCurrentSlide(next);
  };
  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  console.log(allPostsList, "allposts")
  console.log(sliderRef, "slidefRef")
  return (
    <>
      {
        disable ? <DisableModal setDisable={setDisable} disableId={disableId} /> : <></>
      }
      {
        pollModalBtn ? <SocialWallModal item={item} /> : <></>
      }
      <div className="w-full ">
        {
          allPostsList.length === 0 && checkResponse === true ? <NoDataModal /> : globalLoader ? <GlobalLoader /> :
            allPostsList.map((item) => {
              return (
                <>

                  <div key={item?._id} className={`${item?.isActive === false ? "bg-[rgba(0,0,0,0.08)]" : "bg-white"} w-full  rounded-md mt-4`}>
                    <div className="px-6 pb-1 flex justify-between">

                      <div className="flex space-x-3 my-2 pt-3">
                        <img
                          src={item?.attendee_id?.image === "" ? dummyImage : item?.attendee_id?.image}
                          className="w-12 h-12 rounded-full "
                        />
                        <div >
                          <p className="text-[14px] font-semibold ">{item?.attendee_id?.Name}</p>
                          <p className="text-[13px] font-normal text-[#747474] ">

                            {timeAgo.format(Date.now() - (Date.now() - Date.parse(item?.createdAt)), 'round')
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className="text-[18px] text-[#868EAE] pt-4"

                      >


                        <div class="relative inline-block text-left">
                          <div className="flex gap-6 items-center">
                            <button className={`${item?.isActive === false ? "" : "hidden"} flex justify-center py-2 px-3  items-center text-theme-color border border-theme-color text-xs font-normal rounded-md`}>Disabled</button>
                            <button type="button" className={`${item?.isActive === false ? "hidden" : ""} inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm `} id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={(e) => { handleResponse(item._id) }}>
                              <FiMoreHorizontal size={20} />

                            </button>
                          </div>


                          <div class={`${toggle === item?._id ? "" : "hidden"} absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
                            onClick={() => {

                              setDisableId(item?._id)
                              setDisable(true)
                              setToggle(false)
                            }}
                          >
                            <div class="py-1" role="none">

                              <Link to="" class="text-gray-700 block px-4 py-1 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Disable</Link>

                            </div>
                          </div>
                        </div>



                      </div>
                    </div>

                    <hr className="text-gray-50" />
                    <div className="px-6 pt-3 ">
                      {item?.image.length > 0 && (
                        <Slider {...settings} beforeChange={handleSlideChange} ref={sliderRef}>
                          {item?.image.map((element, index) => (
                            <div key={index}>
                              <img src={element} alt={`Image ${index}`} className="w-full object-scale-down h-[250px] rounded-lg" />
                            </div>
                          ))}
                        </Slider>
                      )}

                      <div className="py-3">
                        <p className="text-sm  font-normal text-[#404040] w-[60%]">
                          {item?.caption}
                        </p>
                      </div>
                    </div>
                    <hr className="text-gray-50" />
                    <div className="flex gap-4 mx-6 my-3 pb-3  ">
                      <div className="flex gap-1 items-center text-theme-color">
                        <AiOutlineLike className="" />
                        <span onClick={() => {
                          setPollModalBtn("like")
                          setItem(item?.likes)
                        }} className={`${item?.likes?.length > 0 ? "underline" : ""} font-normal text-sm cursor-pointer`}>{item?.likes.length}</span>
                      </div>

                      <div className="flex gap-1 items-center text-theme-color">
                        <BiComment className="" />
                        <span onClick={() => {
                          setItem(item?.comments)
                          setPollModalBtn("comment")
                        }} className={`${item?.comments.length > 0 ? "underline" : ""} font-normal text-sm cursor-pointer`}>{item?.comments.length}</span>
                      </div>



                    </div>
                  </div>


                </>


              )
            })
        }


      </div>
    </>
  );
};

export default TimeLine;
