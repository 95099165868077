import React, { useState, useContext, useEffect,useRef } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { useParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { WithContext as ReactTags } from "react-tag-input";
import "./ReactTag.css";
import { MdClose } from "react-icons/md";

const EventDetails = () => {
  const { specificEvent, setSpecificEvent, updateEventApi, getEventByIdApi,disableSave } =
    useContext(DataContext);
  const { id } = useParams();
  const [bannerimage, setbannerimage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [image, setimage] = useState(null);
  const [imageErrors, setImageErrors] = useState(false);
  const [logo, setlogo] = useState(null);
  const [logoError, setlogoError] = useState(false);
  const [tags, setTags] = useState([]);
  const [RoomTags,setRoomTags]=useState([])
  const [apiTags, setApiTags] = useState([]);
  const [interest, setInterest] = useState([]);
  const [room,setRoom]=useState([])
  const appLogoInput=useRef()
  const websiteLogoInput=useRef()
  const bannerImageInput=useRef()

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    const newArr = interest.filter((value, index) => index !== i);
    setInterest(interest.filter((value, index) => index !== i));
    setSpecificEvent({ ...specificEvent, interest: [...newArr] });
  };

  const handleRoomDelete=(i)=>{
    setRoomTags(RoomTags.filter((tag,index)=>index !==i));
    const newRoomArr = room.filter((value, index) => index !== i);
    setRoom(room.filter((value, index) => index !== i));
    setSpecificEvent({ ...specificEvent, room: [...newRoomArr] });
  }

  const handleAddition = (tag) => {
    console.log("hello");
    setTags([...tags, tag]);
    console.log(tag.text);
    setInterest([...interest, tag.text]);

    setSpecificEvent({ ...specificEvent, interest: [...interest, tag.text] });
  };
const handleRoomAddition=(tag)=>{
  console.log("hello");
    setRoomTags([...RoomTags, tag]);
    console.log(tag.text);
    setRoom([...room, tag.text]);

    setSpecificEvent({ ...specificEvent, room: [...room, tag.text] });
}
  // const [state,setState] = useState({
  //   eventName: "",
  //   eventStartDate: "",
  //   eventEndDate: "",
  //   event_venue: "",
  //   image: "",
  //   interest: [],
  //   bannerimage:"",
  //   business: "",
  //   phone: "",
  //   room: [],
  //   gtag: "",
  //   logo: ""
  // })

  var formatedStartDate = new Date(
    specificEvent?.eventStartDate
  ).toLocaleDateString("en-CA");

  var formatedEndDate = new Date(
    specificEvent?.eventEndDate
  ).toLocaleDateString("en-CA");

  const handleUpdate = () => {
    if(!disableSave)
    updateEventApi(specificEvent, id,"eventapi");
  };

  //-------logo vaidation--------
  const onLogoChange = (event) => {
    const allowedExtensions = ["jpg", "jpeg", "png","webp"];

    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      return allowedExtensions.some((extension) => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(
      event.target.files[0].name,
      allowedExtensions
    );

    if (event.target.files && event.target.files[0]) {
      setlogo(URL.createObjectURL(event.target.files[0]));
    }

    if (!isAllowed) {
      setlogoError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setlogoError("Please select image less than 1 mb");
    } else {
      setlogoError(false);
      setSpecificEvent({
        ...specificEvent,
        logo: event.target.files[0],
      });
    }
  };

  let interestArr = [];
  specificEvent?.interest?.forEach((item) => {
    interestArr.push(item);
  });

  let roomArr = [];
  specificEvent?.room?.forEach((item) => {
    roomArr.push(item);
  });
  //-----------image validation----------
  const onImageFormat = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimage(URL.createObjectURL(event.target.files[0]));
    }
    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)
    ) {
      setImageErrors("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageErrors("Please select image less than 1 mb");
    } else {
      setImageErrors(false);
      setSpecificEvent({ ...specificEvent, image: event.target.files[0] });
    }
  };
  //---------bannerimage validation---------
  const onImageFormat2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setbannerimage(URL.createObjectURL(event.target.files[0]));
    }
    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)
    ) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false);
      setSpecificEvent({
        ...specificEvent,
        bannerimage: event.target.files[0],
      });
    }
  };

  const handleBannerDelete = ()=>{
    setSpecificEvent({...specificEvent, bannerimage: ""});
    setbannerimage(null)
    bannerImageInput.current.value = "";
    
  }
  const handleAppLogoDelete = ()=>{
    setSpecificEvent({...specificEvent, logo: ""});
    setlogo(null)
    appLogoInput.current.value = "";
    
  }
  const handleWebsiteLogoDelete = ()=>{
    setSpecificEvent({...specificEvent, image: ""});
    setimage(null)
   websiteLogoInput.current.value = "";
    
  }

  //------------start and enddate validation
  let startDate = new Date(specificEvent?.eventStartDate)
    .toLocaleDateString("en-CA")
    .split("T")[0];
  // let endDate = new Date(specificEvent?.eventEndDate).toLocaleDateString('en-CA').split('T')[0];

  const buttonDisabled =
    specificEvent?.interest?.length !== 0 &&
    specificEvent?.room?.length !== 0 &&
    specificEvent.gtag !== "" &&
    specificEvent.logo !== "" &&
    specificEvent.bannerimage !== "" &&
    specificEvent.image !== "";
  useEffect(() => {
    let option = [];
    console.log(specificEvent?.interest);
    specificEvent?.interest?.forEach((item) => {
      option.push({ id: item, text: item });
    });
    console.log(specificEvent);
    setTags([...option]);
    setInterest([...interestArr]);
    console.log(option, "option");
  }, [specificEvent?.interest]);

  useEffect(() => {
    let option2 = [];
    
    specificEvent?.room?.forEach((item) => {
      option2.push({ id: item, text: item });
    });
    
    setRoomTags([...option2]);
    setRoom([...roomArr]);
   
  }, [specificEvent?.room]);
  console.log(logo !== null && !logoError)
  return (
    <>
      <div className="bg-white w-full px-4 rounded-md">
        <div className="flex gap-x-4 py-2">
          <div>
            <div className="">
              <h1 className="text-sm  font-semibold pb-1">Event App Logo *</h1>
              <div className=" h-30 border-2 border-dashed rounded-md text-center pt-5  hover:border-theme-color">
                <label
                  htmlFor="logo_image"
                  // accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer  text-sm "
                >
                  <div className="flex justify-center">
                    <span className="flex items-center gap-2 bg-theme-light-color px-2 py-1">
                      {" "}
                      {logo === null
                        ? "Choose event image"
                        : specificEvent?.logo?.name}
                      <FaArrowCircleUp className="text-theme-color" />{" "}
                    </span>
                  </div>
                  <p className="text-sm pt-1 text-gray-400 px-4 leading-4">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                  </p>

                  <input
                    id="logo_image"
                    type="file"
                    ref={appLogoInput}
                    // accept="image/jpeg, image/png"
                    className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      onLogoChange(e);
                    }}
                  />
                </label>
              </div>
            </div>
            <span className="text-xs text-red-600 font-normal">
              {logoError ? logoError : ""}
            </span>
            <div className={(logo === null && !logoError) && specificEvent?.logo!=="" ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleAppLogoDelete()}/>
              <img
                src={logo === null ? specificEvent?.logo : logo}
                alt="logo"
                className="absolute w-full h-full object-cover"
              />
            </div>
            <div className={(logo !== null && !logoError) ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleAppLogoDelete()}/>
              <img
                src={logo}
                alt="logo"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>

          <div>
            <div className="">
              <h1 className="text-sm  font-semibold pb-1">Website Logo *</h1>
              <div className=" h-30 border-2 border-dashed rounded-md text-center pt-5  hover:border-theme-color">
                <label
                  htmlFor="website_image"
                  // accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer  text-sm "
                >
                  <div className="flex justify-center">
                    <span className="flex items-center gap-2 bg-theme-light-color px-2 py-1">
                      {" "}
                      {image === null
                        ? "Choose logo image"
                        : specificEvent?.image?.name}
                      <FaArrowCircleUp className="text-theme-color" />{" "}
                    </span>
                  </div>
                  <p className="text-sm pt-1 text-gray-400 px-4 leading-4">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                  </p>

                  <input
                    id="website_image"
                    type="file"
                    ref={websiteLogoInput}
                    // accept="image/jpeg, image/png"
                    className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      onImageFormat(e);
                    }}
                  />
                </label>
              </div>
            </div>
            <span className="text-xs text-red-600 font-normal">
              {imageErrors ? imageErrors : ""}
            </span>
            <div className={(image === null && !imageErrors) && specificEvent?.image!=="" ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleWebsiteLogoDelete()}/>
              <img
                src={image === null ? specificEvent?.image : image}
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>
            <div className={image != null && !imageErrors ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleWebsiteLogoDelete()}/>
              <img
                src={image}
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>

          <div>
            <div className="">
              <h1 className="text-sm  font-semibold pb-1">Banner image *</h1>
              <div className=" h-30 border-2 border-dashed rounded-md text-center pt-5  hover:border-theme-color">
                <label
                  htmlFor="banner_image"
                  // accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer  text-sm "
                >
                  <div className="flex justify-center">
                    <span className="flex items-center gap-2 bg-theme-light-color px-2 py-1">
                      {" "}
                      {bannerimage === null
                        ? "Choose banner image"
                        : specificEvent?.bannerimage?.name}
                      <FaArrowCircleUp className="text-theme-color" />{" "}
                    </span>
                  </div>
                  <p className="text-sm pt-1 text-gray-400 px-4 leading-4">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400 pb-2">
                    Maximum Upload size: 1Mb
                  </p>

                  <input
                    id="banner_image"
                    type="file"
                    ref={bannerImageInput}
                    // accept="image/jpeg, image/png"
                    className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      onImageFormat2(e);
                    }}
                  />
                </label>
              </div>
            </div>
            <span className="text-xs text-red-600 font-normal">
              {imageError ? imageError : ""}
            </span>
            <div className={(bannerimage === null && !imageError) && specificEvent?.bannerimage!=="" ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleBannerDelete()}/>
              <img
                src={
                  bannerimage === null
                    ? specificEvent?.bannerimage
                    : bannerimage
                }
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>
            <div className={bannerimage != null && !imageError ? "relative w-28 h-28 mt-4" : "hidden"}>
            <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={()=> handleBannerDelete()}/>
              <img
                src={bannerimage}
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <hr className="text-gray-50 py-1" />
        <form className="">
          <div className="flex space-x-5 py-1">
            <div className="w-1/2">
              <label
                htmlFor="event-name"
                className="text-sm font-medium text-[#202223] px-1 pb-1"
              >
                Event Name
              </label>
              <input
                type="text "
                id="event-name"
                placeholder="Enter your event name"
                className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="eventName"
                value={specificEvent?.eventName}
                disabled
                onChange={(e) => {
                  setSpecificEvent({
                    ...specificEvent,
                    eventName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-1/2">
              <label
                htmlFor="organization"
                className="text-sm font-medium text-[#202223] px-1  pb-1"
              >
                Organization
              </label>
              <input
                type="text "
                id="organization"
                placeholder="Enter your organization"
                className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="Organization"
                value={specificEvent?.business}
                disabled
                onChange={(e) => {
                  setSpecificEvent({
                    ...specificEvent,
                    business: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex space-x-5 py-1">
            <div className="w-1/2">
              <label
                htmlFor="start-date"
                className="pt-2 px-1 text-sm font-medium text-[#202223] pb-1"
              >
                Start at
              </label>
              <input
                min={startDate}
                type="date"
                id="start-date"
                className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="eventStartDate"
                value={formatedStartDate}
                disabled
                onChange={(e) => {
                  setSpecificEvent({
                    ...specificEvent,
                    formatedStartDate: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-1/2">
              <label
                htmlFor="end-date"
                className="pt-2  px-1 text-sm font-medium text-[#202223]  pb-1"
              >
                End at
              </label>
              <input
                type="date"
                id="end-date"
                className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="eventEndDate"
                value={formatedEndDate}
                disabled
                onChange={(e) => {
                  setSpecificEvent({
                    ...specificEvent,
                    formatedEndDate: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="py-2 ">
            <FormGroup>
              <label
                htmlFor="interests"
                className="pt-4 font-medium text-sm text-[#202223]"
              >
                Interests Tag*
              </label>

              <ReactTags
                tags={tags}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="bottom"
                autocomplete
                classNames={{
                  tag: "ReactTags__tag",
                  remove: "ReactTags__remove",
                  tagInput: "ReactTags__tagInput",
                }}
              />
              {/* <span className="text-theme-color text-sm float-right relative bottom-16">
                CREATE TAG
              </span> */}
            </FormGroup>
            <span className="text-gray-400 text-sm">
              Interest tags are keywords that describe the topics or categories
              that your users are interested in.
            </span>

            {/* <div className="grid grid-cols-auto px-2 py-2">
                 {
                  room.map((item)=>{

                  })
                 }
              </div> */}
          </div>
          <div className="flex flex-col  py-1">
            <div className="">
              <label
                htmlFor="location"
                className="pt-2 text-sm font-medium text-[#202223]  px-1  pb-1"
              >
                Event Location
              </label>
              <input
                type="text"
                id="location"
                className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="location"
                value={specificEvent?.event_venue}
                disabled
                onChange={(e) => {
                  setSpecificEvent({
                    ...specificEvent,
                    event_venue: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mt-2 ">
              {/* <label
                htmlFor="room"
                className="pt-2 text-sm font-medium text-[#202223]  px-1  pb-1 "
              >
                Room *
              </label>
              

              <input
                type="text"
                id="room"
                className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="room"
                value={specificEvent?.room}
                onChange={(e) => {
                  const value = e.target.value;
                  setSpecificEvent({
                    ...specificEvent,
                    room: value.split(","),
                  });
                }}
              /> */}
              <FormGroup>
              <label
                htmlFor="interests"
                className=" font-medium text-sm text-[#202223]"
              >
                Room*
              </label>

              <ReactTags
                tags={RoomTags}
                delimiters={delimiters}
                handleDelete={handleRoomDelete}
                handleAddition={handleRoomAddition}
                inputFieldPosition="bottom"
                autocomplete
                classNames={{
                  tag: "ReactTags__tag",
                  remove: "ReactTags__remove",
                  tagInput: "ReactTags__tagInput",
                }}
              />
              
            </FormGroup>
            </div>
          </div>
          <div className="py-2 ">
            <label
              htmlFor="gtag"
              className="text-sm font-medium  text-[#202223] px-1  pb-1 float-left"
              name="gtag"
            >
              G Tag *
            </label>

            <textarea
              rows="3"
              id="gtag"
              placeholder="Enter your g tag"
              className="w-full border  py-1 px-3 placeholder-[#7c7a7a] placeholder:text-sm shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
              name="gtag"
              value={specificEvent?.gtag}
              onChange={(e) => {
                setSpecificEvent({
                  ...specificEvent,
                  gtag: e.target.value,
                });
              }}
            />
          </div>
        </form>
        <div className="py-6">
          <button
            type="button"
            disabled={!buttonDisabled}
            className=" px-4 py-2 w-48 text-sm font-medium bg-theme-color hover:bg-orange-500 text-white rounded disabled:opacity-50"
            onClick={() => handleUpdate()}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};
export default EventDetails;
