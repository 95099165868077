import React ,{useState} from "react";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import demoImage from "../Assets/dummyimage.jpg"
// import { type Crop } from "react-image-crop";

const ImageCropper=(props)=> {
  console.log("hello iMge Cropper")
 
  const {imageToCrop, onImageCropped} = props;
  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
        unit: '%',
        width: 30,
        aspect: 16 / 9,
    }
);
// console.log(cropConfig,"cropConfig")
const [imageRef, setImageRef] = useState({
  unit: '%',
  width: 30,
  aspect: 16 / 9,
});
// console.log(imageRef,"imageRef")
async function cropImage(crop) {
    // console.log("inside crop image")
  if (imageRef && crop.width && crop.height) {
  //  console.log("iside image re")
      const croppedImage = await getCroppedImage(
          imageRef,
          crop,
          'croppedImage.jpeg' // destination filename
      );
      // console.log("inside cropped image")
      // calling the props function to expose
      // croppedImage to the parent component
      onImageCropped(croppedImage);
  }
}
function getCroppedImage(sourceImage, cropConfig, fileName) {
  // creating the cropped image from the source image
  const canvas = document.createElement('canvas');
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = cropConfig.width;
  canvas.height = cropConfig.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
  );

  return new Promise((resolve, reject) => {
      canvas.toBlob(
          (blob) => {
              // returning an error
              if (!blob) {
                // console.log("he;lo")
                  reject(new Error('Canvas is empty'));
                  return;
              }

              blob.name = fileName;
              // creating a Object URL representing the Blob object given
              const croppedImageUrl = window.URL.createObjectURL(blob);
            // console.log(croppedImageUrl,"cropped imageUrl")
              resolve(croppedImageUrl);
          }, 'image/jpeg'
      );
  });
}
// console.log(cropConfig,"cropConfig")
    return (
       
            <ReactCrop
            // src={imageToCrop}
            crop={imageRef}
            // crop={cropConfig}
            // ruleOfThirds
            // onImageLoaded={(imageRef) => setImageRef(imageRef)}
            // onComplete={(cropConfig) => cropImage(cropConfig)}
            // onChange={(cropConfig) => setCropConfig(cropConfig)}
            onChange={c => setImageRef(c)}
            crossorigin="anonymous"
        >
        <img src={imageToCrop}
          />
        </ReactCrop>
       
    );
}

ImageCropper.defaultProps = {
  onImageCropped: () => {}
}

export default ImageCropper;