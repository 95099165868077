import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const AddEnquiry = () => {
  const { isSpinner, setIsEnquiryList, addEnquiry, enquiry } =
    useContext(DataContext);
  const [emailError, setEmailError] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [state, setState] = useState({
    Attendee_name: "",
    email: "",
    query: "",
    description: "",
  });
  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email) && state.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const btnDisabled =
    state.Attendee_name !== "" &&
    state.email !== "" &&
    state.query !== "" &&
    state.description !== "" &&
    !emailError;

  const handleAddEnquiry = () => {
    addEnquiry(state, userInfo?._id);
  };
  const option = [
    { name: "Looking to buy products", value: "Looking to buy products" },
    { name: "Looking for Dealership", value: "Looking for Dealership" },
    {
      name: "Looking for Distributorship",
      value: "Looking for Distributorship",
    },
    { name: "Business Collaboration", value: "Business Collaboration" },
    { name: "Be a Vendor/Supplier", value: "Be a Vendor/Supplier" },
    { name: "Other", value: "Other" },
  ];
  useEffect(() => {
    if (enquiry) {
      setState({
        Attendee_name: enquiry?.Attendee_name,
        email: enquiry?.email,
        query: enquiry?.query,
        description: enquiry?.description,
      });
    }
  }, [enquiry]);

  return (
    <>
      <ToastContainer />
      <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
              <button
                className="border p-2"
                onClick={() => setIsEnquiryList(false)}
              >
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Add Enquiry </h1>
          </div>
        </div>
        <div className="flex space-x-10 m-0 bg-white p-3 rounded">
          <div className="w-1/2 space-y-3 ">
            <h2 className="font-semibold text-lg ">Contact details</h2>
            <hr className="w-[207%] border-1 border-[#BDBDBD]" />
            <form className="">
              <div>
                <label
                  htmlFor="name"
                  className="text-sm px-1 font-medium pb-1 text-[#202223]"
                >
                  Attendee Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  disabled={enquiry !== undefined}
                  value={state.Attendee_name}
                  placeholder="Enter First Name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                  onChange={(e) => {
                    setState({ ...state, Attendee_name: e.target.value });
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-sm px-1 font-medium  text-[#202223]"
                >
                  Attendee Email *
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={state.email}
                  placeholder="Enter Last Name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="query"
                  className="text-sm px-1 font-medium  text-[#202223]"
                >
                  Query *
                </label>
                <select
                  id="query"
                  type="text"
                  value={state.query}
                  name="query"
                  className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  onChange={(e) => {
                    setState({ ...state, query: e.target.value });
                  }}
                >
                  <option value="" selected disabled hidden>
                    Choose Query
                  </option>

                  {option.map((option, index) => (
                    <option key={index} value={option.name}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="font-semibold text-sm pb-1 text-[#202223]"
                >
                  Description *
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows={"4"}
                  placeholder="Enter description about exhibitor"
                  className="w-full  pt-1 px-3 border border-gray-300 placeholder:text-slate-400 text-sm  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                  value={state.description}
                  onChange={(e) => {
                    setState({ ...state, description: e.target.value });
                  }}
                  maxLength={2000}
                />
              </div>

              <div>
                <div className="pt-5 flex gap-3">
                  <button
                    className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded"
                    onClick={() => {
                      setIsEnquiryList(false);
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    disabled={!btnDisabled}
                    onClick={() => {
                      if (!isSpinner) handleAddEnquiry();
                    }}
                    className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md"
                  >
                    <svg
                      width="40"
                      height="20"
                      fill="currentColor"
                      className={`${
                        isSpinner ? "" : "hidden"
                      } mr-2 animate-spin`}
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                    </svg>
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEnquiry;
