import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams, useLocation } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";


const UpdateSpeaker = () => {
  const {

    speakers,
    updateSpeakerApi,
    countryList,
    getCountryApi,
    disableSave, isSpinner
  } = useContext(DataContext);
  const location = useLocation()
  const speakerEdit = location.state;
  const [count, setCount] = useState(0);
  const [editSpeaker, setEditSpeaker] = useState(speakerEdit);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const { id } = useParams();
  const imageInput = useRef()
  const [errors, setErrors] = useState({
    firstName: true,
    lastName: true,
    position: true,
    organization:true,

  });
  const [instagramError, setInstagramError] = useState(false);
  const [linkedlnError, setLinkedlnError] = useState(false);
  const [twitterError, setTwitterError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);

  const validateUrl = (url) => {
    if (!url.test(editSpeaker.instagram_profile) && editSpeaker.instagram_profile !== "") {
      setInstagramError(true);
    }
    else {
      setInstagramError(false);
    }
    if (!url.test(editSpeaker.linkedln_profile) && editSpeaker.linkedln_profile !== "") {
      setLinkedlnError(true);
    }
    else {
      setLinkedlnError(false);
    }
    if (!url.test(editSpeaker.twitter_profile) && editSpeaker.twitter_profile !== "") {
      setTwitterError(true);
    }
    else {
      setTwitterError(false);
    }
    if (!url.test(editSpeaker.facebook_profile) && editSpeaker.facebook_profile !== "") {
      setFacebookError(true);
    }
    else {
      setFacebookError(false);
    }
  };
  console.log(editSpeaker, "speakerid")


  const buttonObj = {

  }
  const handleUpdate = (e) => {
    if (!isSpinner) updateSpeakerApi(editSpeaker, id);
  };

  const handleChange = (e) => {
    setEditSpeaker({ ...editSpeaker, Biography: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };
  const onImageChange = (event) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "webp"];

    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      return allowedExtensions.some((extension) => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(
      event.target.files[0].name,
      allowedExtensions
    );

    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    setEditSpeaker({
      ...editSpeaker,
      image: event.target.files[0],
    });
    if (!isAllowed) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false);
    }
  };

  const handleDelete = () => {
    setEditSpeaker({ ...editSpeaker, image: "" });
    setImage(null);
    imageInput.current.value = "";
  }

  useEffect(() => {

    getCountryApi();
    setCount(editSpeaker?.Biography.split("").length);
  }, []);

  const buttonDisabled =
    editSpeaker.firstname !== "" &&
    editSpeaker.lastname !== "" &&
    editSpeaker.title !== "" &&
    editSpeaker.organization !== "" &&
    editSpeaker.position !== "" &&
    editSpeaker.country !== "" &&
    editSpeaker.image !== "" &&
    editSpeaker.biography !== "" &&
    editSpeaker.email !== ""
    &&
    !instagramError &&
    !linkedlnError
    && !twitterError && !facebookError
    && errors.firstName
    && errors.lastName && errors.position



  console.log(buttonDisabled, "button")
  console.log(disableSave, "disableSave")
  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <Link to={"/speaker_list"}>
              <div className="w-8 ">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-1">
              <h1 className=" text-xl hover:bg-orange-400xl px-2">
                {`${editSpeaker?.title} ${editSpeaker?.speaker_firstname} ${editSpeaker?.speaker_lastname}`}
              </h1>
            </div>
          </div>
          <div className="bg-white rounded-lg p-3 flex flex-col border-[#D1D9E7] ">
            <div className="flex space-x-10">
              <div className="w-1/2 space-y-3">
                <h2 className="font-semibold text-lg">Speaker Details</h2>
                <hr />
                <div className="">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="text-sm px-1 font-semibold "
                    >
                      First Name *
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      name="firstname"
                      placeholder="Enter your first name"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.speaker_firstname}
                      onChange={(e) => {
                        const isValidInput = /^[a-zA-Z\s]+$/.test(e.target.value);
                        setEditSpeaker({
                          ...editSpeaker,
                          speaker_firstname: e.target.value,
                        });
                        setErrors({ ...errors, firstName: isValidInput })
                      }}
                    />
                    {!errors.firstName && editSpeaker?.speaker_firstname != "" && (
                      <p className=" text-xs text-red-600" id="email-error">
                        Please enter only alphabetical characters.
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Last Name *
                    </label>
                    <input
                      id="last-name"
                      type="text"
                      name="lastname"
                      placeholder="Enter your last name"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.speaker_lastname}
                      onChange={(e) => {
                        const isValidInput = /^[a-zA-Z\s]+$/.test(e.target.value);
                        setEditSpeaker({
                          ...editSpeaker,
                          speaker_lastname: e.target.value,
                        });
                        setErrors({ ...errors, lastName: isValidInput })
                      }}
                    />
                    {!errors.lastName && editSpeaker?.speaker_lastname != "" && (
                      <p className=" text-xs text-red-600" id="email-error">
                        Please enter only alphabetical characters.
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="title"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Title *
                    </label>
                    <select
                      id="title"
                      type="text"
                      name="title"
                      placeholder="Title"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.title}
                      onChange={(e) => {
                        setEditSpeaker({ ...editSpeaker, title: e.target.value });
                      }}
                    >
                      <option value="" selected disabled>
                        Choose Title
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="organization"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Organization *
                    </label>
                    <input
                      type="text"
                      name="organization"
                      placeholder="Organization"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.organization}
                      onChange={(e) => {
                       
                        const isValidInput = /^[a-zA-Z\s]+$/.test(e.target.value);
                        setEditSpeaker({
                          ...editSpeaker,
                          organization: e.target.value,
                        });
                        setErrors({ ...errors, organization: isValidInput })
                      }}
                    />
                     {!errors.organization && editSpeaker?.organization != "" && (
                      <p className=" text-xs text-red-600" id="email-error">
                        Please enter only alphabetical characters.
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="position"
                      className="pt-2 text-sm px-1 font-semibold pb-1"
                    >
                      Position *
                    </label>
                    <input
                      type="text"
                      id="position"
                      name="position"
                      placeholder="Position"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.position}
                      onChange={(e) => {
                        const isValidInput = /^[a-zA-Z\s]+$/.test(e.target.value);
                        console.log(e.target.value)
                        setEditSpeaker({
                          ...editSpeaker,
                          position: e.target.value,
                        });
                        setErrors({ ...errors, position: isValidInput })
                      }} speakerEdit
                    />
                    {!errors.position && editSpeaker?.position != "" && (
                      <p className=" text-xs text-red-600" id="email-error">
                        Please enter only alphabetical characters.
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="pt-2 text-sm px-1 font-semibold ">
                      Country *
                    </label>
                    <select
                      id="country"
                      type="text"
                      name="country"
                      className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.country}
                      onChange={(e) => {
                        setEditSpeaker({
                          ...editSpeaker,
                          country: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled>
                        Choose country
                      </option>
                      {countryList.map((item, index) => {
                        return (
                          <>
                            <option value={item?.name} key={index}>{item?.name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="instgram"
                      className=" text-sm px-1 font-semibold pb-1"
                    >
                      Instagram Url
                    </label>
                    <input
                      id="instgram"
                      type="text"
                      name="instgram"
                      placeholder="www.instagram.com"
                      className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.instagram_profile}
                      onChange={(e) => {
                        setEditSpeaker({
                          ...editSpeaker,
                          instagram_profile: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        const instagramRegex = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9_.-]+\/?$/;
                        validateUrl(instagramRegex)
                      }}
                    />
                    {instagramError && (
                      <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="linkedln"
                      className=" text-sm px-1 font-semibold pb-1"
                    >
                      Linkedln Url
                    </label>
                    <input
                      id="linkedln"
                      type="text"
                      name="linkedln"
                      placeholder="www.linkedln.com"
                      className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.linkedln_profile}
                      onChange={(e) => {
                        setEditSpeaker({
                          ...editSpeaker,
                          linkedln_profile: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
                        validateUrl(linkedInRegex)
                      }}
                    />
                    {linkedlnError && (
                      <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="facebook"
                      className=" text-sm px-1 font-semibold pb-1"
                    >
                      Facebook Url
                    </label>
                    <input
                      id="facebook"
                      type="text"
                      name="facebook"
                      placeholder="www.facebook.com"
                      className="w-full border py-1 px-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                      value={editSpeaker?.facebook_profile}
                      onChange={(e) => {
                        setEditSpeaker({
                          ...editSpeaker,
                          facebook_profile: e.target.value,
                        });
                      }}

                      onKeyUp={(e) => {
                        const facebookRegex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9_.-]+\/?$/;

                        validateUrl(facebookRegex)
                      }}
                    />
                    {facebookError && (
                      <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2 pt-[55px] ">


                <div className="">
                  <label
                    htmlFor="twitter"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Twitter Url
                  </label>
                  <input
                    id="twitter"
                    type="text"
                    name="twitter"
                    placeholder="www.twitter.com"
                    className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editSpeaker?.twitter_profile}
                    onChange={(e) => {
                      setEditSpeaker({
                        ...editSpeaker,
                        twitter_profile: e.target.value,
                      });
                    }}

                    onKeyUp={(e) => {
                      const twitterRegex = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9_]+\/?$/;
                      validateUrl(twitterRegex)
                    }}
                  />
                  {twitterError && (
                    <span className="text-left text-red-600 text-xs">Please enter a valid url</span>
                  )}
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="last-name"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                    Email *
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={editSpeaker.email}
                    onChange={(e) => {
                      setEditSpeaker({ ...editSpeaker, email: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <p className="text-sm pt-2 font-semibold pb-1">
                    Speaker Photo *{" "}
                  </p>
                  <div className="w-full h-28 border-2 border-dashed rounded-md text-center flex flex-col  items-center justify-center">
                    <label
                      htmlFor="choose_banner"
                      accept="image/png, image/gif, image/jpeg, image/svg"
                      className="text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs"
                    >
                      {image === null ? "Choose file" : editSpeaker?.image?.name}
                      {/* {editSpeaker.image == "" ? "Add files" : editSpeaker?.image} */}
                      {/* {(editSpeaker.image=="" || imageError) ? "Choose File" : editSpeaker.image} */}
                      <input
                        id="choose_banner"
                        ref={imageInput}
                        type="file"
                        className=" text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md"
                        onChange={(e) => {
                          onImageChange(e);
                        }}
                      />
                    </label>
                    <p className="text-sm pt-1 text-gray-400 leading-4">
                      Accepts jpg, jpeg, png and webp
                    </p>
                    <p className="text-[11px] font-normal text-gray-400 ">
                      Maximum Upload size: 1Mb
                    </p>
                  </div>
                  <span className="text-xs text-red-600 font-normal">
                    {imageError ? imageError : ""}
                  </span>
                  <div className={(image === null && !imageError) && editSpeaker?.image != "" ? "relative w-28 mt-4 h-28" : "hidden"}>
                    <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleDelete()} />
                    <img
                      src={image === null ? editSpeaker.image : image}
                      alt="banner"
                      className="absolute w-full h-full object-cover"
                    />
                  </div>

                  <div className={image != null && !imageError ? "relative w-28 h-28" : "hidden"}>
                    <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleDelete()} />
                    <img
                      src={image}
                      alt="banner"
                      className="absolute w-full h-full object-cover"
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-4">
                    <label
                      htmlFor="Biography"
                      className="text-sm pt-4 font-semibold pb-1"
                    >
                      Biography *
                    </label>
                    <textarea
                      rows="4"
                      id="Biography"
                      name="Biography"
                      className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                      value={editSpeaker?.Biography}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      maxLength={2000}
                    />
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">
                        Provide some details about the speaker's background,
                        achievements and presentation goals.
                      </span>
                      <span className=" bottom-1 text-xs">{count}/2000</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 flex gap-3">
              <Link to="/speaker_list">
                <button
                  className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                  Back
                </button>
              </Link>

              <button type="button"
                disabled={!buttonDisabled}
                onClick={() => {
                  handleUpdate();
                }}
                className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
                <svg width="40" height="20" fill="currentColor" className={`${isSpinner ? "" : "hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                  </path>
                </svg>
                Save
              </button>
            </div>
          </div>


        </div>
      </div>
    </>
  );
};

export default UpdateSpeaker;
