import React from 'react'
import { INFORMATION_ICON } from './icons'

const Popover = ({ isPopOpen, handleAction }) => {
  return (
    <>
      {!!isPopOpen && <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center xxs:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all xxs:my-8 xxs:w-full xxs:max-w-lg">
              <div className="bg-white p-4 min-w-[290px] max-w-[32vw] w-[calc(100vw-50px)]">
                <INFORMATION_ICON className={'mx-[auto]'} />
                <div className="mt-3 mx-[auto]">
                  <h4
                    className="font-semibold text-center leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Are you sure, you want to delete?
                  </h4>
                  <div className="mt-8 flex justify-evenly">
                    <button name='no' className='border shadow-btnshd align-middle items-center rounded text-sm w-[120px] h-[36px]' onClick={handleAction}>No, cancel</button>
                    <button name='yes' className='border shadow-btnshd bg-[#fb473d] text-white align-middle items-center rounded text-sm w-[120px] h-[36px]' onClick={handleAction}>Yes, I'm sure</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default Popover
