import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";

const NoContactModal = ({ isContactActive ,id,name}) => {
  const {isContactList,setIsContactList}=useContext(DataContext)
  return (
    <>
      <div className="flex w-full bg-white mt-4">
        <div className="w-full  p-8  bg-white overflow-y-scroll h-[35vh] rounded  ">
          <div className="flex flex-col gap-2 items-center justify-center mt-7">
            <h1 className="text-[#202223] text-xl font-semibold ">
              No Contacts Available
            </h1>
            <p className="text-[#6D7175] text-sm font-normal">
              {isContactActive
                ? "There are two ways to add contact, Please choose from the below "
                : "Please add Contacts to Move Further"}
            </p>
          </div>
          <div className="flex justify-center items-center mt-6 gap-2">
            <Link to="/createcontact" state={{id:id,name:name,isContactActive:isContactActive}} >
            {
              isContactActive && <button
              className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white rounded-l text-sm"
              
            >Add Contact Manually</button>
            }
            </Link>
           
          <Link to={`${isContactActive ? "/import-contacts":""}`} state={{id:id,name:name,isContactActive:isContactActive}}>
            <button
              type="button"
              className="bg-theme-color text-white font-medium text-sm px-4 py-2 rounded"
              onClick={() => {
                if(!isContactActive){
                  setIsContactList(true);
                }
                
              }}
            >
              {isContactActive
                ? "Import Contacts"
                : "Create Contact List"}
            </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default NoContactModal;
