import { React, useState, useContext, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

import { FiTrash, FiEdit2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal"
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination"
import NoDataModal from "../NoDatatModal/NoDataModal";
import { AiOutlineEye } from "react-icons/ai";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { Tooltip } from 'react-tooltip'

const Exhibitors = () => {
  const { globalLoader, exhibitorList,deleteBtn,setDeleteBtn,deleteBtnId,setDeleteBtnId,deleteExhibitorApi ,pageCount,totalCount,exhibitorPaginationApi,currentPage,setCurrentPage,searchExhibitorApi,checkResponse} =
    useContext(DataContext);

    const limit= 15;
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || "";
    const permission = userInfo?.permission?.exhibitor;
    const [searchInput, setSearchInput] = useState("");
  const [firstResultCount,setFirstResultCount]=useState(1)
  const [secondResultCount,setSecondResultCount]=useState(limit)

  const onKeyUpHandler = (e) => {
    searchExhibitorApi(e.target.value, currentPage);
  };
  useEffect(() => {
    setCurrentPage(1)
    exhibitorPaginationApi(1,limit)
  }, []);
 
  return (
    <>
    {deleteBtn ? <DeleteModal deleteApi={deleteExhibitorApi} deleteId={deleteBtnId} currentPage={currentPage} limit={limit}
        secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount}  /> : ""}
    <ToastContainer/>
    <div className="flex w-full">
      <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
        <div className="pt-3 ">
          <h1 className="text-xl text-[#202223] font-semibold ">Exhibitors</h1>
          <div className=" flex justify-between space-x-2 pt-8">
            <div className="space-x-4">
            {(userInfo?.role === "Admin" || permission?.create) &&
              <Link to={"/add-exhibitor"}>
                <button className="border  bg-theme-color text-sm text-white  px-2 rounded py-1.5">
                  {"Add New Exhibitors"}
                </button>
              </Link>}
            </div>
            <div className="float-right flex">
              <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
              <input
                type="text"
                placeholder="Search"
                className="py-1 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  onKeyUpHandler(e);
                }}
              />
            </div>
          </div>
        </div>
        {/*--------- table list--------- */}
        {
        exhibitorList?.length===0 && checkResponse===true ? <NoDataModal/> :
        globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
          <table className="w-full table-auto bg-white shadow-md ">
            <thead className="">
              <tr className=" text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-2/12 px-3 py-2 rounded-tl-md">Logo</th>
                <th className="w-2/12 px-3">Exhibitors Name</th>
                <th className="w-2/12 px-3">Website</th>
                <th className="w-3/12 px-3">Email</th>
                <th className="w-3/12 px-3">Contact Number</th>
                <th className="w-3/12  rounded-tr-md px-3">Action</th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {exhibitorList?.map((item) => {
                return (
                  <tr className="border-y cursor-pointer group hover:shadow-tableRowsShadow  text-sm font-normal" key={item?._id}>
                    <td className="hover:underline decoration-sky-500 px-4 py-2">
                      <Link>
                        <img src={item?.logo} className="w-10 h-10 object-contain" />
                      </Link>
                    </td>
                    <td className=" p-3 ">{item?.name}</td>
                    <td className="p-3">{item?.websiteUrl}</td>
                    <td className="p-3">{item?.email}</td>
                    <td className="p-3">{item?.contactNumber}</td>
                    <td className="">
                        
                        <div className="flex items-center   gap-0 text-black">
                        <Link to={"/exhibitorsoverview-view"} state={item}>
                            <HiOutlineChartSquareBar
                             data-tooltip-id="my-tooltip" data-tooltip-content="Report"
                            size={30} className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                              <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </Link>
                          
                        <Link to={`/exhibitor/${item?._id}`} state={item}>
                       
                          <AiOutlineEye 
                           data-tooltip-id="my-tooltip" data-tooltip-content="View"
                          onClick={()=>{
                            item={item}
                            }} className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] "/>
                             <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                        </Link>
                        {(userInfo?.role==="Admin" || permission?.write) &&
                          <Link to={`/update-exhibitor/${item?._id}`} state={item}>
                            <FiEdit2 
                              data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                            className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                               <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </Link>}
                          {userInfo?.role==="Admin" &&<>
                           <FiTrash 
                              data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                           className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' 
                           onClick={()=>{
                            setDeleteBtn(true);
                            setDeleteBtnId(item?._id)
                           }}
                           
                           />
                            <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" /></>}
                        </div>
                      </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {
             !checkResponse && <div className="flex justify-between items-start ">
            <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount}  of ${totalCount} results`}</span>
           <Pagination   firstResultCount={firstResultCount} setFirstResultCount={setFirstResultCount} secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} limit={limit} api={exhibitorPaginationApi}/>
           </div>
          }
         
          </>
        )}
      </div>
    </div>
    </>
  );
};

export default Exhibitors;
